import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import Avatar from "@mui/material/Avatar";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { getRequest, postRequest } from "../../utils/request";
import moment from "moment";
import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isValidMessage } from "../../helpers/validate_message";

const cookies = new Cookies();
toast.configure();
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid rgb(107 114 128)",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            // borderColor: "#80bdff",
            // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const SendMessage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const userParam = searchParams.get("user");

    const [active1, setActive1] = useState("Users");

    const [select, setSelect] = useState("Users");
    const [type, setType] = useState("username");

    const [reciever_id, setReciever_id] = useState("");
    const [message_title, setMessageTitle] = useState("");
    const [message_body, setMessageBody] = useState("");


    const sendMessage = () => {
        const submitData = {
            receiver:`${reciever_id}`,
            header: message_title,
            message: message_body,
        };

        const error = isValidMessage(submitData);
        if (error) return toast.error(error);

        axios
            .post(`hri_admin/message/send`, submitData, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                toast.success("Message send Successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
                navigate("/messages/Received");
            })
            .catch(err => {
                toast.error(`Error: Fields may not be blank`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };

    const [list, setList] = useState([]);

    useEffect(() => {
        if (cookies.get("token")) {
            getRequest(`hri_admin/${type}`, setList);
        } else {
            navigate("/");
        }
    }, [type]);

    const date = new Date();
    const newDate = moment(date).format("YYYY-MM-DD");

    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header />
            <Sidebar />

            <div className="flex items-center justify-between px-5 py-2 m-2 rounded-md z-10">
                <div onClick={() => navigate(-1)}>
                    <div className="text-default-blue flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                        <ArrowBackIosNewIcon style={{ height: 20 }} />
                        <span>Back</span>
                    </div>
                </div>
                <div
                    className="text-red-500 flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer"
                    onClick={() => navigate("/messages/Received")}
                >
                    <RemoveCircleOutlineIcon style={{ height: 20 }} />
                    <span>Discard</span>
                </div>
            </div>

            <div className="bg-white px-5 py-4 m-2 rounded-md z-10">
                <div className="flex items-center">
                    <div className="space-x-10 flex-1">
                        <span
                            id="Message From"
                            className={`font-lighter px-4 py-2 rounded-md duration-300 cursor-pointer`}
                        >
                            Message To:
                        </span>
                        <span
                            id="Users"
                            className={`${
                                active1 === "Users" &&
                                "bg-default-gray text-default-blue"
                            } tab_span`}
                            onClick={e => {
                                setActive1(e.currentTarget.id);
                                setSelect(e.currentTarget.id);
                                setType("username");
                            }}
                        >
                            Users
                        </span>
                        <span
                            id="Companies"
                            className={`${
                                active1 === "Companies" &&
                                "bg-default-gray text-default-blue"
                            } tab_span`}
                            onClick={e => {
                                setActive1(e.currentTarget.id);
                                setSelect(e.currentTarget.id);
                                setType("companyemail");
                            }}
                        >
                            Companies
                        </span>
                        <span
                            id="Teams"
                            className={`${
                                active1 === "Teams" &&
                                "bg-default-gray text-default-blue"
                            } tab_span`}
                            onClick={e => {
                                setActive1(e.currentTarget.id);
                                setSelect(e.currentTarget.id);
                                setType("teamemail");
                            }}
                        >
                            Teams
                        </span>
                    </div>

                    <div className="flex items-center">
                        <p className="font-semibold text-gray-500">
                            Date:{" "}
                            <span className="text-default-blue">{newDate}</span>
                        </p>
                    </div>
                </div>

                <div className="space-y-5 my-4">
                    {select === "Users" ? (
                        <div className="mx-4 flex flex-col">
                            <span className="text-default-blue font-semibold text-lg">
                                Select User
                            </span>
                            <FormControl className="w-[400px]">
                                <Select
                                    labelId="demo-customized-select-label"
                                    value={reciever_id}
                                    onChange={e =>
                                        setReciever_id(e.target.value)
                                    }
                                    input={<BootstrapInput />}
                                >
                                    {list.length > 0 ? (
                                        list.map(item => (
                                            <MenuItem
                                                value={item.user}
                                                key={item.id}
                                            >
                                                {item.email}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="">
                                            No user available
                                        </MenuItem>
                                    )}
                                    {/* <MenuItem value={"10-50"}>10-50</MenuItem>
                                        <MenuItem value={"50-100"}>50-100</MenuItem>
                                        <MenuItem value={"100-200"}>100-200</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>
                    ) : select === "Companies" ? (
                        <div className="mx-4 flex flex-col">
                            <span className="text-default-blue font-semibold text-lg">
                                Select Company
                            </span>
                            <FormControl className="w-[400px]">
                                <Select
                                    labelId="demo-customized-select-label"
                                    value={reciever_id}
                                    onChange={e =>
                                        setReciever_id(e.target.value)
                                    }
                                    input={<BootstrapInput />}
                                >
                                    {list.length ? (
                                        list.map(item => (
                                            <MenuItem
                                                value={item.user_id}
                                                key={item.id}
                                            >
                                                {item.email}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="">
                                            No company available
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    ) : select === "Teams" ? (
                        <div className="mx-4 flex flex-col">
                            <span className="text-default-blue font-semibold text-lg">
                                Select Team
                            </span>
                            <FormControl className="w-[400px]">
                                <Select
                                    labelId="demo-customized-select-label"
                                    value={reciever_id}
                                    onChange={e =>
                                        setReciever_id(e.target.value)
                                    }
                                    input={<BootstrapInput />}
                                >
                                    {list.length ? (
                                        list.map(item => (
                                            <MenuItem
                                                value={item.user_id}
                                                key={item.id}
                                            >
                                                {item.email}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="">
                                            No company available
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="px-4 space-y-2 flex flex-col">
                        <span className="text-default-blue font-semibold text-lg">
                            Message
                        </span>
                        <input
                            type="text"
                            placeholder="Subject of the message"
                            className="p-2 border-[2px] rounded-md border-gray-300 outline-none max-w-none"
                            value={message_title}
                            onChange={e => setMessageTitle(e.target.value)}
                        />
                    </div>

                    <div className="px-4 space-y-2 flex flex-col">
                        <textarea
                            id="w3review"
                            name="w3review"
                            rows="6"
                            cols="50"
                            placeholder="Describe anything in message"
                            className="p-2 border-[2px] rounded-md border-gray-300 outline-none max-w-none"
                            value={message_body}
                            onChange={e => setMessageBody(e.target.value)}
                        />
                    </div>

                    <div
                        className="bg-[#0865B6] text-white font-semibold rounded-md hover:opacity-60 duration-300 active:opacity-80 my-4 max-w-[120px] mx-4 px-4 py-2 flex items-center space-x-2"
                        onClick={sendMessage}
                    >
                        <SendIcon />
                        <button className="">Send</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendMessage;
