import React, { useState } from "react";
import BaseModal from "../BaseModal";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { sendMessage } from "../../controller/MessageController";
import { HTTPStatus } from "../../utils/helperObj";
import { toastSuccess } from "../../utils/Notification";
import { useNavigate } from "react-router";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { Close } from "@mui/icons-material";

/**
 * @typedef {object} Props
 * @property {string|number} reciverId
 * @property {boolean} open
 * @property {()=>void} onClose
 */

/**
 * @param {Props} param
 */
export default function SendReplyModal({ reciverId, onClose, open }) {
    const [messageState, setMessageState] = useState({
        subject: "",
        description: "",
    });
    const receiver = `${reciverId}`
    const [showError, setShowError] = useState("");
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setMessageState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    function validatingFilds(inputs) {
        const keys = Object.keys(inputs);
        return keys.every((key) => inputs[key] !== "")
    }

    async function handleSendReply() {
        if (validatingFilds(messageState)) {
            setLoading(true)
            const res = await sendMessage(messageState.subject, messageState.description, receiver)
            setLoading(false)
            if (res.statusCode === HTTPStatus.SUCCESS) {
                toastSuccess("Message is send");
                onClose()
            } else {
                console.log(res,"error response")
                handleControllerResultException(res, navigate)
            }
        } else {
            setShowError(true)
        }
    }


    return (
        <BaseModal onClose={onClose} open={open} sx={{
            padding: "1rem 1.5rem", width: {
                md: "600px",
                xs: "80%"
            }
        }}>
            <Box sx={{ marginBottom: "1.5rem", display: "flex", justifyContent: "flex-end" }}>
                <button onClick={() => onClose()}><Close /> </button>
            </Box>
            <Typography sx={{ fontWeight: "500", fontSize: "1.2rem" }}>Send Reply</Typography>
            <TextField
                variant="filled"
                label="Subject"
                name="subject"
                value={messageState.subject}
                onChange={handleChange}
                fullWidth
                sx={{ marginTop: "1rem" }}
                error={showError && messageState.subject === ""}
            />
            <TextField
                variant="filled"
                label="Description"
                name="description"
                value={messageState.description}
                onChange={handleChange}
                fullWidth
                multiline
                sx={{ marginTop: "2rem" }}
                error={showError && messageState.description === ""}
                rows={5}
            />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={"1rem"}>
                <Button disabled={loading} onClick={handleSendReply} variant="contained">
                    {loading ? <CircularProgress sx={{ color: "blue" }} size="1.3rem" /> : "Send"}
                </Button>
            </Box>
        </BaseModal>
    );
}
