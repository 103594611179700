import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { handleControllerResultException, handleException } from '../../utils/dataFetchHelper';
import axios from 'axios';
import Cookies from 'universal-cookie';
import JobList from '../../components/jobs/JobList';
import { Box, Pagination,Typography } from '@mui/material';
import Spinner from '../../components/Spinner';
import noJob from '../../Images/NoJob.png'
const cookies = new Cookies();

export default function InactiveJobs ({input}) {

    const navigate = useNavigate();
    const [card_details, setCard_details] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

  async function searchText() {
    setLoading(true);
    GetDetails(input);
    setLoading(false);
  }

  useEffect(() => {
    let timeOut = 0;

    timeOut = setTimeout(() => {
      searchText();
    }, [700]);

    return () => {
      clearTimeout(timeOut);
    };
  }, [input]);



  const GetDetails = (input = "", page = 1) => {
    axios
      .get(`hri_admin/newposition?keyword=${input}&jobs_type=Old`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        }
      })
      .then((res) => {

        setCard_details(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 20));
        setLoading(false);
      })
      .catch((err) => {
        const errRes = handleException(err);
        handleControllerResultException(errRes, navigate)
      });
  };



  useEffect(() => {
    GetDetails();
  }, []);

    return (
        <div className="m-1 md:m-2 lg:m-3">
            {typeof card_details !== "string" && card_details.length > 0 ? (
                <div>
                <JobList
                    jobList={card_details}
                    actionButtonText={"view details"}       
                />
                <Box
                  sx={{ display: "flex", justifyContent: "center",padding:2 }}
                  marginBottom={"2rem"}
                >
                  <Pagination
                    count={totalPages}
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => {
                      GetDetails(input,page);
                    }}
                  />
                </Box>
                </div>
            ) : (
                <div>
                  {loading ? <Spinner /> : 
                  <Box display={"flex"} justifyContent={"center"} marginTop={2}>
                    <Box>
                    <img
                        src={noJob}
                        alt="no jobs"
                        height={"200px"}
                        width={"200px"}
                    />
                    <Typography variant="h6" textAlign={"center"} marginTop={"5px"}>No Inactive Jobs</Typography>
                    </Box>
                </Box>}
                </div>
                )}
        </div>
    )
}
