/**
 * @typedef {object} StudentModel
 * @property {number} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} gender
 * @property {string} panCard
 * @property {string} email
 * @property {string} phoneNumber
 * @property {string} designation
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} pin
 * @property {string} aadharCard
 * @property {string} address
 */

export function createUserObj(data) {
    /**
     * @type {StudentModel}
     */
    const result = {
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        gender: data.gender,
        panCard: data.pan_card,
        email: data.email,
        phoneNumber: data.mobile_no,
        designation: data.designation,
        city: data.city,
        state: data.state,
        country: data.country,
        pin: data.pin,
        aadharCard: data.aadhaar_card,
        address: data.address,
    };
    return result;
}
