import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputBase,
    Paper,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Header from "../../components/Header";
import IndividualUser from "./IndividualUser";
import UnRegisteredUser from "./UnRegisteredUser";
import { grey } from "@mui/material/colors";

const cookies = new Cookies();

const UserManagement = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [resume, setResume] = useState(null);
    const [searchedText, setSearchedText] = useState("");
    const [resumePreview, setResumePreview] = useState("");
    const [uploaded, setUploaded] = useState(null);
    const [open, setOpen] = useState(false);

    const uploadResume = () => {
        let formField = new FormData();

        formField.append("resume", resume);

        axios.post(`hri_admin/bulk-resume-single-create`, formField, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
            onUploadProgress: (data) => {
                setUploaded(Math.round((data.loaded / data.total) * 100));
            },
        })
            .then(res => {
                toast.success("Resume Uploaded Successfully");
                setOpen(false);
                setUploaded(null);
                window.location.reload();
            })
            .catch(err => {
                toast.error(err.response.data.message);
                setOpen(false);
                setUploaded(null);
            });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (!cookies.get("token")) {
            navigate("/");
        }
    }, [navigate]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setResume(file);
            setResumePreview(URL.createObjectURL(file));
            handleClickOpen();
        }
    };

    const handleTabChange = (event, newValue) => {
        navigate(newValue);
    };

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "3rem auto", padding: { xs: "1rem", sm: "2rem" } }}>
                <Typography sx={{ fontSize: "1.4rem", marginBottom: "1.6rem", fontWeight: "500", color: grey[800] }}>User management</Typography>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "space-between", gap: { xs: 2, sm: 0 } }}>
                    <Tabs
                        value={location.pathname}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant={isMobile ? "scrollable" : "standard"}
                        scrollButtons={isMobile ? "auto" : "off"}
                        allowScrollButtonsMobile
                    >
                        <Tab label="Registered User" value="/user-management/registered-user" />
                        <Tab label="Un-Registered User" value="/user-management/unregistered-user" />
                    </Tabs>
                    {location.pathname === "/user-management/registered-user" && (
                        <Paper
                            component="form"
                            sx={{ display: "flex", alignItems: "center", width: { xs: "100%", sm: 300 }, marginLeft: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
                        >
                            <InputBase
                                value={searchedText}
                                onChange={(e) => setSearchedText(e.target.value)}
                                placeholder="Search by name or email"
                                sx={{ ml: 1, flex: 1 }}
                            />
                            <IconButton onClick={() => setSearchedText("")} sx={{ display: searchedText ? "block" : "none" }}>
                                <CloseIcon />
                            </IconButton>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    )}
                    {location.pathname === "/user-management/unregistered-user" && (
                        <Button
                            variant="contained"
                            component="label"
                            sx={{ mt: { xs: 2, sm: 0 } }}
                        >
                            Select Resume
                            <input
                                hidden
                                type="file"
                                accept="application/pdf"
                                onChange={handleFileChange}
                            />
                        </Button>
                    )}
                </Box>
            </Container>
            <Routes>
                <Route
                    path="registered-user"
                    element={<IndividualUser searchedText={searchedText} />}
                />
                <Route
                    path="unregistered-user"
                    element={<UnRegisteredUser />}
                />
            </Routes>
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Resume</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <div className="py-4" id="resume">
                            {resumePreview && (
                                <iframe
                                    src={resumePreview}
                                    width="100%"
                                    height="400px"
                                    title="Resume Preview"
                                ></iframe>
                            )}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {uploaded !== null ? (
                        <Button variant="contained">
                            <CircularProgress color="inherit" size={20} />
                        </Button>
                    ) : (
                        <Button
                            endIcon={<FileUploadIcon />}
                            variant="contained"
                            onClick={uploadResume}
                        >
                            Upload
                        </Button>
                    )}
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default UserManagement;
