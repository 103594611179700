import { Box, Button, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { deleteMessage } from '../../controller/MessageController';
import { useNavigate } from 'react-router';
import { HTTPStatus } from '../../utils/helperObj';
import { toastSuccess } from '../../utils/Notification';
import { handleControllerResultException } from '../../utils/dataFetchHelper';

/**
 * @typedef {object} Props
 * @property {number} messageId
 * @property {React.Dispatch<React.SetStateAction<import('../../controller/MessageController').MessageModel[]>>} setMessageList
 */


/**
 * @param {Props} param
 */
export default function DeleteMessage({ setMessageList, messageId }) {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    async function handleRemoveMessage() {
        setLoading(true);
        const res = await deleteMessage(messageId);
        setLoading(false)
        if (res.statusCode === HTTPStatus.SUCCESS) {
            toastSuccess(res.successMsg);
            setMessageList((prevState) => prevState.filter((data) => data.id !== messageId))
        } else {
            handleControllerResultException(res, navigate)
        }
    }

    return (
        <Box>
            <Button disabled={loading} sx={{ width: "4rem" }} color='warning' variant='outlined' onClick={handleRemoveMessage}>
                {loading ? <CircularProgress size={"1.5em"} /> : "Delete"}
            </Button>
        </Box>
    )
}
