import React, {useEffect} from "react";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const LandingPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    return(
        <div className="py-4 px-10">
            <Button
                style={{background:"#0865B6", color:"white", borderRadius:"10px", fontSize:"20px", fontWeight:"bold"}}
                className="w-1/4 p-5"
                align="center"
                onClick={() => navigate("joblist")}
            >
                Jobs
            </Button>
        </div>
    )
}

export default LandingPage;