import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../Spinner/Spinner';

function BillPayout() {

    const navigate = useNavigate()

    const cookies = new Cookies();

    const { id } = useParams()

    const [data, setData] = useState("")
    const [loading, setLoading] = useState(true)
    const [currency, setCurrency] = useState("INR")
    const [transaction_id, setTransaction_id] = useState("")
    const [amount_paid, setAmount_paid] = useState("")
    const [amount_withheld_reason, setAmount_withheld_reason] = useState("")
    const [payment_receipt, setPayment_receipt] = useState("")
    const [mode_payment, setMode_payment] = useState("")
    const [complete_bill, setComplete_bill] = useState(id)

    useEffect(() => {
        axios.get("hri_admin/bill/" + id, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        }).then(res => {
            setData(res.data[0])
            setLoading(false)
        }).catch(err => {
            console.log(err.message)
        })
    }, [])

    let formData = new FormData()

    formData.append("payment_receipt",payment_receipt)
    formData.append("complete_bill",complete_bill)
    formData.append("amount_withheld_reason",amount_withheld_reason)
    formData.append("amount_paid",amount_paid)
    formData.append("transaction_id",transaction_id)
    formData.append("mode_payment",mode_payment)
    formData.append(" currency", currency)

    function postBill() {
        axios.post("hri_admin/payout/create", formData, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            }
        }).then(res=>{
            toast.success("Sucessfully created")
            navigate(-1)
        }).catch(err=>{

            if(err.response.status === 400) {
            return toast.warning("Please Check the details!")
        }
            toast.warning(err?.message)
        })
    }


    return (
        <div className='bg-gray-100 pb-10'>
            <Header />
            <Sidebar />
            {loading ? <div className='flex justify-center items-center h-[70vh]'><Spinner /></div>
                :
                <div className='m-4 bg-white rounded-md pt-10'>
                    <div>
                        <div
                            className="bg-white mb-4 rounded-lg px-[2rem] md:px-[10%] "
                        >
                            <div className='text-lg md:text-xl text-gray-400 flex justify-center my-4'>
                                <div className='whitespace-nowrap font-semibold'>BILL DETAILS</div>
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                                <div className="py-3 md:pr-10 text-lg md:text-xl text-gray-700 whitespace-nowrap">
                                    <div className='whitespace-nowrap text-gray-400 font-semibold'>Bill Id</div>
                                    <h className="w-full truncate">{data.complete_bill_id}</h>
                                </div>
                                <div className='text-lg md:text-xl'>
                                    <div className='whitespace-nowrap text-gray-400 font-semibold'>Bill Description:</div>
                                    <h className="text-gray-700 w-full pt-[-1rem]">{data.bill_description}</h>
                                </div>
                                <div className='text-lg md:text-xl'>
                                    <div className='font-semibold text-gray-400'>Notes:</div>
                                    <h className="text-gray-700">{data.note}</h>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:grid-rows-2 lg:text-lg'>
                                <div className="text-lg md:text-xl">
                                    <div className='font-semibold text-gray-400'>Bill Date:</div>
                                    <h className="text-gray-700 whitespace-nowrap ">
                                        {data?.updated_at.slice(0, 10)}
                                    </h>
                                </div>
                                <div className='text-lg md:text-xl'>
                                    <div className='font-semibold text-gray-400'>Amount:</div>
                                    <h className="text-gray-700">{data.currency}{" "}{data.amount}</h>
                                </div>
                                <div className='text-lg md:text-xl'>
                                    <div className='font-semibold text-gray-400'>Created By:</div>
                                    <h className="text-gray-700">{data.created_by_name}</h>
                                </div>
                                <div className='text-lg md:text-xl'>
                                    <div className='font-semibold text-gray-400'>Payment To:</div>
                                    <h className="text-gray-700">{data.created_for_name}</h>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full p-[1rem] md:px-[10%]'>
                        <div className='text-lg md:text-xl text-gray-400 flex justify-center my-4'>
                            <div className='whitespace-nowrap font-semibold'>TRANSACTION DETAILS</div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4'>
                            <div>
                                <TextField fullWidth variant='outlined' onChange={(e) => setTransaction_id(e.target.value)} size='small' label="Enter the Transaction Id" />
                            </div>
                            <div>
                                <TextField fullWidth variant='outlined' onChange={(e) => setAmount_withheld_reason(e.target.value)} size='small' label="Enter the Amount Witheld Reason" />
                            </div>
                            <div className='flex gap-2 md:gap-4'>
                                <FormControl sx={{ width: 130, fontSize: 15 }}>
                                    <InputLabel id="demo-simple-select-label">Select one</InputLabel>
                                    <Select size='small' labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        title='select' onChange={(e) => setCurrency(e.target.value)} value={currency}>
                                        <MenuItem value="INR">INR</MenuItem>
                                        <MenuItem value="USD">USD</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField fullWidth variant='outlined' type="number" onChange={(e) => setAmount_paid(e.target.value)} size='small' label="Enter the paid Amount" />
                            </div>
                            <div>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Select one</InputLabel>
                                    <Select labelId="demo-simple-select-label"
                                        label="Select one"
                                        id="demo-simple-select"
                                        size='small' onChange={(e) => setMode_payment(e.target.value)} value={mode_payment}>
                                        <MenuItem disabled >Select trasaction mode</MenuItem>
                                        <MenuItem value="UPI">UPI</MenuItem>
                                        <MenuItem value="NEFT">NEFT</MenuItem>
                                        <MenuItem value="IMPS">IMPS</MenuItem>
                                        <MenuItem value="RTGS">RTGS</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl hiddenLabel >

                                <TextField 
                                      label="-"
                                        id="demo-simple-select" type="file" placeholder='Upload Receipt' onChange={(e)=>setPayment_receipt(e.target.files[0])} />
                                </FormControl>
                            </div>
                        </div>
                        <div className='flex justify-center my-10 gap-4'>
                            <Button onClick={()=>navigate(-1)} color='error' variant='outlined'>Cancel</Button>
                            <Button variant='contained' onClick={postBill}>Submit details</Button>
                        </div>
                    </div>

                </div>
            }

        </div>
    )
}

export default BillPayout
