import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { selectHead } from "../../features/HeadSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { RiCheckDoubleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { toast } from "react-toastify";
import Editor from "../../components/richEditor/Editor";
import useCreateEditor from "../../hooks/useCreateEditor";

const cookies = new Cookies();

const EditJobDetails = () => {
    const currentState = useSelector(selectHead);
    const [position_name, setPositionName] = useState("");
    const [employment_type, setEmplomentType] = useState("");
    const [stipend, setStipend] = useState("");

    const [experience, setExperience] = useState("");
    const [location, setLocation] = useState("");
    const [criteria, setEducation] = useState("");
    const [functional_area, setFunctionalArea] = useState("");
    const [skills, setSkills] = useState("");
    const [job_description, setDisc] = useState("");
    const [id, setId] = useState("");
    const [updated_at, setUpdatedAt] = useState("");
    const [roleCatId, setRoleCatId] = useState("");
    const [roleSubCatId, setRoleSubCatId] = useState("");
    const [roleCat, setRoleCat] = useState([]);
    const [roleSubCat, setRoleSubCat] = useState([]);
    const [company, setCompany] = useState([]);
    const [ed_lis, setEd_list] = useState(["BE", "Btech", "commerce"]);

    const navigate = useNavigate();
    const editor = useCreateEditor(job_description);

    async function getJob() {
        await axios
            .get(`hri_admin/newposition/${cookies.get("jobId")}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(resp => {
                console.log(resp.data);
                setPositionName(resp.data[0].position_name);
                setEmplomentType(resp.data[0].employment_type);
                setStipend(resp.data[0].stipend);
                setExperience(resp.data[0].experience);
                setLocation(resp.data[0].location);
                setEducation(resp.data[0].criteria);
                setFunctionalArea(resp.data[0].functional_area);
                setSkills(resp.data[0].skills);
                setDisc(resp.data[0].job_description);
                setId(resp.data[0].id);
                setUpdatedAt(resp.data[0].updated_at);
            })
            .catch(err => {
                console.log(err);
            });
    }

    async function updateData(e) {
        e.preventDefault();

        await axios
            .put(
                `hri_admin/newposition/update/${cookies.get("jobId")}`,
                {
                    position_name: position_name,
                    experience: experience,
                    employment_type: employment_type,
                    location: location,
                    stipend: stipend,
                    role: roleCatId,
                    role_sub_category: roleSubCatId,
                    functional_area: functional_area,
                    criteria: criteria,
                    job_description: editor.getHTML(),
                    skills: skills,
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then(res => {
                toast.success("Sucessfully updated the details", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
                navigate("/posted-jobs");
            })
            .catch(err => {
                console.log(err);
            });
    }

    const roleCatChange = e => {
        setRoleCatId(e.target.value);
    };
    const roleSubCatChange = e => {
        setRoleSubCatId(e.target.value);
    };
    const date = new Date(updated_at?.slice(0, 10));
    const month = date.toLocaleString("default", { month: "long" });

    useEffect(() => {
        axios
            .get("hri_admin/education-list")
            .then(dat => setEd_list(dat.data.education_list));

        if (cookies.get("token")) {
            getJob();
            axios
                .get("hri_admin/roles", {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
                .then(resp => {
                    console.log(resp.data);
                    setRoleCat(resp.data);
                })
                .catch(err => {
                    console.log(err);
                });
            axios
                .get(`hri_admin/sub_roles/${roleCatId}`, {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
                .then(resp => {
                    console.log(resp.data);
                    setRoleSubCat(resp.data);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            navigate("/");
        }
    }, [roleCatId]);

    return (
        <>
            <Header />
            <Sidebar />

            <div className="pt-3 pb-20 bg-[#EDEBEE] px-4 md:px-10 w-full h-auto">
                <div className="ml-0">
                    <div key={id}>
                        <Link to="/posted-jobs">
                            <div className="mb-2 text-lg font-semibold text-blue-700">
                                {" "}
                                Posted jobs / {cookies.get("check")} /{" "}
                                {position_name}{" "}
                            </div>
                        </Link>
                        <div className="bg-white px-4 md:px-12 pb-20 pt-8 w-full rounded-lg h-auto">
                            <div>
                                <div className="md:flex  justify-between">
                                    <div className=" md:flex">
                                        <div className="md:ml-20 text-lg font-semibold">
                                            Date : {date.getDate()}&nbsp;
                                            {month?.slice(0, 3)}&nbsp;
                                            {date.getFullYear()}
                                        </div>
                                    </div>

                                    <div
                                        onClick={updateData}
                                        className="lg:flex mt-2 lg:mt-0 lg:space-x-4 cursor-pointer lg:space-y-0 space-y-2"
                                    >
                                        <div className="flex justify-center bg-slate-100 px-4 py-2 rounded-lg font-semibold text-blue-600 text-sm">
                                            Save{" "}
                                            <RiCheckDoubleFill className="ml-2 mt-[1px] text-xl" />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-5 md:space-y-0 md:grid gap-y-10 gap-x-10 md:mr-20  grid-cols-2  mt-5 text-sm font-semibold ml-2 md:ml-20">
                                    <div>
                                        <TextField
                                            className="w-full "
                                            value={position_name}
                                            label="Position Name"
                                            id="outlined-size-small"
                                            onChange={e =>
                                                setPositionName(e.target.value)
                                            }
                                        />
                                    </div>

                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Employment Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={employment_type}
                                                label="Employment Type"
                                                onChange={e =>
                                                    setEmplomentType(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={"Part-time"}>
                                                    Part-time
                                                </MenuItem>
                                                <MenuItem
                                                    value={
                                                        "Part-time(Work from home)"
                                                    }
                                                >
                                                    Part-time(Work from home)
                                                </MenuItem>
                                                <MenuItem value={"Full-time"}>
                                                    Full-time
                                                </MenuItem>
                                                <MenuItem
                                                    value={
                                                        "Full-time(Work from home)"
                                                    }
                                                >
                                                    Full-time(Work from home)
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div>
                                        <TextField
                                            label="Stipend / Salary"
                                            className="w-full"
                                            value={stipend}
                                            id="outlined-size-small"
                                            onChange={e =>
                                                setStipend(e.target.value)
                                            }
                                        />
                                    </div>

                                    <div>
                                        <TextField
                                            label="Experience"
                                            className="w-full "
                                            value={experience}
                                            id="outlined-size-small"
                                            onChange={e =>
                                                setExperience(e.target.value)
                                            }
                                        />
                                    </div>

                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Role Category
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={roleCatId}
                                                label="Role Category"
                                                onChange={roleCatChange}
                                            >
                                                {roleCat.map(roleCat => (
                                                    <MenuItem
                                                        key={roleCat.id}
                                                        value={roleCat.id}
                                                    >
                                                        {roleCat.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Role
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={roleSubCatId}
                                                label="Role"
                                                onChange={roleSubCatChange}
                                            >
                                                {roleSubCat.map(user => (
                                                    <MenuItem
                                                        key={user.id}
                                                        value={user.id}
                                                    >
                                                        {user.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div>
                                        <div className="flex">
                                            <TextField
                                                label="Location"
                                                className="w-full "
                                                value={location}
                                                id="outlined-size-small"
                                                onChange={e =>
                                                    setLocation(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            onChange={(_, val) =>
                                                setEducation(val)
                                            }
                                            value={criteria || null}
                                            options={company}
                                            getOptionLabel={option =>
                                                option.name
                                            }
                                            renderOption={(props, o) => (
                                                <li {...props}>{o.name}</li>
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label="Company List"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete:
                                                            "new-password",
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <TextField
                                            label="Functional Area"
                                            className="w-full "
                                            value={functional_area}
                                            id="outlined-size-small"
                                            onChange={e =>
                                                setFunctionalArea(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <div>
                                        <TextField
                                            label="Skills"
                                            className="w-full"
                                            value={skills}
                                            id="outlined-size-small"
                                            onChange={e =>
                                                setSkills(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="mt-10 md:mx-20">
                                    {/* <TextField
                    multiline
                    label="Job Description"
                    minRows={4}
                    InputProps={{
                      disableUnderline: true,
                      style: { whiteSpace: 'pre-wrap' }
                    }}
                    // cols="10"
                    // rows="5"
                    value={job_description}
                    className="w-full "
                    onChange={(e) => setDisc(e.target.value)}
                  /> */}
                                    <Editor editor={editor} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditJobDetails;
