import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

/**
 * @typedef {object} Props
 * @property {string[]} options
 * @property {(option:string)=>void} setSelectOption
 * @property {boolean} error
 * @property {(searchText:string)=>Promise<void>} searchFunction
 * @property {string} textFieldLabel
 * @property {string} search
 */

/**
 * @param {Props} props
 */
export default function SuggestionTextField({
    options,
    setSelectOption,
    error = false,
    searchFunction,
    textFieldLabel = "Text Field",
    search = ""
}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    async function handleSearch(searchText) {
        setLoading(true);
        await searchFunction(searchText);
        setLoading(false);
    }

    useEffect(() => {
        if (search !== "") {
            setSearchText(search);
        }
    }, [search])
    useEffect(() => {
        let timeOutId = 0;

        timeOutId = setTimeout(() => {
            handleSearch(searchText);
        }, [700]);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [searchText]);


    function handleFieldChange(value) {
        if (value !== null) {
            setSelectOption(value);
        } else {
            setSelectOption("");
        }
    }

    return (
        <Autocomplete
            id="asynchronous-demo"
            open={open}
            fullWidth
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) =>
                option === value
            }
            getOptionLabel={option => option}
            options={options}
            loading={loading}
            onChange={(_event, value) => handleFieldChange(value)}
            renderInput={params => (
                <TextField
                    {...params}
                    label={textFieldLabel}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    error={error}
                />
            )}
        />
    );
}
