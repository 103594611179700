import React, { useCallback, useEffect, useState } from "react";
import { searchCompany } from "../../../controller/companyController";
import { useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../../utils/handleException";
import {
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Typography,
} from "@mui/material";

/**
 * @typedef {object} Props
 * @property {number[]} companyIds
 * @property {React.Dispatch<React.SetStateAction<number[]>>} setCompanyIds
 */

/**
 * @param {Props} props
 */
export default function CompanySelector({ companyIds, setCompanyIds }) {
    const [selectAll, setSelectAll] = useState(false);
    const [companyList, setCompanyList] = useState(companyListType);
    const [fetchingCompanyDetails, setFetchingCompanyDetails] = useState(false);
    const navigate = useNavigate();

    const handleFetchCompanyList = useCallback(async () => {
        setFetchingCompanyDetails(true)
        const res = await searchCompany();
        setFetchingCompanyDetails(false)
        if (res[0] !== null) {
            setCompanyList(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, [navigate]);

    function handleCheck(checked, id) {
        if (checked) {
            setCompanyIds(prevState => [...prevState, id]);
        } else {
            const arr = [...companyIds];
            let idIndex = arr.findIndex(data => data === id);
            idIndex >= 0 && arr.splice(idIndex, 1);
            setCompanyIds(arr);
        }
    }

    function handleCheckAll(checked) {
        if (checked) {
            const newIdArr = [];
            companyList.forEach(data => {
                newIdArr.push(data.id);
            });
            setCompanyIds(newIdArr);
            setSelectAll(true);
        } else {
            setCompanyIds([]);
            setSelectAll(false);
        }
    }

    useEffect(() => {
        handleFetchCompanyList();
    }, [handleFetchCompanyList]);

    if (fetchingCompanyDetails) {
        return <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <CircularProgress />
        </Box>
    }

    return (
        <Box>
            <Typography fontSize={"18px"}>Company</Typography>
            <Box
                display={"flex"}
                justifyContent={"flex-end"}
                marginTop={"10px"}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            onChange={e => handleCheckAll(e.target.checked)}
                        />
                    }
                    label={"Select all"}
                />
            </Box>
            {companyList.length > 0 && (
                <Grid container spacing={2}>
                    {companyList.map(data => (
                        <Grid item xs={6} key={data.id}>
                            <Box display={"flex"} alignItems={"center"}>
                                <Checkbox
                                    checked={companyIds.includes(data.id)}
                                    onChange={e =>
                                        handleCheck(e.target.checked, data.id)
                                    }
                                />
                                <Box display={"flex"} alignItems={"center"}>
                                    <Avatar src={data.companyLogo} alt={data.name} sx={{ marginRight: "5px" }} />
                                    <Typography>{data.name}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

/**
 * @type {import('../../../models/CompanyModel').CompanyModel[]}
 */

const companyListType = [];
