import React, { useState } from "react";
import csc from "country-state-city";
import { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function useCountryStateCitySelector() {
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [cityId, setCityId] = useState("");
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        if (countryId !== "") {
            setStateList(csc.getStatesOfCountry(countryId));
        }
    }, [countryId]);

    useEffect(() => {
        if (stateId !== "") {
            setCityList(csc.getCitiesOfState(stateId));
        }
    }, [stateId]);

    function CountryFiled() {
        return (
            <FormControl fullWidth>
                <InputLabel id="country">Country</InputLabel>
                <Select
                    labelId="country"
                    value={countryId}
                    onChange={e => setCountryId(e.target.value)}
                    label="country"
                >
                    {csc.getAllCountries().map(data => (
                        <MenuItem value={data.id} key={data.id}>
                            {data.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    function StateFiled() {
        return (
            <FormControl fullWidth>
                <InputLabel id="state">State</InputLabel>
                <Select
                    labelId="state"
                    value={stateId}
                    onChange={e => setStateId(e.target.value)}
                    label="state"
                >
                    {stateList.map(data => (
                        <MenuItem value={data.id} key={data.id}>
                            {data.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    function CityFiled() {
        return (
            <FormControl fullWidth>
                <InputLabel id="state">City</InputLabel>
                <Select
                    labelId="city"
                    value={cityId}
                    onChange={e => setCityId(e.target.value)}
                    label="city"
                >
                    {cityList.map(data => (
                        <MenuItem value={data.id} key={data.id}>
                            {data.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    return { countryId, cityId, stateId, CountryFiled, StateFiled, CityFiled, setCityId, setStateId, setCountryId };
}
