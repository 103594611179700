import { BsTypeBold } from "react-icons/bs";
import { BiItalic } from "react-icons/bi";
import { BsTypeStrikethrough, BsParagraph } from "react-icons/bs";
import MenuBtnContainer from "./MenuBtnContainer";

/**
 * @typedef {object} Props
 * @property {import("@tiptap/react").Editor} editor
 */

/**
 *  @param {Props} props
 *  @returns {JSX.Element}  Renders editor toolbar
 */

export default function FontStyles({ editor }) {
  return (
    <>
      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().toggleBold().run()}
        toolTipText="Bold"
      >
        <BsTypeBold size="1.2em" />
      </MenuBtnContainer>

      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().toggleItalic().run()}
        toolTipText="Italic"
      >
        <BiItalic size="1.2em" />
      </MenuBtnContainer>
      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().toggleStrike().run()}
        toolTipText="Strike"
      >
        <BsTypeStrikethrough size="1.2em" />
      </MenuBtnContainer>
      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().setParagraph().run()}
        toolTipText="Paragraph"
      >
        <BsParagraph size="1.2em" />
      </MenuBtnContainer>
    </>
  );
}
