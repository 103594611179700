import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoneAllSharpIcon from "@mui/icons-material/DoneAllSharp";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import DetailsInput from "../../components/DetailsInput";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { updateRequest } from "../../utils/request";


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid rgb(107 114 128)",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      // borderColor: "#80bdff",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const CompanyEditDetails = () => {
  const cookies = new Cookies();
  // const currentValue = useSelector(selectId);
  // console.log(currentValue?.id, 'current from edit')
  const navigate = useNavigate();
  const currentValue = localStorage.getItem("company_edit_id");

  const [company_name, setCompany_name] = useState("");
  const [company_founder, setCompany_founder] = useState("");
  const [company_ceo, setCompany_ceo] = useState("");
  const [company_employee, setCompany_employee] = useState("");
  const [company_description, setCompany_description] = useState("");
  const [company_phone_number, setCompany_phone_number] = useState("");
  const [company_email_address, setCompany_email_address] = useState("");
  const [contact_person, setContact_person] = useState("");
  const [contact_number, setContact_number] = useState("");
  const [contact_email, setContact_email] = useState("");
  const [established_in, setEstablished_in] = useState(new Date());
  const [working_sector, setWorking_sector] = useState("");
  const [status, setStatus] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pin, setPin] = useState();
  const [address, setAddress] = useState("");
  const [company_logo, setCompany_logo] = useState([]);
  const [company_url, setCompany_url] = useState("");
  const [linkedin_url, setLinkedin_url] = useState("");
  const [facebook_url, setFacebook_url] = useState("");

  useEffect(() => {
    if (cookies.get("token")) {
      axios
        .get(`hri_admin/company/${cookies.get("CompanyId")}`, {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        })
        .then((res) => {
          console.log(
            "response from submitting the form successful from edit",
            res.data
          );
          setCompany_name(res.data[0].name);
          setCompany_founder(res.data[0].company_founder);
          setCompany_ceo(res.data[0].company_ceo);
          setCompany_employee(res.data[0].company_employee);
          setCompany_description(res.data[0].company_description);
          setCompany_phone_number(res.data[0].company_number);
          setCompany_email_address(res.data[0].company_email);
          setContact_person(res.data[0].contact_person);
          setContact_number(res.data[0].contact_number);
          setContact_email(res.data[0].contact_email);
          setEstablished_in(res.data[0].established_in);
          setWorking_sector(res.data[0].working_sector);
          setStatus(res.data[0].status);
          setCity(res.data[0].city);
          setState(res.data[0].state);
          setCountry(res.data[0].country);
          setPin(res.data[0].pin);
          setAddress(res.data[0].address);
          setCompany_description(res.data[0].company_description);
          setCompany_logo(res.data[0].company_logo);
          setCompany_url(res.data[0].company_url);
          setLinkedin_url(res.data[0].linkedin_url);
          setFacebook_url(res.data[0].facebook_url);
        })
        .catch((err) => {
          console.log("ERROR  from update in form", err);
          toast.error("Some error occurred");
        });
    } else {
      navigate("/");
    }
  }, []);

  const startDate = moment(established_in).format("YYYY-MM-DD");

  const update = () => {
    let formField = new FormData();

    formField.append("name", company_name);
    formField.append("company_number", company_phone_number);
    formField.append("company_email", company_email_address);
    formField.append("contact_person", contact_person);
    formField.append("contact_number", contact_number);
    formField.append("contact_email", contact_email);
    formField.append("company_founder", company_founder);
    formField.append("company_ceo", company_ceo);
    formField.append("company_description", company_description);
    formField.append("company_employee", company_employee);
    formField.append("established_in", startDate);
    formField.append("working_sector", working_sector);
    formField.append("status", status);
    formField.append("city", city);
    formField.append("state", state);
    formField.append("country", country);
    formField.append("pin", pin);
    formField.append("address", address);
    formField.append("company_logo", company_logo);
    formField.append("company_url", company_url);
    formField.append("linkedin_url", linkedin_url);
    formField.append("facebook_url", facebook_url);

    updateRequest(
      `hri_admin/company/update/${cookies.get("CompanyId")}`,
      formField,
      "/companies"
    );
  };

  return (
    <div className="bg-default-background h-screen overflow-y-scroll">
      <Header />
      <Sidebar />

      <div className="px-4 py-3">
        <div className="bg-white rounded-lg p-6">
        <Button
          variant="text"
          startIcon={<ArrowBackIosNewIcon />}
          onClick={() => navigate(-1)}
          sx={{marginBottom:2}}
        >
          Back
        </Button>
          <div className="flex items-center justify-start mx-3">
            <input
              id="file-upload"
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                // setVariable({
                //   ...variable,
                //   company_logo: e.target.files[0],
                // })
                setCompany_logo(e.target.files[0])
              }
            />
            <label
              htmlFor="file-upload"
              className="flex flex-col items-center justify-center"
            >
              <Avatar
                className="hover: cursor-pointer hover:opacity-50 duration-300"
                sx={{ width: 70, height: 70 }}
                src={company_logo?.toString()}
              />
              <span className="text-gray-400 font-semibold text-lg my-1">
                {typeof company_logo === "string"
                  ? "Re-upload Logo"
                  : "Upload Logo"}
              </span>
            </label>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-4 items-center justify-center md:mx-32">
            <div>
              <DetailsInput
                title="Company Name"
                placeholder="Enter Company name"
                value={company_name}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   company_name: e.target.value,
                  // })
                  setCompany_name(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="Company Phone Number"
                placeholder="Enter company Phone Number"
                value={company_phone_number}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   company_phone_number: e.target.value,
                  // })
                  setCompany_phone_number(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="Company Email ID"
                placeholder="Enter email address"
                value={company_email_address}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   company_email_address: e.target.value,
                  // })
                  setCompany_email_address(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="Founder"
                placeholder="Enter Name of the founder"
                value={company_founder}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   company_founder: e.target.value,
                  // })
                  setCompany_founder(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="CEO"
                placeholder="Enter Name of the CEO"
                value={company_ceo}
                onChange={(e) => setCompany_ceo(e.target.value)}
              />
            </div>
            <div>
              <DetailsInput
                title="HR"
                placeholder="Enter the Name of HR"
                value={contact_person}
                onChange={(e) => setContact_person(e.target.value)}
              />
            </div>
            <div>
              <DetailsInput
                title="HR Phone Number"
                placeholder="Enter HR Phone Number"
                value={contact_number}
                onChange={(e) => setContact_number(e.target.value)}
              />
            </div>
            <div>
              <DetailsInput
                title="HR Email ID"
                placeholder="Enter HR email address"
                value={contact_email}
                onChange={(e) => setContact_email(e.target.value)}
              />
            </div>
            <div>
              <span className="text-gray-400 font-semibold text-lg">
                Employees
              </span>
              <FormControl fullWidth>
                <Select
                  labelId="demo-customized-select-label"
                  value={company_employee}
                  onChange={(e) =>
                    // setVariable({
                    //   ...variable,
                    //   company_employee: e.target.value,
                    // })
                    setCompany_employee(e.target.value)
                  }
                  input={<BootstrapInput />}
                >
                  <MenuItem value={"10-50"}>10-50</MenuItem>
                  <MenuItem value={"50-100"}>50-100</MenuItem>
                  <MenuItem value={"100-200"}>100-200</MenuItem>
                  <MenuItem value={"200-500"}>200-500</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="pt-4 ">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  size="large"
                  label="Established In"
                  value={established_in}
                  onChange={(newValue) =>
                    // setVariable({ ...variable, established_in: newValue })
                    setEstablished_in(newValue)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div>
              <span className="text-gray-400 font-semibold text-lg">
                Working Sector
              </span>
              <FormControl fullWidth>
                <Select
                  labelId="demo-customized-select-label"
                  value={working_sector}
                  onChange={(e) =>
                    // setVariable({
                    //   ...variable,
                    //   working_sector: e.target.value,
                    // })
                    setWorking_sector(e.target.value)
                  }
                  input={<BootstrapInput />}
                >
                  <MenuItem value={"IT Company"}>IT Company</MenuItem>
                  <MenuItem value={"Software Solutions"}>
                    Software Solutions
                  </MenuItem>
                  <MenuItem value={"Digital Marketing"}>
                    Digital Marketing
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <span className="text-gray-400 font-semibold text-lg">
                Hiring Status
              </span>
              <FormControl fullWidth>
                <Select
                  labelId="demo-customized-select-label"
                  value={status}
                  onChange={(e) =>
                    // setVariable({
                    //   ...variable,
                    //   status: e.target.value,
                    // })
                    setStatus(e.target.value)
                  }
                  input={<BootstrapInput />}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>In-Active</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <DetailsInput
                title="City"
                placeholder="Enter the city name"
                value={city}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   city: e.target.value,
                  // })
                  setCity(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="State"
                placeholder="Enter the state name"
                value={state}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   state: e.target.value,
                  // })
                  setState(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="Country"
                placeholder="Enter the country name"
                value={country}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   country: e.target.value,
                  // })
                  setCountry(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="Pin"
                placeholder="Enter the pin code"
                value={pin}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   pin: e.target.value,
                  // })
                  setPin(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="Location"
                placeholder="Choose the right location in google maps or Type manually"
                span="col-span-2"
                value={address}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   address: e.target.value,
                  // })
                  setAddress(e.target.value)
                }
              />
            </div>

            <div>
              <DetailsInput
                title="Company website Link"
                placeholder="Add Company official website"
                value={company_url}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   company_url: e.target.value,
                  // })
                  setCompany_url(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="LinkedIn Link"
                placeholder="Add company linkedIn profile"
                value={linkedin_url}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   linkedin_url: e.target.value,
                  // })
                  setLinkedin_url(e.target.value)
                }
              />
            </div>
            <div>
              <DetailsInput
                title="Facebook Link"
                placeholder="Add company facebook link"
                value={facebook_url}
                onChange={(e) =>
                  // setVariable({
                  //   ...variable,
                  //   facebook_url: e.target.value,
                  // })
                  setFacebook_url(e.target.value)
                }
              />
            </div>
          </div>
          <div className="md:mx-32 mt-4">
            <DetailsInput
              title="Company Description"
              placeholder="Describe anything about the company"
              span="col-span-2"
              textarea
              value={company_description}
              onChange={(e) =>
                // setVariable({
                //   ...variable,
                //   company_description: e.target.value,
                // })
                setCompany_description(e.target.value)
              }
            />
          </div>
          <div className="flex justify-end mt-4" onClick={update}>
            <button className="font-semibold bg-cyan-600 rounded-md px-4 py-2 text-white">
              Save <DoneAllSharpIcon style={{ height: 18 }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyEditDetails;
