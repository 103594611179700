import axios from 'axios';
import React from 'react'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";


function InterviewReview() {

const cookies = new Cookies();
const navigate = useNavigate();

    const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addAnotherQuestion, setAddAnotherQuestion] = useState("");
  const [questionCount, setQuestionCount] = useState(1);
  const [addAnotherArr, setAddAnotherArr] = useState([]);
  const addQuestion = (e) => {
    e.preventDefault();
    setQuestionCount(questionCount + 1);
    setAddAnotherQuestion(addAnotherQuestion);
    addAnotherArr.push(addAnotherQuestion);
  };

  const [data, setData] = useState({});
  const handleChange = (e) => {
    if (e.target.name.startsWith("option")) {
      setData({ ...data, [e.target.name]: e.target.value });
    } else setData({ ...data, [e.target.name]: e.target.value });
  };

  function createReview (e) {
    e.preventDefault();
    let question_data = [];
    let i 
    for (i = 0; i < questionCount; i++) {
      let obj = {};
      obj["question"] = data[`question${i}`];
      obj["question_type"] = data[`questionType${i}`];
      obj["description"] = data[`questionDes${i}`];
      if (data[`questionType${i}`] === "ChoiceField")
        obj["options"] =
          data[`optionOne${i}`] +
          "," +
          data[`optionTwo${i}`] +
          "," +
          data[`optionThree${i}`] +
          "," +
          data[`optionThree${i}`];
      question_data.push(obj);
    }
    console.log("data", {question_data});
  let formData = new FormData()
        
    formData.append("name", title);
    formData.append("description", description);
    formData.append("creator", cookies.get("id"));
    formData.append("question_data", question_data);

    let sendData = {
        name: title,
        description: description,
        questions: question_data,
        // creator: cookies.get("id"),
        form_type: "interview_review"
      };
    console.log(sendData);


    axios.post('survey/form-create',sendData, {
        headers: {
            Authorization: "Token " + cookies.get("token"),
        },
    })
        .then((response) => {
            message.info("Review was created successfully")
            navigate("/interview/All")
        }).catch((err) => {
          if(err?.response?.status === 400){
            return toast.warning("plz check the fields again")
          }
            toast.warning(err.message)
        });


  }
    return (
        <div className="">
            <Header />
            <Sidebar />
            <div className="py-10">
            <div className=" md:px-16">
                <div
                  onClick={() => navigate(-1)}
                  className="text-blue-600 text-xl cursor-pointer mb-3"
                >
                  <NavigateBeforeIcon />
                  Back
                </div>
                <div className="  ">
                  <div className="text-gray-400   text-sm md:text-base mb-2 font-semibold ml-1">
                    Title
                  </div>
                  <TextField
                    className="w-full"
                    label="Enter Title"
                    required
                    id="outlined-size-small"
                    onChange={(e) => setTitle(e.target.value)}
                    size="small"
                  />
                </div>
                <div className="mt-4 ">
                  <div className="text-gray-400  text-sm md:text-base mb-2 font-semibold">
                    Description
                  </div>

                  <TextField
                    multiline
                    minRows={5}
                    label="Skills"
                    className="w-full"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="row">
                  {Array.from({ length: questionCount }, (_, i) => (
                    <>
                      <div className="mt-4">
                        <div className="row">
                          <div className=" section_margin_top">
                            <hr className="my-4 mt-8" />
                            <div className="col-xl-12  noPadding">
                              <p className="bp-form-text-header mb-1">
                                Question <span style={{ color: "red" }}>*</span>
                              </p>
                              <input
                                className="w-full border h-10 rounded-md p-4"
                                type="text"
                                name={`question${i}`}
                                onChange={handleChange}
                                placeholder="Type your first question here..."
                              />
                            </div>
                            <div className="mt-4">
                              <p className="bp-form-text-header mb-1">
                                Description
                              </p>
                              <textarea
                                className="h-20 2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                name={`questionDes${i}`}
                                onChange={handleChange}
                                type="text"
                                placeholder="Question Description"
                              />
                            </div>
                          </div>

                          <div className="mt-4">
                            <div>
                              <div className="col-xl-12 ">
                                <p className="bp-form-text-header mb-1">
                                  Answer Mode{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                                <select
                                  className="form-select   ml-1  appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  name={`questionType${i}`}
                                  onChange={handleChange}
                                  // onChange={(e) => setQuestionType(e.target.value)}
                                >
                                  <option className="h-20" value="">
                                    Select
                                  </option>
                                  <option className="h-20" value="TextField">
                                    TextField
                                  </option>
                                  <option className="h-20" value="ChoiceField">
                                    ChoiceField
                                  </option>
                                  <option className="h-20" value="FileField">
                                    File
                                  </option>
                                  <option className="h-20" value="BoolField">
                                    Boolean
                                  </option>
                                  <option className="h-20" value="VideoField">
                                    Video
                                  </option>
                                  <option className="h-20" value="ImageField">
                                    Image
                                  </option>
                                </select>
                              </div>

                              {data[`questionType${i}`] === "ChoiceField" ? (
                                <>
                                  <div className="col-xl-12 section_margin_top">
                                    <p className="mt-3">
                                      First Option{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </p>
                                    <input
                                      className="w-full border h-10 rounded-md p-4"
                                      type="text"
                                      onChange={handleChange}
                                      name={`optionOne${i}`}
                                      placeholder="Type your first question here..."
                                    ></input>
                                  </div>
                                  <div className="col-xl-12 section_margin_top">
                                    <p className="mt-3">
                                      Second Option{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </p>
                                    <input
                                      className="w-full border h-10 rounded-md p-4"
                                      name={`optionTwo${i}`}
                                      onChange={handleChange}
                                      type="text"
                                      placeholder="Type your first question here..."
                                    ></input>
                                  </div>
                                  <div className="col-xl-12 section_margin_top">
                                    <p className="mt-3">Third Option</p>
                                    <input
                                      className="w-full border h-10 rounded-md p-4"
                                      name={`optionThree${i}`}
                                      onChange={handleChange}
                                      type="text"
                                      placeholder="Type your first question here..."
                                    ></input>
                                  </div>
                                  <div className="col-xl-12 section_margin_top">
                                    <p className="mt-3">Forth Option</p>
                                    <input
                                      className="w-full border h-10 rounded-md p-4"
                                      name={`optionThree${i}`}
                                      onChange={handleChange}
                                      type="text"
                                      placeholder="Type your first question here..."
                                    ></input>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="mt-4">
                    <div className="">
                      <Button color='warning'
                      variant='contained'
                        className=""
                        onClick={addQuestion}
                      >
                        + Add Question
                      </Button>
                    </div>
                    <div className='my-4 flex justify-center md:gap-[1rem] lg:gap-10'>
                    <Button variant='contained' color='error' size='large' onClick={()=>navigate(-1)}
                     className="my-4">Cancel</Button>
                     <Button variant='contained' size='large' onClick={createReview}
                     className="my-4">Create Review</Button>
                     </div>

                  </div>
                </div>
              </div>


            </div>
        </div>
    )
}

export default InterviewReview
