import DoneAllSharpIcon from "@mui/icons-material/DoneAllSharp";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import DetailsInput from "../../components/DetailsInput";
import Header from "../../components/Header";
import { MobileNumberInput } from "../../components/Input/MobileNumberInput";
import Sidebar from "../../components/Sidebar";
import { isValidCompany } from "../../helpers/validate_addcompany";
import { postRequest } from "../../utils/request";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const cookies = new Cookies();

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid rgb(107 114 128)",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            // borderColor: "#80bdff",
            // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const CompanyAdd = () => {
    const [company_name, setCompany_name] = useState("");
    const [company_founder, setCompany_founder] = useState("");
    const [company_ceo, setCompany_ceo] = useState("");
    const [company_employee, setCompany_employee] = useState("");
    const [company_description, setCompany_description] = useState("");
    const [company_phone_number, setCompany_phone_number] = useState("");
    const [company_email_address, setCompany_email_address] = useState("");
    const [contact_person, setContact_person] = useState("");
    const [contact_number, setContact_number] = useState("");
    const [contact_email, setContact_email] = useState("");
    const [established_in, setEstablished_in] = useState(new Date());
    const [working_sector, setWorking_sector] = useState("");
    const [status, setStatus] = useState(false);
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pin, setPin] = useState();
    const [address, setAddress] = useState("");
    const [company_logo, setCompany_logo] = useState([]);
    const [company_url, setCompany_url] = useState("");
    const [linkedin_url, setLinkedin_url] = useState("");
    const [facebook_url, setFacebook_url] = useState("");
    const navigate = useNavigate();
    const startDate = moment(established_in).format("YYYY-MM-DD");
    const [preview, setPreview] = useState("");

    useEffect(() => {
        if (cookies.get("token")) {
        } else {
            navigate("/");
        }
    }, []);

    // useEffect(() => {
    //   if(company_logo) {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       if(reader.readyState === 2) {
    //         setPreview(reader.result);
    //       }
    //     }
    //     reader.readAsDataURL(company_logo)
    //   } else {
    //     setPreview(null);
    //   }
    // }, [company_logo])

    const add = () => {
        const submitData = {
            name: company_name,
            company_number: company_phone_number,
            company_email: company_email_address,
            contact_person: contact_person,
            contact_number: contact_number,
            contact_email: contact_email,
            company_founder: company_founder,
            company_ceo: company_ceo,
            company_description: company_description,
            company_employee: company_employee,
            established_in: startDate,
            working_sector: working_sector,
            status: status,
            city: city,
            state: state,
            country: country,
            pin: pin,
            address: address,
            company_logo: company_logo,
            company_url: company_url,
            linkedin_url: linkedin_url,
            facebook_url: facebook_url,
        };

        const error = isValidCompany(submitData);
        if (error) return toast.error(error);

        let formField = new FormData();
        for (const key in submitData) {
            formField.append(key, submitData[key]);
        }

        postRequest(`hri_admin/company/add`, formField, "/companies");
    };

    return (
        <div className="bg-default-background h-screen overflow-y-scroll">
            <Header />
            <Sidebar />

            <div className="px-4">
                {/* <Navigation
          first="Companies List"
          second="ABC Company"
          third="Add new Company"
          start="/companies"
          mid="/companies/companies-details"
        /> */}
                {/* <p className="py-2">
                    <span
                        onClick={() => navigate("/companies")}
                        className="font-bold text-blue-600 cursor-pointer"
                    >
                        Companies List {">"}{" "}
                    </span>
                    <span className="font-bold cursor-pointer text-blue-600">
                        Add new Company{" "}
                    </span>
                </p> */}
                <div className="bg-white rounded-lg p-6 mt-5">
                    <Button
                        variant="text"
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={() => navigate(-1)}
                        >
                        Back
                    </Button>
                    <div className="flex items-center justify-start mx-3">
                        
                        <input
                            id="file-upload"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={e => {
                                const file = e.target.files[0];
                                if (
                                    file &&
                                    file?.type?.substring(0, 5) === "image"
                                ) {
                                    setCompany_logo(file);
                                } else {
                                    setCompany_logo(null);
                                }
                            }}
                        />
                        <label
                            htmlFor="file-upload"
                            className="flex flex-col items-center justify-center"
                        >
                            <Avatar
                                className="hover: cursor-pointer hover:opacity-50 duration-300"
                                sx={{ width: 70, height: 70 }}
                                src={preview}
                            ></Avatar>
                            <span className="text-gray-400 font-semibold text-lg my-1">
                                {typeof company_logo?.name !== "string"
                                    ? "Upload Logo"
                                    : "Re-upload Logo"}
                            </span>
                        </label>
                    </div>

                    <div className="grid grid-cols-1 gap-y-3 md:grid-cols-2 md:grid-cols-2 md:gap-4 items-center justify-center md:mx-12 lg:mx-32">
                        <div>
                            <DetailsInput
                                title="Company Name"
                                placeholder="Enter Company name"
                                value={company_name}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   company_name: e.target.value,
                                    // })
                                    setCompany_name(e.target.value)
                                }
                            />
                        </div>
                        <div
                            style={{
                                paddingTop: "1.2rem",
                            }}
                        >
                            {/* <DetailsInput
                                title="Company Phone Number"
                                placeholder="Enter company Phone Number"
                                value={company_phone_number}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   company_phone_number: e.target.value,
                                    // })
                                    setCompany_phone_number(e.target.value)
                                }
                            /> */}

                            <MobileNumberInput
                                label="Company Phone Number"
                                initVal={company_phone_number}
                                onChange={num => setCompany_phone_number(num)}
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="Company Email ID"
                                placeholder="Enter email address"
                                value={company_email_address}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   company_email_address: e.target.value,
                                    // })
                                    setCompany_email_address(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="Founder"
                                placeholder="Enter Name of the founder"
                                value={company_founder}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   company_founder: e.target.value,
                                    // })
                                    setCompany_founder(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="CEO"
                                placeholder="Enter Name of the CEO"
                                value={company_ceo}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   company_ceo: e.target.value,
                                    // })
                                    setCompany_ceo(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="HR"
                                placeholder="Enter the Name of HR"
                                value={contact_person}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   contact_person: e.target.value,
                                    // })
                                    setContact_person(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="HR Phone Number"
                                placeholder="Enter HR Phone Number"
                                value={contact_number}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   contact_number: e.target.value,
                                    // })
                                    setContact_number(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="HR Email ID"
                                placeholder="Enter HR email address"
                                value={contact_email}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   contact_email: e.target.value,
                                    // })
                                    setContact_email(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <span className="text-gray-400 font-semibold text-lg">
                                Employees
                            </span>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-customized-select-label"
                                    value={company_employee}
                                    onChange={e =>
                                        // setVariable({
                                        //   ...variable,
                                        //   company_employee: e.target.value,
                                        // })
                                        setCompany_employee(e.target.value)
                                    }
                                    input={<BootstrapInput />}
                                >
                                    <MenuItem value={"10-50"}>10-50</MenuItem>
                                    <MenuItem value={"50-100"}>50-100</MenuItem>
                                    <MenuItem value={"100-200"}>
                                        100-200
                                    </MenuItem>
                                    <MenuItem value={"200-500"}>
                                        200-500
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="mt-[15px]">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Established In"
                                    value={established_in}
                                    // style={{width: '100%'}}

                                    onChange={newValue =>
                                        // setVariable({ ...variable, established_in: newValue })
                                        setEstablished_in(newValue)
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                width: "100%",
                                                height: "70%",
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div>
                            <span className="text-gray-400 font-semibold text-lg">
                                Working Sector
                            </span>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-customized-select-label"
                                    value={working_sector}
                                    onChange={e =>
                                        // setVariable({
                                        //   ...variable,
                                        //   working_sector: e.target.value,
                                        // })
                                        setWorking_sector(e.target.value)
                                    }
                                    input={<BootstrapInput />}
                                >
                                    <MenuItem value={"IT Company"}>
                                        IT Company
                                    </MenuItem>
                                    <MenuItem value={"Software Solutions"}>
                                        Software Solutions
                                    </MenuItem>
                                    <MenuItem value={"Digital Marketing"}>
                                        Digital Marketing
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <span className="text-gray-400 font-semibold text-lg">
                                Hiring Status
                            </span>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-customized-select-label"
                                    value={status}
                                    onChange={e =>
                                        // setVariable({
                                        //   ...variable,
                                        //   status: e.target.value,
                                        // })
                                        setStatus(e.target.value)
                                    }
                                    input={<BootstrapInput />}
                                >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>In-Active</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <DetailsInput
                                title="City"
                                placeholder="Enter the city name"
                                value={city}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   city: e.target.value,
                                    // })
                                    setCity(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="State"
                                placeholder="Enter the state name"
                                value={state}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   state: e.target.value,
                                    // })
                                    setState(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="Country"
                                placeholder="Enter the country name"
                                value={country}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   country: e.target.value,
                                    // })
                                    setCountry(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="Pin"
                                placeholder="Enter the pin code"
                                value={pin}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   pin: e.target.value,
                                    // })
                                    setPin(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="Location"
                                placeholder="Choose the right location in google maps or Type manually"
                                span="col-span-2"
                                value={address}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   address: e.target.value,
                                    // })
                                    setAddress(e.target.value)
                                }
                            />
                        </div>

                        <div>
                            <DetailsInput
                                title="Company website Link"
                                placeholder="Add Company official website"
                                value={company_url}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   company_url: e.target.value,
                                    // })
                                    setCompany_url(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="LinkedIn Link"
                                placeholder="Add company linkedIn profile"
                                value={linkedin_url}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   linkedin_url: e.target.value,
                                    // })
                                    setLinkedin_url(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="Facebook Link"
                                placeholder="Add company facebook link"
                                value={facebook_url}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   facebook_url: e.target.value,
                                    // })
                                    setFacebook_url(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <DetailsInput
                                title="Company Description"
                                placeholder="Describe anything about the company"
                                span="col-span-2"
                                textarea
                                value={company_description}
                                onChange={e =>
                                    // setVariable({
                                    //   ...variable,
                                    //   company_description: e.target.value,
                                    // })
                                    setCompany_description(e.target.value)
                                }
                            />
                        </div>
                    </div>

                    <div className="flex justify-end mt-4" onClick={add}>
                        <button className="font-semibold bg-cyan-600 rounded-md px-4 py-2 text-white">
                            Save <DoneAllSharpIcon style={{ height: 18 }} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyAdd;
