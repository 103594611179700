import { Cancel, WorkspacePremium } from '@mui/icons-material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsDiamond, BsFillDiamondFill } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { changeprofileVerificationStatus } from '../../controller/profileVerificationController';
import { handleControllerResultException } from '../../utils/handleException';
import { HTTPStatus } from '../../utils/helperObj';
import Image from "./Avatar.png";

const VerifyDetail = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [status, setStatus] = useState("");
    const [changingStatus, setChangingStatus] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [profileId , setProfileId] = useState(0)
    const { id}  = useParams()
    


    useEffect(() => {
        getData();
    }, [])

    function getData() {
        // setIsPending(true);
        const token = cookies.get("token");
      
        const request1 = axios.get(`hri_admin/verification-detail/${id}`, {
          headers: {
            Authorization: "Token " + token,
          },
        });
      
      
        axios.all([request1])
          .then(axios.spread((response1, response2) => {
            // Handle the response from the first request (response1)
            setData(response1.data);
            setProfileId(response1.data.id)
            setStatus(response1.data.status)
            setIsPending(false);
      
            // Handle the response from the second request (response2)
            
          }))
          .catch((error) => {
            toast.warning(error.message, {
              position: "top-center"
            });
            console.log(error);
          });
      }

      async function handleChangeStatus(status) {
        setChangingStatus(true);
        const res = await changeprofileVerificationStatus(profileId, status);
        setChangingStatus(false);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            message.success(res.success);
        } else {
            handleControllerResultException(res, navigate);
        }
    }
    
    return (
        <div className="bg-default-background w-full">
            <Header />
            <Sidebar />
            <div className="">
                {isPending ? <div className='flex justify-center items-center h-[90vh]'><h1 className='text-3xl'>Loading<span className='animate-pulse'>.</span><span className='animate-pulse'>.</span><span className='animate-ping'>.</span></h1></div>
                    :
                    <div className="mt-2 pb-20 mx-2 bg-white">
                        <Button onClick={() => navigate(-1)} size="large" className="text-blue-600 text-xl cursor-pointer w-12 p-2 my-2 mx-4"><NavigateBeforeIcon />Back</Button>
                        <div className="flex flex-col">
                            <div className="flex flex-col my-10 sm:flex-row justify-between">
                                <div className="flex gap-3 w-32 lg:ml-[2.5rem]">
                                    <img src={data.personal_details.profile_pic ? data.personal_details.profile_pic : Image} alt="" className=" md:w-32 md:h-32 rounded-full ml-2" />
                                    <div className="text-xl font-bold mt-[1rem] lg:ml-6 lg:mt-[2rem]">{data.personal_details.name || "Not Available"}</div>
                                    <div className="p-4 text-base whitespace-nowrap">
                                        <div className="bg-slate-100 px-2 py-2 rounded-lg text-blue-500 hover:text-blue-700 hover:shadow cursor-pointer">{data.work_experience ? "Experience" : "Fresher"}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col md:ml-[10rem] lg:ml-[30rem]">
                                    <div className="flex">
                                        <Link to={`/send-message?user=${data.user}`}>
                                            <div className="p-3 text-base whitespace-nowrap">
                                                <div className="bg-slate-100 px-2 py-2 rounded-lg text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer">Message <MessageOutlinedIcon /></div>
                                            </div>
                                        </Link>
                                        <a target='_blank' href={`mailto:${data.user_email}`} rel="noreferrer">
                                            <div className="p-3 text-base whitespace-nowrap">
                                                <div className="bg-slate-100 px-2 py-2 rounded-lg text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer">Mail <EmailOutlinedIcon /></div>
                                            </div>
                                        </a>
                                    </div>
                                    <Link to={`/applications-details/${data.user}`}>
                                        <div className="p-3 text-base whitespace-nowrap">
                                            <div  className="flex items-center justify-center bg-slate-100 px-1 py-1 rounded-lg text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer">View Profile</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row">
                                <div className="flex flex-col">
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">Report ID</div><div className="text-xl ml-8">:</div><div className="text-xl ml-[1rem]">{data.report_unique_id || "Not available"}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">Aadhar Card</div><div className="text-xl ml-2">:</div><div className="text-xl  ml-[1rem]">{data.personal_details.aadhaar_card ? data.personal_details.aadhaar_card : "0000-0000-0000"}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">PAN Card</div><div className="text-xl ml-8">:</div><div className="text-xl ml-[1rem]">{data.personal_details.pan_card || "Not Available"}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">Status</div>
                                        <div className="text-xl ml-[4rem] mr-4">:</div>
                                        <FormControl variant="standard" sx={{ m: 0, minWidth: 140 }}>
                                            <Select
                                                disabled={changingStatus}
                                                value={status}
                                                onChange={e => {
                                                    handleChangeStatus(e.target.value);
                                                    setStatus(e.target.value);
                                                }}
                                                variant="standard"
                                            >
                                                <MenuItem value={"Initiated"}>Initiated</MenuItem>
                                                <MenuItem value={"Verified"}>Verified</MenuItem>
                                                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[13rem]">Report Type</div><div className="text-xl ml-3 lg:ml-[7rem]">:</div><div className="text-xl  ml-[1rem]">{data.report_type || "Not available"}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[13rem]">Parent / Guardian Name</div><div className="text-xl ml-3">:</div><div className="text-xl  ml-[1rem]">{data.personal_details.guardian_name || "Not Available"}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[13rem]">Parent / Guardian  Ph.no</div><div className="text-xl lg:ml-[1rem]">:</div><div className="text-xl ml-[1rem]">{data.personal_details.guardian_phn_no || " Not Available"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="text-neutral-600 md:ml-[5rem] text-lg mt-[2rem] ml-1">{data.updated_at.slice(0, 10)}</div>
                                <div className="flex">
                                    {data.status === "Initiated" ?
                                        <div className='mt-10 ml-8'>
                                            <div>
                                                <BsFillDiamondFill className='text-blue-600' />
                                                <div className='w-[2px] h-32 mx-auto border-r-[2px] border-blue-600'>
                                                </div>
                                            </div>
                                            <div>
                                                <BsDiamond className='text-blue-600' />
                                                <div className='w-[2px] border-r-[2px] border-dashed border-blue-500 h-[4.5rem] mx-auto '>
                                                </div>
                                            </div>
                                            <div>
                                                <BsDiamond className='text-blue-600' />
                                                <div className='w-[2px] border-r-[2px] border-dashed border-blue-600 h-[4rem] mx-auto '>
                                                </div>
                                            </div>
                                            <div className='text-blue-500'>
                                                <BsDiamond className='text-blue-600' />

                                            </div>
                                        </div>
                                        : data.status === "Initiated" ?
                                            <div className='mt-10 ml-8'>
                                                <div>
                                                    <BsFillDiamondFill className='text-blue-600' />
                                                    <div className='w-[2px] h-32 mx-auto border-r-[2px] border-blue-600'>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BsFillDiamondFill className='text-blue-600' />
                                                    <div className='w-[2px] border-r-[2px] border-blue-500 h-[4.5rem] mx-auto '>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BsDiamond className='text-blue-600' />
                                                    <div className='w-[2px] border-r-[2px] border-dashed border-blue-600 h-[4rem] mx-auto '>
                                                    </div>
                                                </div>
                                                <div className='text-blue-500'>
                                                    <BsDiamond className='text-blue-600' />

                                                </div>
                                            </div>
                                            : data.status === "Verified" ?
                                                <div className='mt-10 ml-8'>
                                                    <div>
                                                        <BsFillDiamondFill className='text-green-500' />
                                                        <div className='w-[2px] h-32 mx-auto border-r-[2px] border-green-500'>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-green-500' />
                                                        <div className='w-[2px] border-r-[2px] border-green-500 h-[4.5rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-green-500' />
                                                        <div className='w-[2px] border-r-[2px] border-green-500 h-[4rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div className='text-blue-500'>
                                                        <BsFillDiamondFill className='text-green-600' />

                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-10 ml-8'>
                                                    <div>
                                                        <BsFillDiamondFill className='text-red-500' />
                                                        <div className='w-[2px] h-32 mx-auto border-r-[2px] border-red-500'>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-red-500' />
                                                        <div className='w-[2px] border-r-[2px]  border-red-500 h-[4.5rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-red-500' />
                                                        <div className='w-[2px] border-r-[2px] border-red-500 h-[4rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div className='text-red-500'>
                                                        <BsFillDiamondFill className='text-red-500' />

                                                    </div>
                                                </div>

                                    }

                                </div>
                               
                                    {          
                                    data.status === "Rejected" ?                                    
                                    <div className="flex flex-col">
                                    <div className="text-lg font-bold mt-[2rem] ml-[2rem]">Uploaded</div>
                                    <div className="text-lg font-bold mt-[7rem] ml-[2rem]">Working in Progress</div>
                                    <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Contacted:</div>
                                    <div className="text-base text-neutral-600 ml-[2rem]">ABC University of Delhi</div>
                                    {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                        <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">View Report</div>
                                    </div> */}
                                    <div className="text-lg font-bold mt-[3rem] ml-[2rem]"><Cancel className='text-red-500'/>Rejected</div>
                                    <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Note : The person is vrifiedby the candidate father name ramu</div>
                                    {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                        <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">Re - Verify</div>
                                    </div> */}
                                    </div>
                                    :
                                    <div className="flex flex-col">
                                    <div className="text-lg font-bold mt-[2rem] ml-[2rem]">Uploaded</div>
                                    <div className="text-lg font-bold mt-[7rem] ml-[2rem]">Working in Progress</div>
                                    <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Contacted:</div>
                                    <div className="text-base text-neutral-600 ml-[2rem]">ABC University of Delhi</div>
                                    {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                        <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">View Report</div>
                                    </div> */}
                                    <div className="text-lg font-bold mt-[3rem] ml-[2rem]">{data.status === "Verified" ? <span className='flex'><WorkspacePremium className="text-blue-500 animate-[wiggle_1s_ease-in-out_infinite]" />Verified</span> :"Verified"}</div>
                                    <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Note : The person is vrifiedby the candidate father name ramu</div>
                                    {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                        <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">Re - Verify</div>
                                    </div> */}
                                    </div>
                                    }
                               
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div >
    )
}

export default VerifyDetail
