
import axios from "axios";
import { handleException } from "../utils/handleException";
import { BackendAPI } from "../constants/backendAPI";
import Cookies from "universal-cookie";
import { HttpResponse } from "../models/HttpResponse";

const cookies = new Cookies();

/**
 * @typedef {object} MeetingParams
 * @property {string[]} studentEmails
 * @property {string} title
 * @property {string} date
 * @property {string} startingTime
 * @property {string} meetingLink
 * @property {string} meetingNote
 * @property {string} meetingPassCode
 * @property {string} duration
 * @property {string} companyEmail
 */

/**
 *
 * @param {MeetingParams} param
 */
export async function scheduleMeeting({
    studentEmails,
    title,
    date,
    startingTime,
    duration,
    meetingLink,
    meetingNote,
    meetingPassCode,
    companyEmail
}) {
    try {
        const res = await axios.post(BackendAPI.meeting.createMeeting, {
            user_emails: studentEmails,
            title,
            date,
            start_time: startingTime,
            duration,
            note: meetingNote,
            meeting_link: meetingLink,
            meeting_password: meetingPassCode,
            company: companyEmail
        }, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        });
        return new HttpResponse(res.status, "Meeting created succesfuly", null);
    } catch (err) {
        return handleException(err);
    }
}
