import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { BsDiamond, BsFillDiamondFill } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Avatar from "./Avatar.png";
import { WorkspacePremium } from '@mui/icons-material';
import { Box, Button, Container, Pagination, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import noInterview from "../../Images/empty_states/no-interview.png";
import { getInterview } from '../../controller/interviewController';
import { handleControllerResultException } from '../../utils/dataFetchHelper';

const initialinterviewState = [];

function Interview() {
    const { interviewType } = useParams();
    const navigate = useNavigate();
    const interviewTypes = useMemo(() => ["All", "Initiated", "Selected", "Rejected"], []);
    const [interviewList, setInterviewList] = useState(initialinterviewState);
    const [totalPages, setTotalPages] = useState(0);
    const [currentTab, setCurrentTab] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const handleInterview = useCallback(async (interviewType, currentPage = 1) => {
        const res = await getInterview(interviewType, currentPage);
        if (res[0] !== null) {
            setInterviewList(res[0].result || []);
            setTotalPages(Math.ceil((res[0].count || 0) / 10));
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, [navigate]);

    useEffect(() => {
        handleInterview(interviewType);
    }, [handleInterview, interviewType]);

    useEffect(() => {
        if (interviewType && interviewTypes.includes(interviewType)) {
            setCurrentTab(interviewTypes.indexOf(interviewType));
        }
    }, [interviewType, interviewTypes]);

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "5rem auto", padding: { xs: "1rem", sm: "2rem" } }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "space-between", gap: { xs: 2, sm: 0 } }}>
                    <Tabs
                        value={currentTab}
                        onChange={(_e, value) => {
                            setCurrentPage(1);
                            navigate(`/interview/${interviewTypes[value]}`);
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        {interviewTypes.map((type, index) => (
                            <Tab key={index} label={type} sx={{ textTransform: "capitalize" }} />
                        ))}
                    </Tabs>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'stretch', sm: 'center' } }}>
                        <Button variant="contained" color="warning" onClick={() => navigate("/interview/review")}>Review Form</Button>
                        <Button variant="contained" onClick={() => navigate("/interview/add-new")}>+ New Interview</Button>
                    </Box>
                </Box>
                {interviewList.length > 0 ? (
                    <div>
                        <TableContainer component={Paper} elevation={2} sx={{ mt: 4, mb: 10 }}>
                            <Table sx={{ width: "100%" }}>
                                <TableHead sx={{ backgroundColor: blue[700] }}>
                                    <TableRow>
                                        <TableCell sx={{ color: "white" }}>
                                            Status
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Employee
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Skills
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Time and Date
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Duration
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Details
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {interviewList.map(interview => (
                                        <TableRow key={interview.id}>
                                            <TableCell>
                                                {interview.progress_status === "Initiated" ? (
                                                    <Box display="flex" alignItems="center" color="green" gap={1}>
                                                        <BsDiamond />
                                                        <Typography>{interview.progress_status}</Typography>
                                                    </Box>
                                                ) : interview.progress_status === "Selected" ? (
                                                    <Box display="flex" alignItems="center" color="blue" gap={1}>
                                                        <WorkspacePremium />
                                                        <Typography>{interview.progress_status}</Typography>
                                                    </Box>
                                                ) : interview.progress_status === "Rejected" ? (
                                                    <Box display="flex" alignItems="center" color="red" gap={1}>
                                                        <BsFillDiamondFill />
                                                        <Typography>{interview.progress_status}</Typography>
                                                    </Box>
                                                ) : (
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        <BsDiamond />
                                                        <Typography>{interview.progress_status}</Typography>
                                                    </Box>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center" gap={2}>
                                                    <Box width={48} height={48}>
                                                        <img src={interview.personal_details?.profile_pic || Avatar} alt="profile_pic" style={{ width: '100%', borderRadius: '50%' }} />
                                                    </Box>
                                                    <Typography>{interview.personal_details?.name || "Not Available"}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell><Typography>{interview.skill}</Typography></TableCell>
                                            <TableCell>
                                                <Typography>{interview.date}</Typography>
                                                <Typography>{interview.format_time}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {interview.duration !== 61 ? (
                                                    <Typography>{interview.duration} minutes</Typography>
                                                ) : (
                                                    <Typography>1+ Hours</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='outlined' onClick={() => navigate(`/interview/view-details/${interview.id}`)}>
                                                    View Details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display={"flex"} justifyContent={"center"} margin={"2rem 0rem"}>
                            <Pagination
                                count={totalPages}
                                color="primary"
                                page={currentPage}
                                onChange={(_e, value) => {
                                    setCurrentPage(value);
                                    handleInterview(interviewType, value);
                                }}
                            />
                        </Box>
                    </div>
                ) : (
                    <Box display={"flex"} justifyContent={"center"} marginTop={2}>
                        <Box>
                            <img
                                src={noInterview}
                                alt="no interview"
                                height={"200px"}
                                width={"200px"}
                            />
                            <Typography
                                textAlign={"center"}
                                margin={"1rem 0rem"}
                                color={grey[600]}
                                fontSize={"14px"}
                            >
                                No Interview Scheduled
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Container>
        </Box>
    );
}

export default Interview;
