import { containsOnlyLetters, isValidSocialLink } from "../utils/validation";

export function isValidPersonalData(data) {
    if (!data.name || containsOnlyLetters(data.name) === false)
        return "Enter a valid Name";

    // if (!data.country || !data.state || !data.city)
    //     return "Please select country, city and state";

    if (data.github_id && isValidSocialLink("github", data.github_id) === false)
        return "Please enter a valid github profile link";

    if (
        data.linkedin_id &&
        isValidSocialLink("linkedin", data.linkedin_id) === false
    )
        return "Please enter a valid linkedin profile link";

    return null;
}
