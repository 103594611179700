import {
    ArrowBackIos,
    Edit,
    FormatListBulleted,
    MoreVert,
    People,
    Share,
} from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Admin_FE_url, User_Fe_url } from "../../..";
import ActiveJobHeader from "../../../components/ActiveJobHeader";
import Header from "../../../components/Header";
import LabeledText from "../../../components/LabeledText";
import RichDescription from "../../../components/richdescription/RichDescription";
import { getJobDetails } from "../../../controller/JobController";
import { handleControllerResultException } from "../../../utils/handleException";
import JobAppliedApplicantsModal from "./JobAppliedApplicantsModal";
import MatchingProfileModal from "./MatchingProfileModal";

/**
 * @typedef {object} Props
 * @property {"protected"|"public"} visibility
 */

/**
 * @param {Props} props
 */
export default function JobDescription({ visibility = "protected" }) {
    const [jobDetails, setJobDetails] = useState(jobDetailType);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [anchorOptions, setAnchorOptions] = useState(null);
    const openOptions = Boolean(anchorOptions);
    const [showApplicantListModal, setShowApplicantListModal] = useState(false);
    const [showMatchingProfileModal, setShowMatchingProfileModal] =
        useState(false);

    const handleFetchJobDescription = useCallback(
        async (jobId, visibility) => {
            setLoading(true);
            const res = await getJobDetails(jobId, visibility);
            setLoading(false);
            if (res[0] !== null) {
                setJobDetails(res[0]);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (id) {
            handleFetchJobDescription(id, visibility);
        }
    }, [id, handleFetchJobDescription, visibility]);

    function copyShareLink() {
        navigator.clipboard.writeText(
            `${Admin_FE_url}posted-jobs/active-job/${id}`
        );
        message.success("Link is copied");
    }

    if (loading) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress size={"2rem"} />
            </Box>
        );
    }

    return (
        <Box>
            {visibility === "protected" ? (
                <Header />) : (<ActiveJobHeader />)}
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>
                <Grid container spacing={4}>
                    {visibility === "protected" &&
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                <ArrowBackIos />
                                Return
                            </Button>
                        </Grid>}
                    <Grid item xs={4}>
                        <LabeledText
                            heading="Date:"
                            text={jobDetails.date}
                            sx={{ display: "flex", alignItems: "center" }}
                            textStyles={{ marginLeft: "5px" }}
                        />
                    </Grid>
                    <Grid item xs={4}>

                    {jobDetails.companyName ? (
                        <div 
                            onClick={() => navigate(`/companies/companies-details/${jobDetails.company}`)} 
                            style={{ cursor: 'pointer' }} // This will change the cursor to a pointer on hover
                        >
                            <LabeledText
                                heading="Company Name:"
                                text={jobDetails.companyName}
                                sx={{ display: "flex" , justifyContent:"flex-end" }}
                                textStyles={{ marginLeft: "5px" }}
                            />
                        </div>
                    ) : (
                        <LabeledText
                            heading="Company Name:"
                            text="Not Available"
                            sx={{ display: "flex" , justifyContent:"flex-end" }}
                            textStyles={{ marginLeft: "5px" }}
                        />
                    )}
                        
                    </Grid>
                    <Grid item xs={4}>
                        <Box display={"flex"} justifyContent={"flex-end"}>
                            {visibility === "protected" ? (
                                <>
                                    <Button
                                        onClick={e =>
                                            setAnchorOptions(
                                                anchorOptions === null
                                                    ? e.currentTarget
                                                    : null
                                            )
                                        }
                                    >
                                        <MoreVert />
                                    </Button>
                                    <Menu
                                        components={Paper}
                                        open={openOptions}
                                        anchorEl={anchorOptions}
                                        onClose={() => setAnchorOptions(null)}
                                        elevation={2}
                                    >
                                        <MenuItem
                                            onClick={() =>
                                                setShowApplicantListModal(true)
                                            }
                                        >
                                            <FormatListBulleted
                                                fontSize="small"
                                                sx={{ marginRight: "10px" }}
                                            />
                                            Applicant List
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                setShowMatchingProfileModal(
                                                    true
                                                )
                                            }
                                        >
                                            <People
                                                fontSize="small"
                                                sx={{ marginRight: "10px" }}
                                            />
                                            Matching Profiles
                                        </MenuItem>
                                        {jobDetails.isActive ? 
                                            <MenuItem onClick={copyShareLink}>
                                            <Share
                                                fontSize="small"
                                                sx={{ marginRight: "10px" }}
                                            />
                                            Share
                                        </MenuItem> : <></>}
                                        
                                        <MenuItem
                                            onClick={() =>
                                                navigate(`/add-job/${id}`)
                                            }
                                        >
                                            <Edit
                                                fontSize="small"
                                                sx={{ marginRight: "10px" }}
                                            />
                                            Edit
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <a
                                    href={`${User_Fe_url}applications/job-detail/${id}`}
                                    target="_blank" rel="noreferrer"
                                >
                                    {" "}
                                    <Button variant="contained">
                                        Apply Now
                                    </Button>
                                </a>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Position"
                            text={jobDetails.positionName}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Experience"
                            text={jobDetails.experience}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Employment Type"
                            text={jobDetails.employmentType}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Location"
                            text={jobDetails.location}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Salary/Stipend"
                            text={jobDetails.salary ? jobDetails.salary:"Not Disclosed"}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Role"
                            text={jobDetails.roleSubCategoryName ? jobDetails.roleSubCategoryName : "No Role"}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Role Cateogory"
                            text={jobDetails.roleCategoryName ? jobDetails.roleCategoryName : "No Role Category"}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Education"
                            text={jobDetails.education}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Functional Area"
                            text={jobDetails.functionalArea ? jobDetails.functionalArea : "N/A"}
                        />
                    </Grid>
                    {jobDetails.skills !== "" && (
                        <Grid item xs={12}>
                            <Typography fontSize={"16px"}>Skills</Typography>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                flexWrap={"wrap"}
                            >
                                {jobDetails.skills
                                    .split(",")
                                    .map((data, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                backgroundColor: grey[200],
                                                padding: "5px 20px",
                                                borderRadius: "20px",
                                                margin: "10px",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                            }}
                                        >
                                            {data}
                                        </Box>
                                    ))}
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography fontSize={"16px"}>Description</Typography>
                        <RichDescription>
                            {jobDetails.jobDescription}
                        </RichDescription>
                    </Grid>

                </Grid>
            </Container>
            {visibility === "protected" && (
                <>

                    <JobAppliedApplicantsModal
                        open={showApplicantListModal}
                        onClose={() => setShowApplicantListModal(false)}
                        jobId={id}
                        hasQuestionnaries={jobDetails.hasQuestionnaire}
                    />
                    <MatchingProfileModal
                        open={showMatchingProfileModal}
                        onClose={() => setShowMatchingProfileModal(false)}
                        jobId={id}
                    />
                </>
            )}
        </Box>
    );
}

/**
 * @type {import('../../../models/JobModel').JobModel}
 */
const jobDetailType = {
    id: 0,
    company: "",
    companyName: "",
    education: "",
    employmentType: "",
    experience: "",
    functionalArea: "",
    jobDescription: "",
    location: "",
    positionName: "",
    roleCategoryName: "",
    roleSubCategoryName: "",
    salary: "",
    skills: "",
};
