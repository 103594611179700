import axios from "axios";
import { handleException } from "../utils/handleException";
import Cookies from "universal-cookie";
import { HttpResponse } from "../models/HttpResponse";
import { createWebinarObj } from "../models/WebinarModel";
import { BackendAPI } from "../constants/backendAPI";

const cookie = new Cookies();

export async function createWebinar({
    title,
    date,
    startTime,
    relatedSkills,
    contactEmail,
    isOnline,
    price,
    onlineMeetingLink,
    onlineMeetingPasscode,
    offlineAddress,
    city,
    country,
    pin,
    state,
    description,
    speaker,
    speakerDesignation,
}) {
    try {
        const res = await axios.post(
            BackendAPI.Webinar.CreateWebinar,
            {
                title,
                date,
                description,
                start_time: startTime,
                related_skills: relatedSkills.replace(/ /g, ","),
                speaker,
                speaker_designation: speakerDesignation,
                contact_email: contactEmail,
                is_online: isOnline,
                is_free: !price <= 0,
                price,
                online_meeting_link: onlineMeetingLink,
                online_meeting_passcode: onlineMeetingPasscode,
                offline_address: offlineAddress,
                offline_city: city,
                offline_state: state,
                offline_country: country,
                offline_pin: pin,
            },
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Webinar successfully created");
    } catch (err) {
        return handleException(err);
    }
}

/**
 *
 * @param {number} page
 *  @param {"previous"|"upcoming"} webinarType
 * @returns {Promise<[{results:import("../models/WebinarModel").WebinarModel[], count:number} | null, HttpResponse | null]>}
 */
export async function getWebinarList(page = 1, webinarType) {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.Webinar.WebinarList, {
            params: {
                page,
                webinar_type: webinarType,
            },
            headers: {
                Authorization: `Token ${cookie.get("token")}`,
            },
        });
        const webinarArr = [];
        for (let x of res.data.results) {
            webinarArr.push(createWebinarObj(x));
        }
        result[0] = {
            results: webinarArr,
            count: res.data.count,
        };
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {number} webinarId
 * @returns {Promise<[import("../models/WebinarModel").WebinarModel|null, HttpResponse|null]>}
 */
export async function getWebinarDetail(webinarId) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${BackendAPI.Webinar.WebinarDetail}/${webinarId}`,
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );
        result[0] = createWebinarObj(res.data);
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

export async function updateWebinarDetail({
    id,
    title,
    date,
    startTime,
    relatedSkills,
    contactEmail,
    isOnline,
    price,
    onlineMeetingLink,
    onlineMeetingPasscode,
    offlineAddress,
    city,
    country,
    pin,
    state,
    description,
    speaker,
    speakerDesignation,
}) {
    try {
        const res = await axios.put(
            `${BackendAPI.Webinar.UpdateWebinar}/${id}`,
            {
                title,
                date,
                description,
                start_time: startTime,
                related_skills: relatedSkills.replace(/ /g, ","),
                speaker,
                speaker_designation: speakerDesignation,
                contact_email: contactEmail,
                is_online: isOnline,
                is_free: !price <= 0,
                price,
                online_meeting_link: onlineMeetingLink,
                online_meeting_passcode: onlineMeetingPasscode,
                offline_address: offlineAddress,
                offline_city: city,
                offline_state: state,
                offline_country: country,
                offline_pin: pin,
            },
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Update succesfully");
    } catch (err) {
        return handleException(err);
    }
}
