import Cookies from "universal-cookie";
import { handleException } from "../utils/handleException";
import axios from "axios";
import { BackendAPI } from "../constants/backendAPI";
import { createCompanyMailObj, createCompanyObj } from "../models/CompanyModel";

const cookies = new Cookies();

/**
 *
 * @param {string} searchText
 * @returns {Promise<[import("../models/CompanyModel").CompanyModel[]|null, import("../models/HttpResponse").HttpResponse | null]>}
 */
export async function getCompanyMailList(searchText = "") {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.company.companyMailList, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
            params: {
                keyword: searchText,
            },
        });
        const companyArr = [];
        for (let x = 0; x < res.data.length; x++) {
            companyArr.push(createCompanyMailObj(res.data[x]));
        }
        result[0] = companyArr;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}



/**
 * 
 * @param {string} companyName
 * @returns {Promise<[import("../models/CompanyModel").CompanyModel[]|null, import("../models/HttpResponse").HttpResponse | null]>} 
 */
export async function searchCompany(companyName) {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.company.companyList, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
            params: {
                keyword: companyName
            }
        });
        const companyArr = [];
        for (let data of res.data) {
            companyArr.push(createCompanyObj(data))
        }
        result[0] = companyArr;
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}