import React from 'react'
import { EmptyState } from '../../components/EmptyStates/EmptyState'
import error404 from "../../Images/empty_states/error404.png"

const ErrorPage = () => {
  return (
    <div>
      <EmptyState
      btn="Home"
      img={error404}
      title="Screen doesn’t exists"
      message="Oops! It seems like you've reached a dead end. The screen you're trying to access, /rdx, doesn't exist within the app."
      btnLink="/dashboard"
      />
    </div>
  )
}

export default ErrorPage
