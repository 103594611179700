/**
 * @typedef {object} TeamModel
 * @property {number} id
 * @property {number} userId
 * @property {string} name
 * @property {string} email
 */

export function createTeamObj(data) {
    /**
     * @type {TeamModel}
     */
    const result = {
        id: data.id,
        userId: data.user_id,
        name: data.name,
        email: data.email
    }
    return result
}