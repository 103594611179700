import { ArrowBackIos } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ReturnButton() {
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate(-1)} variant="contained">
            <ArrowBackIos sx={{ marginRight: "2px" }} fontSize="small" />
            Return
        </Button>
    );
}
