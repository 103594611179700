import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {Route, Routes, useNavigate} from "react-router-dom";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import BroadcastMessage from "./BroadcastMessage";
import All from "./All";
import Active from "./Active";
import InActive from "./Inactive";
import {Button} from "@mui/material";
import Request from "./Request";
import RequestDetails from "./RequestDetails";
import ActiveDetails from "./ActiveDetails";
import InactiveDetails from "./InactiveDetails";
import AllDetails from "./AllDetails";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const Broadcast = () => {

    const navigate = useNavigate();
    const [variable, setVariable] = useState("");

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    return(
        <div className="bg-default-background h-screen overflow-auto">
            <Header/>
            <Sidebar/>
            <div>
                <TabsUnstyled defaultValue={0} className="m-2">

                <TabsListUnstyled className="flex flex-wrap bg-white rounded-lg flex items-center text-neutral-400 content-between min-w-[220px]">
                    <div className="py-1 px-2">
                        <p onClick={() => navigate("")}
                        className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base cursor-pointer
                            m-1 py-1 px-2.5
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                            ${window.location.pathname === "/broadcast" ? "bg-[#F1F1F1] text-sky-700" : ""}
                             ${window.location.pathname === "/broadcast/details" ? "bg-[#F1F1F1] text-sky-700" : ""}`}>
                            All
                        </p>
                    </div>

                    <div className="py-1 px-2">
                        <p
                            onClick={() => navigate("active")}
                            className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base cursor-pointer
                            m-1 py-1 px-2.5
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-1.5 lg:m-1.5
                            ${window.location.pathname === "/broadcast/active" ? "bg-[#F1F1F1] text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/active-details" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                        >
                            Active
                        </p>
                    </div>
                    <div
                        onClick={() => navigate("in-active")}
                    >
                        <p
                            className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base cursor-pointer
                                m-1 py-1 px-2.5
                                md:px-8 md:text-xl md:py-2 md:1.5
                                lg:px-10 lg:text-xl lg:py-1.5 lg:m-1.5
                                ${window.location.pathname === "/broadcast/in-active" ? "bg-[#F1F1F1] text-sky-700" : ""}
                                ${window.location.pathname === "/broadcast/inactive-details" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                        >
                            InActive
                        </p>
                    </div>
                    <div
                        className="flex-grow"
                        onClick={() => navigate("request")}
                    >
                        <p
                            align="center"
                            className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base cursor-pointer
                                m-1 py-1 px-2.5 w-20
                                md:px-8 md:text-xl md:py-2 md:m-1.5 md:w-36
                                lg:px-10 lg:text-xl lg:py-1.5 lg:m-1.5
                                ${window.location.pathname === "/broadcast/request" ? "bg-[#F1F1F1] text-sky-700" : ""}
                                ${window.location.pathname === "/broadcast/request-details" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                    >
                            Request
                        </p>
                    </div>
                    <div className="p-1.5 ml-2">
                        <button
                            className="bg-[#0865B6] text-white text-sm mr-2 pl-4 pr-4 py-1 rounded md:text-lg md:mr-2"
                            onClick={() => navigate("message")}
                        >
                            Broadcast Message
                        </button>
                    </div>
                </TabsListUnstyled>

                <TabPanelUnstyled
                    className="w-full"
                    value={0} onClick={(e) => setVariable(e.currentTarget.id)}
                >
                </TabPanelUnstyled>

                <TabPanelUnstyled
                    className="w-full"
                    value={1} onClick={(e) => setVariable(e.currentTarget.id)}
                >
                </TabPanelUnstyled>

                <TabPanelUnstyled
                    className="w-full"
                    value={2} onClick={(e) => setVariable(e.currentTarget.id)}
                >
                </TabPanelUnstyled>



                <Routes>
                    {/*<Route path="/" element={<Navigate replace to="broadcast"/>}/>*/}
                    {/*<Route path="/message" element={<BroadcastMessage />} />*/}
                    <Route path="" element={<All />} />
                    <Route path="active" element={<Active />} />
                    <Route path="in-active" element={<InActive />} />
                    <Route path="details" element={<AllDetails />} />
                    <Route path="active-details" element={<ActiveDetails />} />
                    <Route path="inactive-details" element={<InactiveDetails />} />
                    <Route path="request" element={<Request />} />
                    <Route path="request-details" element={<RequestDetails />} />
                </Routes>
            </TabsUnstyled>
            </div>
        </div>
    )
}

export default Broadcast;