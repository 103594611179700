import {
    Box,
    Button,
    Container,
    Pagination,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import { Add, Edit } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { getSurveyList } from "../../controller/SurveyController";
import { handleControllerResultException } from "../../utils/handleException";
import { blue, grey } from "@mui/material/colors";
import noSurvey from "../../Images/empty_states/no-profileshared.png";

export default function Survey() {
    const [surveyList, setSurveyList] = useState(surveyListType);
    const [currentTab, setCurrentTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const tabList = ["survey", "assignment", "interview_review"];
    const handleSurveyList = useCallback(
        async (formType, page = 1) => {
            const res = await getSurveyList(formType, page);
            if (res[0] !== null) {
                setSurveyList(res[0].results);
                setTotalPages(Math.ceil(res[0].count / 10));
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        handleSurveyList(tabList[currentTab], currentPage);
    }, [currentTab, currentPage, handleSurveyList]);

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>
                <Typography sx={{ fontSize: "1.4rem", marginBottom: "1.6rem", fontWeight: "500", color: grey[800] }}>Survey list</Typography>

                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Link to="/survey-form">
                        <Button variant="contained">
                            <Add fontSize="small" />
                            Add
                        </Button>
                    </Link>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={currentTab}
                            onChange={(_e, index) => setCurrentTab(index)}
                        >
                            <Tab label="Survey" />
                            <Tab label="Assignment" />
                            <Tab label="Interview review" />
                        </Tabs>
                    </Box>
                </Box>
                {surveyList.length > 0 ? (
                    <TableContainer
                        component={Paper}
                        elevation={2}
                        sx={{ marginTop: "3rem" }}
                    >
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead sx={{ backgroundColor: blue[700] }}>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>
                                        Title
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Description
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Edit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {surveyList.map(data => (
                                    <TableRow key={data.id}>
                                        <TableCell>{data.title}</TableCell>
                                        <TableCell>
                                            {data.description}
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                to={`/survey-form/${data.id}`}
                                            >
                                                <Button variant="contained">
                                                    <Edit fontSize="small" />
                                                    Edit
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box display={"flex"} justifyContent={"center"} marginTop={2}>
                        <Box>
                            <img
                                src={noSurvey}
                                alt="no survey"
                                height={"200px"}
                                width={"200px"}
                            />
                            <Typography variant="h6" textAlign={"center"} marginTop={"5px"}>No Data Found</Typography>
                        </Box>
                    </Box>
                )}
                {totalPages > 1 && (
                    <Box
                        marginTop={"2rem"}
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={(_e, value) => setCurrentPage(value)}
                        />
                    </Box>
                )}
            </Container>
        </Box>
    );
}

/**
 * @type {import("../../models/SurveyModel").SurveyModel[]}
 */
const surveyListType = [];
