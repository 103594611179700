import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

/**
 * @typedef {object} Props
 * @property {number[]} educationIds
 * @property {React.Dispatch<React.SetStateAction<number[]>>} setEducationIds
 * @property {import('../../../models/UserApplicationModel').CertificateModel[]} educationList
 */
/**
 * @param {Props} props
 */
export default function EducationSelector({
    educationIds,
    setEducationIds,
    educationList,
}) {
    const [selectAll, setSelectAll] = useState(false);

    function handleCheck(checked, id) {
        if (checked) {
            setEducationIds(prevState => [...prevState, id]);
        } else {
            const arr = [...educationIds];
            let idIndex = arr.findIndex(data => data === id);
            idIndex >= 0 && arr.splice(idIndex, 1);
            setEducationIds(arr);
        }
    }

    function handleCheckAll(checked) {
        if (checked) {
            const newIdArr = [];
            educationList.forEach(data => {
                newIdArr.push(data.id);
            });
            setEducationIds(newIdArr);
            setSelectAll(true);
        } else {
            setEducationIds([]);
            setSelectAll(false);
        }
    }
    return (
        <Box>
            <Typography fontSize={"18px"}>Education / Certificates</Typography>
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={"10px"}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            onChange={e => handleCheckAll(e.target.checked)}
                        />
                    }
                    label={"Select all"}
                />
            </Box>
            {educationList.length > 0 && (
                <Grid container spacing={2} marginTop={"1rem"}>
                    {educationList.map(data => (
                        <Grid item xs={6} key={data.id}>
                            <Box display={"flex"} alignItems={"start"}>
                                <Checkbox
                                    checked={educationIds.includes(data.id)}
                                    onChange={e =>
                                        handleCheck(e.target.checked, data.id)
                                    }
                                />
                                <Box>
                                    <Typography
                                        fontSize={"16px"}
                                        fontWeight={"bold"}
                                    >
                                        {data.degree}
                                    </Typography>
                                    <Box>
                                        <Typography
                                            fontSize={"13px"}
                                        >{`${data.university}, ${data.city}`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}
