import { Avatar, Box, Button, Card, CardContent, Grid, Tooltip, Typography } from "@mui/material"
import JobStatus from "./JobStatus";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import ArticleIcon from "@mui/icons-material/Article";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupsIcon from '@mui/icons-material/Groups';
import picture from "../../Images/4041088.png";
import JobAppliedApplicantsModal from "../../Pages/jobs/Jobdetails/JobAppliedApplicantsModal";
import { useState } from "react";

export default function JobCard ({
    jobId , 
    companyLogo,
    positionName,
    isActive,
    experience,
    stipend,
    employmentType,
    location,
    criteria,
    skills,
    difference,
}) {
    const navigate = useNavigate()
    const [showApplicantListModal, setShowApplicantListModal] = useState(false);
    return (
        <Grid item lg={3} md={4} sm={6} xs={12} key={jobId} >
        <Card
          key={jobId}
          variant="outlined"
          style={{ borderRadius: "0.5rem" }}
        >
          <CardContent>
            <Grid container columns={{ xs: 6, md: 12 }} spacing={2}>

              <Grid item md={2} xs={1}>
                <Avatar
                  src={
                    companyLogo !== "" ? companyLogo : picture
                  }
                  alt="picture"
                  sx={{ height: "50px", width: "50px" }}
                />
              </Grid>
              <Grid item md={8} xs={4} >
                <Box>
                  <Typography variant="h6" noWrap textAlign="left">
                    {positionName}
                  </Typography>
                    <Tooltip title="Applicant List">
                        <GroupsIcon onClick={() => setShowApplicantListModal(true)}/>
                    </Tooltip>
                </Box>
              </Grid>
              <Grid item md={2} xs={1}>
                <JobStatus
                  jobId={jobId}
                  activeState={isActive}
                />
              </Grid>

              <Grid item xs={3} md={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <BusinessCenterIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                  <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap>{experience}</Typography>
                </Box>
              </Grid>

              <Grid item xs={3} md={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <LocalAtmIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                  <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {stipend}</Typography>
                </Box>
              </Grid>

              <Grid item xs={3} md={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <BusinessIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                  <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {employmentType}</Typography>
                </Box>
              </Grid>

              <Grid item xs={3} md={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <LocationOnIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                  <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {location}</Typography>
                </Box>
              </Grid>

              <Grid item xs={3} md={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <ArticleIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                  <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {criteria}</Typography>
                </Box>
              </Grid>

              <Grid item xs={3} md={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <LeaderboardIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                  <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {skills}</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={3}>
                <Typography color="#A9A9A9" sx={{ fontSize: "14px" }}>
                  {difference} Days ago
                </Typography>
              </Grid>
                <Grid item md={6} xs={3}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      sx={{ fontWeight: "800" }}
                      onClick={() => {
                        navigate(`/posted-jobs/job-details/${jobId}`)
                      }}
                    >
                        View Details
                    </Button>
                  </Box>
                </Grid>
                <JobAppliedApplicantsModal
                    open={showApplicantListModal}
                    onClose={() => setShowApplicantListModal(false)}
                    jobId={jobId}
                    />
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    )
}