export function isValidName(name) {
    // Check non-empty string
    if (typeof name !== "string" || name.trim() === "") return false;

    // Check contains numbers
    if (/\d/.test(name)) return false;

    return true;
}

export function isValidPin(pin) {
    // Check if the pin is a number
    if (typeof pin !== "number" && typeof pin !== "string") return false;

    // Convert the pin to a string and check its length
    const pinString = String(pin);
    if (pinString.length !== 6) return false;

    // Check if the pin consists of only numeric digits
    if (!/^\d+$/.test(pinString)) return false;

    return true;
}

export function isValidEmail(email) {
    const tester =
        /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    return tester.test(email);
}

// export function isValidPhoneNumber(number) {
//     const pattern = /^\+?(?=[^a-zA-Z]*$)[0-9\s.\-()/,]{4,25}$/;
//     return pattern.test(number);
// }

export function isValidPhoneNumber(number) {
    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return pattern.test(number);
}

export function isValidSalary(data) {
    // Regular expression to check if the string contains only valid characters
    const isValidFormat = /^[0-9\/\-\p{Sc}]+$/u.test(data);

    return isValidFormat;

    // "50,000"; // Valid: Contains numbers and a comma (currency symbol)
    // "1,000,000"; // Valid: Contains numbers and multiple commas
    // "2000/-"; // Valid: Contains numbers and a slash (currency symbol)
    // "-500"; // Valid: Contains numbers and a minus sign (currency symbol)
    // "¥50000"; // Valid: Contains numbers and a Japanese Yen symbol (currency symbol)
    // "Hello"; // Invalid: Contains non-numeric characters
    // "123abc"; // Invalid: Contains non-numeric characters
    // ""; // Invalid: Empty string
}

export function isValidSkills(data) {
    // Regular expression to check if the string contains valid skills
    // Skills are alphanumeric strings with optional spaces between them
    const isValidFormat = /^(\s*[a-zA-Z0-9]+\s*,\s*)*\s*[a-zA-Z0-9]+\s*$/u.test(
        data
    );

    return isValidFormat;

    // "HTML,CSS,JavaScript"; // Valid: Comma-separated skills with no spaces
    // " React, Vue,  Angular"; // Valid: Skills with spaces after commas
    // "Python , Java,  C++"; // Valid: Skills with spaces before and after commas
    // "JavaScript , React,, Vue"; // Invalid: Two consecutive commas
    // "JavaScript,,React"; // Invalid: Comma without a skill between them
    // " ,Python, Java, C++ "; // Invalid: Comma-separated skill with leading/trailing spaces
}

export function isValidUrl(url) {
    // Regular expression to check if the URL is valid
    const urlPattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/i);

    // Test if the URL matches the pattern and return the result
    return urlPattern.test(url);
}

/**
 * Checks if a value has only numbers.
 *
 * @param {string} str - The value to validate.
 * @returns {boolean} - Returns true if length is same or equal.
 */
export function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
}
/**
 * Checks if a value has only letters.
 *
 * @param {string} str - The value to validate.
 * @returns {boolean} - Returns true if length is same or equal.
 */
export function containsOnlyLetters(str) {
    return /^[A-Za-z\s]+$/.test(str);
}


/**
 * Checks if a string is a valid social media link for LinkedIn or GitHub.
 *
 * @param {string} type - Type of social media (either "linkedin" or "github").
 * @param {string} url - The social media URL to be validated.
 * @returns {boolean} - Returns true if the URL is valid for the specified type, otherwise false.
 */
export function isValidSocialLink(type, url) {
    // Check if the type is valid (either "linkedin" or "github").
    if (type !== "linkedin" && type !== "github") {
        throw new Error(
            "Invalid social media type. Supported types are 'linkedin' and 'github'."
        );
    }

    // Regular expressions for validating LinkedIn and GitHub URLs.
    const linkdinRegex = /^https:\/\/(www\.)?linkedin\.com\/.*$/i;
    const githubRegex = /^https:\/\/(www\.)?github\.com\/.*$/i;

    // Check if the URL matches the expected pattern for the specified social media type.
    if (
        (type === "linkedin" && !linkdinRegex.test(url)) ||
        (type === "github" && !githubRegex.test(url))
    ) {
        return false;
    }

    return true;
}
