import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

/**
 * @typedef {object} Props
 * @property {import("../../models/UserApplicationModel").UserSkillModel[]} skillList
 * @property {boolean} open
 * @property {()=>void} onClose
 */

/**
 * @param {Props} props
 */
export default function SkillsModel({ skillList, open, onClose }) {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    p: 4,
                }}
            >
                {skillList.length > 0 &&
                    skillList.map(data => (
                        <Box
                            key={data.id}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Box margin={"10px 0px"}>
                                <Typography fontSize={"14px"} color={grey[700]} margin={"2px 0px"}>{data.skill}</Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={data.rating * 10}
                                />
                            </Box>
                        </Box>
                    ))}
            </Box>
        </Modal>
    );
}
