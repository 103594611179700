import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Timeline } from "@mui/lab";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import noDataImg from "../../Images/noData.png";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const Education = () => {
    const navigate = useNavigate();

    const [educationData, setEducationData] = useState([]);

    const getEducationData = () => {
        axios
            .get(
                `hri_admin/bulk-resume-detail/${localStorage.getItem(
                    "Un-registered User"
                )}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then(res => {
                console.log("Education Data: ", res.data.education_list);
                setEducationData(res.data.education_list);
            })
            .catch(err => {
                console.log("Error occurred fetching Education Data: ", err);
            });
    };

    useEffect(() => {
        getEducationData();
    }, []);

    return (
        <div className="bg-white rounded-md p-4 m-4">
            {educationData ? (
                <p className="text-2xl text-[#0865B6] font-semibold">
                    Education
                </p>
            ) : (
                <img src={noDataImg} />
            )}

            <div>
                {educationData &&
                    educationData?.map(education => (
                        <Timeline>
                            <TimelineItem
                                key={education.id}
                                className="before:hidden"
                            >
                                <TimelineSeparator>
                                    <TimelineDot
                                        sx={{ background: "#0865B6" }}
                                    />
                                    <TimelineConnector
                                        sx={{ background: "#0865B6" }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div className="pb-4">
                                        <div className="space-y-3">
                                            <p className="text-xl font-semibold">
                                                {education.institute
                                                    ? education.institute
                                                    : "NA"}
                                            </p>
                                            <p>
                                                {education.degree
                                                    ? education.degree
                                                    : "NA"}
                                            </p>
                                            <p>
                                                {education.passing_year
                                                    ? education.passing_year
                                                    : "NA"}
                                            </p>
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    ))}
            </div>
        </div>
    );
};

export default Education;
