import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {  Avatar, Box, Button, Card, CardContent, Grid, IconButton, Pagination, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { grey } from "@mui/material/colors";
import ArticleIcon from "@mui/icons-material/Article";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupsIcon from '@mui/icons-material/Groups';
import picture from "../../../Images/4041088.png";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Spinner from "../../../components/Spinner";
import { handleControllerResultException, handleException } from '../../../utils/dataFetchHelper';
import JobStatus from './JobStatus';
const cookies = new Cookies();
const today = new Date();

const JobList = () => {

    const navigate = useNavigate();

    const [card_details, setCard_details] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages , setTotalPages] = useState(1)

    const GetDetails = ( page = 1) => {
        axios
          .get(`hri_admin/newposition?jobs_type=Active`, {
            headers: {
              Authorization: "Token " + cookies.get("token"),
            },
            params: {
              page,
            }
          })
          .then((res) => {
    
            setCard_details(res.data.results);
            setTotalPages(Math.ceil(res.data.count / 20));
            setLoading(false);
          })
          .catch((err) => {
            const errRes = handleException(err);
            handleControllerResultException(errRes, navigate)
          });
      };
    
    
      useEffect(() => {
        GetDetails();
      }, []);
    

    return (
        <div className="bg-default-background h-screen overflow-auto overflow-y-hidden">
            <Header/>
            <Sidebar/>
            <div className="mt-4 pb-10 px-4 md:px-10">

                <div className="bg-white rounded-md flex mb-4 gap-4">
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIosRoundedIcon/>
                    </IconButton>
                    <p className="text-[#0865B6] mt-3 text-xl font-semibold">
                        Job List
                    </p>
                </div>
                <div style={{ marginTop: "1rem" }}>
      
                <Grid container spacing={2} padding={2}>

                    {card_details.map((item) => {
                    const updatedAt = new Date(item.created_at);

                    const one_day = 1000 * 60 * 60 * 24;

                    const timeDifference = today.getTime() - updatedAt.getTime();
                    const difference = Math.round(timeDifference / one_day);


                    return (
                        <Grid item lg={3} md={4} sm={6} xs={12} key={item.id} >
                <Card
                key={item.id}
                variant="outlined"
                style={{ borderRadius: "0.5rem" }}
                >
                <CardContent>
                    <Grid container columns={{ xs: 6, md: 12 }} spacing={2}>

                    <Grid item md={2} xs={1}>
                        <Avatar
                        src={
                            item.company_logo !== "" ? item.company_logo : picture 
                        }
                        alt="picture"
                        sx={{ height: "50px", width: "50px" }}
                        />
                    </Grid>
                    <Grid item md={8} xs={4} >
                        <Box>
                        <Typography variant="h6" noWrap textAlign="left">
                            {item.position_name}
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={2} xs={1}>
                        <JobStatus
                            jobId={item.id}
                            activeState={item.is_active}
                            landingStatus={item.is_landing_page}
                        />
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                        <BusinessCenterIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                        <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap>{item.experience}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                        <LocalAtmIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                        <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.stipend}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                        <BusinessIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                        <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.employment_type}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                        <LocationOnIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                        <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.location}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                        <ArticleIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                        <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.criteria}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                        <LeaderboardIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                        <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.skills}</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={3}>
                        <Typography color="#A9A9A9" sx={{ fontSize: "14px" }}>
                        {difference} Days ago
                        </Typography>
                    </Grid>
                    </Grid>
                </CardContent>
                </Card>

      </Grid>
                        
                    );
                    })}
                </Grid>
                <Box
                  sx={{ display: "flex", justifyContent: "center",padding:2 }}
                  marginBottom={"2rem"}
                >
                  <Pagination
                    count={totalPages}
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => {
                      GetDetails(page);
                    }}
                  />
                </Box>

            </div>
            </div>   
        </div>
    )
}

export default JobList;