import axios from "axios";
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { blue } from "@mui/material/colors";
import Cookies from "universal-cookie";
import noProfile from "../../Images/empty_states/no-profileshared.png";
import { EmptyState } from "../../components/EmptyStates/EmptyState";
import Spinner from "../../components/Spinner";

const cookies = new Cookies();

const ProfileShared = () => {
  const navigate = useNavigate();
  const [getAllData, setGetAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  async function getData(page = 1) {
    try {
      const resp = await axios.get('/hri_admin/profileshare', {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        },
      });
      setGetAllData(resp.data.results);
      setLoading(false);
      setTotalPages(Math.ceil(resp.data.count / 5));
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (!cookies.get("token")) {
      navigate("/");
    } else {
      getData();
    }
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box>
      <Header />
      <Container maxWidth="lg" sx={{ margin: "5rem auto" }}>
        {getAllData.length > 0 ? (
          <>
            <TableContainer component={Paper} elevation={2}>
              <Table sx={{ width: "100%" }}>
                <TableHead sx={{ backgroundColor: blue[700] }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>Applicants</TableCell>
                    <TableCell sx={{ color: "white" }}>Shared with Companies</TableCell>
                    <TableCell sx={{ color: "white" }}>Shared By</TableCell>
                    <TableCell sx={{ color: "white" }}>Shared at</TableCell>
                    <TableCell sx={{ color: "white" }}>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getAllData.map((user) => {
                    const date = new Date(user.updated_at.slice(0, 10));
                    const month = date.toLocaleString("default", { month: "long" });

                    return (
                      <TableRow key={user.id}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Avatar src={user.user_pic} alt="" sx={{ width: 48, height: 48 }} />
                            <Box ml={2} textAlign="left">
                              <Typography variant="body1">
                                {user.user_fname} {user.user_lname}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                as {user.job_name}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>{user.company_name[0].name}</TableCell>
                        <TableCell>{user.shared_by_name}</TableCell>
                        <TableCell>
                          {date.getDate()} {month.slice(0, 3)} {date.getFullYear()}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => navigate(`/profile-shared/profile-details?id=${user.id}`)}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "1rem", mb: "2rem" }}>
              <Pagination
                count={totalPages}
                color="primary"
                onChange={(e, page) => getData(page)}
              />
            </Box>
          </>
        ) : (
          <EmptyState
            btn="Home"
            img={noProfile}
            title="No Profiles Shared"
            message="There are currently no profiles shared with this account. Start sharing profiles and connecting with others."
            btnLink="/dashboard"
          />
        )}
      </Container>
    </Box>
  );
};

export default ProfileShared;
