import React, {useEffect} from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {useNavigate} from "react-router-dom";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const Help = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    return (
        <div className="bg-default-background h-screen">
            <Header/>
            <Sidebar/>

            <div className="bg-white h-auto px-2 md:px-5 pt-2 pb-10 m-2 rounded-md space-y-3">
                <div className="py-3 px-1 text-[#0865B6] font-semibold text-xl">
                    <p>Help Desk & FAQ’s</p>
                </div>
                <div className="grid items-center justify-center gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

                    <div
                        className="bg-[#0865B6] text-white mx-4 px-3 py-4 rounded-xl text-center
                            hover:opacity-80 active:opacity-90 duration-300 hover:cursor-pointer"
                        onClick={() => {
                            localStorage.setItem("help-type","user");
                            navigate(`/help/${localStorage.getItem("help-type")}/category`);
                        }}
                    >
                        <p className="text-2xl font-semibold">Users FAQ’s</p>
                        <span className="text-xl font-semibold">Create and edit FAQ’s for Users</span>
                    </div>

                    <div
                        className="bg-[#0865B6] text-white mx-4 px-3 py-4 rounded-xl text-center
                            hover:opacity-80 active:opacity-90 duration-300 hover:cursor-pointer"
                        onClick={() => {
                            localStorage.setItem("help-type", "company");
                            navigate(`/help/${localStorage.getItem("help-type")}/category`);
                        }}
                    >
                        <p className="text-2xl font-semibold">Company FAQ’s</p>
                        <span className="text-xl font-semibold">Create and edit FAQ’s for Companies</span>
                    </div>

                    <div
                        className="bg-[#0865B6] text-white mx-4 px-3 py-4 rounded-xl text-center
                            hover:opacity-80 active:opacity-90 duration-300 hover:cursor-pointer"
                        onClick={() => {
                            localStorage.setItem("help-type", "team");
                            navigate(`/help/${localStorage.getItem("help-type")}/category`);
                        }}
                    >
                        <p className="text-2xl font-semibold">Teams FAQ’s</p>
                        <span className="text-xl font-semibold">Create and edit FAQ’s for Teams</span>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Help;