import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from "universal-cookie";
import { Select } from 'antd';
import { Button, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const cookies = new Cookies();

const NewVerify = () => {
    const navigate = useNavigate()

    const [list, setList] = useState([])

    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [reportType, setReportType] = useState("ID VERIFICATION");

    console.log("emailll", email)

    function Verify() {
        axios.post('hri_admin/verification-create', {
            user: id,
            report_type: reportType
        }, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        })
            .then((response) => {
                navigate(-1)
                // setItems(response.data)
            }).catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        axios.get('hri_admin/username', {
            headers: {
                "Authorization": "Token " + cookies.get("token"),
            },
        })
            .then((response) => {
                console.log(response)
                setList(response.data)
            });

    }, []);

    return (
        <div className="bg-default-background w-full">
            <Header />
            <Sidebar />
            <div className="">
                <div className="mt-2 mx-2 bg-white">
                    <div onClick={() => navigate(-1)} className="text-blue-600 text-xl cursor-pointer lg:ml-8"><NavigateBeforeIcon />Back</div>
                    <div className="text-base font-semibold lg:mt-[8rem] lg:ml-[15rem] mt-[4rem] ml-[5rem]">Date: 12/12/2022</div>
                    <div className='grid place-items-center gap-4'>
                        <div className="flex flex-col">
                            <div className="text-lg font-semibold text-gray-600 my-10">Select Person</div>
                            {/* <Select
                                value={id}
                                onChange={setId}
                                showSearch
                                placeholder="Search Email"
                                className="w-[30rem]"
                            >
                                {list.map((item) => (
                                    <MenuItem key={item.id} value={item.user_id}>{item.email}</MenuItem>
                                )
                                )}
                            </Select> */}

                            <Autocomplete
                                disablePortal
                                // value={email}
                                onChange={(e, newValue) => { setId(newValue.user_id) }}
                                id="combo-box-demo"
                                getOptionLabel={(option) => option.email}
                                options={list}
                                renderOption={(props, option) => <li {...props}>{option.email}</li>}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Search By Email"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }} />}
                            />


                        </div>
                        <div>
                            <Select
                                size='large'
                                className='w-72'
                                value={reportType}
                                onChange={setReportType}
                            >
                                <MenuItem value="ID VERIFICATION">ID VERIFICATION</MenuItem>
                                <MenuItem value="PREVIOUS COMPANY">PREV COMPANY VERIFICATION</MenuItem>
                            </Select>
                        </div>
                        <Button variant='contained' onClick={() => Verify()} className="rounded-lg border-2 border-[#0865B6] hover:bg-[#055eac] font-bold bg-[#0865B6] lg:w-[15rem] h-[2.5rem] text-white text-lg md:w-[10rem] w-[7rem]">
                            VERIFY
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default NewVerify
