import { Avatar, Box, Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getMatchingApplicantList } from "../../../controller/UserApplicationController";
import { Link, useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../../utils/handleException";
import { blue } from "@mui/material/colors";
import { toast } from "react-toastify";


/**
 * @typedef {object} Props
 * @property {boolean} open
 * @property {()=>void} onClose
 * @property {string| undefined} jobId
 */

/**
 * @param {Props} props
 */

export default function MatchingProfileModal({ open, onClose, jobId }) {
    const [applicantList, setApplicantList] = useState(applicantListType);
    const navigate = useNavigate();

    const handleFetchMatchingList = useCallback(async (jobId) => {
        const res = await getMatchingApplicantList(jobId)

        if (res[0] !== null) {
            setApplicantList(res[0])
        } else {
            handleControllerResultException(res[1], navigate)
        }
    }, [navigate])

    useEffect(() => {
        if (jobId) {
            handleFetchMatchingList(jobId)
        }
    }, [jobId, handleFetchMatchingList])

    function handleResumeLink (resumeLink) {
        if (resumeLink === null || resumeLink === ""){
            toast.warning("No Resume Attached")
        }
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "50%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    maxHeight: "500px",
                    overflowY: "auto"
                }}
            >
                <TableContainer component={Paper} elevation={2}>
                    <Table sx={{ minWidth: "400px" }}>
                        <TableHead sx={{ backgroundColor: blue[700] }}>
                            <TableRow>
                                <TableCell sx={{ color: "white" }}>
                                    Profile
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Resume
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Details
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applicantList.map((data) => <TableRow key={data.id}>
                                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                                    <Avatar src={data.profilePic} alt={data.firstName} />
                                    <Box sx={{ marginLeft: "10px" }}>
                                        <Typography>{data.firstName} {data.lastName}</Typography>
                                        <Typography>{data.role}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {data.resume.resumeFileLink !== null || data.resume.resumeFileLink !== ""?
                                        <a href={data.resume.resumeFileLink} target="_blank">
                                        <Button variant="contained">
                                            Resume
                                        </Button>
                                    </a> 
                                    : 
                                    <Button variant="contained"
                                    onClick={handleResumeLink(data.resume.resumeFileLink)}
                                    >
                                            Resume
                                        </Button>
                                     }
                                    
                                </TableCell>
                                <TableCell>
                                    <Link to={`/applications-details/${data.userId}`}>
                                        <Button variant="contained">
                                            Details
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
}



/**
 * @type {import("../../../models/UserApplicationModel").UserApplicationModel[]}
 */
const applicantListType = []