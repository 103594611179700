import { Box, Typography } from "@mui/material";
import React from "react";

/**
 * @typedef {object} Props
 * @property {string} heading
 * @property {string} text
 * @property {import("@mui/material").SxProps<import("@mui/material").Theme | undefined>} headingStyles
 * @property {import("@mui/material").SxProps<import("@mui/material").Theme | undefined>} textStyles
 * @property {import("@mui/material").SxProps<import("@mui/material").Theme | undefined>} sx
 */

/**
 * @param {Props} props
 */
export default function LabeledText({
    heading,
    text,
    headingStyles,
    textStyles,
    sx,
}) {
    return (
        <Box sx={sx}>
            <Typography sx={headingStyles} fontSize={"16px"}>
                {heading}
            </Typography>
            <Typography sx={textStyles} fontSize={"18px"} fontWeight={"bold"}>
                {text}
            </Typography>
        </Box>
    );
}
