import MenuBtnContainer from "./MenuBtnContainer";
import { BsCodeSlash } from "react-icons/bs";
import { FaQuoteRight, FaRedoAlt, FaUndoAlt } from "react-icons/fa";
import { MdHorizontalRule } from "react-icons/md";
import FontStyles from "./FontStyles";
import Heading from "./Heading";
import List from "./List";
/**
 * @typedef {object} Props
 * @property {import("@tiptap/react").Editor} editor
 */

/**
 *  @param {Props} props
 *  @returns {JSX.Element}  Renders editor toolbar
 */

export default function MenuBar({ editor }) {
    return (
        <div className=" flex flex-wrap items-center">
            <FontStyles editor={editor} />
            <Heading editor={editor} />
            <List editor={editor} />

            <MenuBtnContainer
                buttonActionFunc={() =>
                    editor.chain().focus().toggleCodeBlock().run()
                }
                toolTipText="Code"
            >
                <BsCodeSlash size="1.2em" />
            </MenuBtnContainer>
            <MenuBtnContainer
                buttonActionFunc={() =>
                    editor.chain().focus().toggleBlockquote().run()
                }
                toolTipText="Quote"
            >
                <FaQuoteRight size="1.1em" />
            </MenuBtnContainer>

            <MenuBtnContainer
                buttonActionFunc={() =>
                    editor.chain().focus().setHorizontalRule().run()
                }
                toolTipText="Horizontal line"
            >
                <MdHorizontalRule size="1.2em" />
            </MenuBtnContainer>

            <MenuBtnContainer
                buttonActionFunc={() => editor.chain().focus().undo().run()}
                toolTipText="Undo"
            >
                <FaUndoAlt size="1em" />
            </MenuBtnContainer>

            <MenuBtnContainer
                buttonActionFunc={() => editor.chain().focus().redo().run()}
                toolTipText="Redo"
            >
                <FaRedoAlt size="1em" />
            </MenuBtnContainer>
        </div>
    );
}
