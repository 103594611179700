import React, { useEffect, useState } from "react";
import { selectHead } from "../../features/HeadSlice";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import Header from "../../components/Header";
import {
    Autocomplete,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { Button as Btn } from "@material-tailwind/react";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import csc from "country-state-city";
import { useFormik } from "formik";
import SelectR from "react-select";
import { Checkbox } from "antd";
import { toast } from "react-toastify";
import Editor from "../../components/richEditor/Editor";
import { isValidJob } from "../../helpers/validate_job";
import useCreateEditor from "../../hooks/useCreateEditor";

const cookies = new Cookies();
const AddNewPosition = () => {
    const navigate = useNavigate();
    const currentState = useSelector(selectHead);
    const [positionName, setPositionName] = useState("");
    const [experience, setExperience] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [location, setLocation] = useState("");
    const [stipend, setStipend] = useState("");
    const [education, setEducation] = useState("");
    const [fulctionalArea, setFunctionalArea] = useState("");
    const [jobDesc, setJobDesc] = useState("");
    const [skills, setSkills] = useState("");
    const [roleCat, setRoleCat] = useState([]);
    const [roleSubCat, setRoleSubCat] = useState([]);
    const [roleCatId, setRoleCatId] = useState();
    const [roleSubCatId, setRoleSubCatId] = useState();
    const [company, setCompany] = useState([]);
    const [companyId, setCompanyId] = useState("");
    const [checked, setCheacked] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [addAnotherQuestion, setAddAnotherQuestion] = useState("");
    const [addAnotherArr, setAddAnotherArr] = useState([]);
    const [country, setCountry] = useState("");
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [questionCount, setQuestionCount] = useState(1);
    const [ed_lis, setEd_list] = useState(["BE", "Btech", "commerce"]);

    const editor = useCreateEditor(jobDesc);

    const addressFromik = useFormik({
        initialValues: {
            country: "India",
            state: null,
            city: null,
        },
        //onSubmit: (values) =>
        //console.log("valuesaa", JSON.stringify(values).city.name),
    });

    const addQuestion = e => {
        e.preventDefault();
        setQuestionCount(questionCount + 1);
        setAddAnotherQuestion(addAnotherQuestion);
        addAnotherArr.push(addAnotherQuestion);
    };
    const roleCatChange = e => {
        setRoleCatId(e.target.value);
    };
    const roleSubCatChange = e => {
        setRoleSubCatId(e.target.value);
    };
    const [data, setData] = useState({});
    const handleChange = e => {
        if (e.target.name.startsWith("option")) {
            setData({ ...data, [e.target.name]: e.target.value });
        } else setData({ ...data, [e.target.name]: e.target.value });
    };
    const countries = csc.getAllCountries();
    const updatedCountries = countries.map(country => ({
        label: country.name,
        value: country.id,
        ...country,
    }));

    const updatedStates = countryId =>
        csc
            .getStatesOfCountry(countryId)
            .map(state => ({ label: state.name, value: state.id, ...state }));
    const updatedCities = stateId =>
        csc
            .getCitiesOfState(stateId)
            .map(city => ({ label: city.name, value: city.id, ...city }));

    const { values, handleSubmit, setFieldValue, setValues } = addressFromik;

    const handleLocation = () => {
        setLocation(values.city.name);
    };

    // useEffect(() => {}, [values]);

    function createJob(e) {
        e.preventDefault();
        let question_data = [];
        for (let i = 0; i < questionCount; i++) {
            let obj = {};
            obj["question"] = data[`question${i}`];
            obj["question_type"] = data[`questionType${i}`];
            obj["description"] = data[`questionDes${i}`];
            if (data[`questionType${i}`] === "ChoiceField")
                obj["options"] =
                    data[`optionOne${i}`] +
                    "," +
                    data[`optionTwo${i}`] +
                    "," +
                    data[`optionThree${i}`] +
                    "," +
                    data[`optionThree${i}`];
            question_data.push(obj);
        }
        console.log("data", question_data);
        let formData = new FormData();
        formData.append("name", title);
        formData.append("description", description);
        // formData.append("creator", cookies.get("id"));
        formData.append("question_data", question_data);
        console.log("roleSubCatId", roleSubCatId);

        const assessment = {
            name: title,
            description: description,
            questions: question_data,
        };

        const submitData = {
            position_name: positionName,
            experience: experience,
            employment_type: employmentType,
            location: values.city,
            stipend: stipend,
            criteria: education,
            role_sub_category: roleSubCatId,
            functional_area: fulctionalArea,
            job_description: editor.getHTML(),
            skills: skills,
            company_name: companyId.name,
            company_logo: companyId.company_logo,
            // company_details:"null",
            company: companyId.id,
            assessment: assessment,

            is_assessment: checked,
        };

        const error = isValidJob(submitData);
        if (error) return toast.error(error);

        axios
            .post("hri_admin/newposition/add", submitData, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(resp => {
                toast.info("New job post is created", {
                    position: "top-center",
                });
                navigate("/posted-jobs");
            })
            .catch(err => {
                toast.info(err.message);
            });
    }

    const onChange = e => {
        setCheacked(e.target.checked);
    };
    useEffect(() => {
        axios
            .get("hri_admin/education-list")
            .then(dat => setEd_list(dat.data.education_list));

        if (cookies.get("token")) {
            axios
                .get("hri_admin/roles", {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
                .then(resp => {
                    setRoleCat(resp.data);
                })
                .catch(err => {
                    toast.info(err.message);
                });
            axios
                .get(`hri_admin/sub_roles/${roleCatId}`, {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
                .then(resp => {
                    // console.log(resp.data);
                    setRoleSubCat(resp.data);
                })
                .catch(err => {
                    console.log(err);
                });
            axios
                .get(`hri_admin/company`, {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
                .then(resp => {
                    setCompany(resp.data);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            navigate("/");
        }
    }, [roleCatId]);
    const customStyles = {
        control: base => ({
            ...base,
            height: 55,
            minHeight: 35,
        }),
    };

    const companyChange = e => {
        setCompanyId(e.target.value);
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            },
        },
    };

    return (
        <>
            <div className="bg-default-background pb-10 w-full overflow-auto">
                <Header />
                <div className="md:px-4 px-0 py-7">
                    <div className="bg-white px-4 md:px-28 pb-20 w-full rounded-lg h-auto">
                        <div className="flex gap-4 py-4">
                            <div className="flex-grow">
                                <p className="text-2xl text-[#0865B6] font-bold">
                                    Add New Position
                                </p>
                            </div>
                            <Btn
                                variant="contained"
                                className={`md:my-[2] md:px-[8] bg-[#F1F1F1] text-[#0865B6] my-0 px-[2] font-bold rounded-md shadow`}
                                onClick={() => navigate("/posted-jobs")}
                            >
                                Cancel
                            </Btn>
                            <Btn
                                className={`md:my-[2] md:px-[8] bg-[#F1F1F1] text-[#0865B6] my-0 px-[2] font-bold rounded-md shadow`}
                                variant="gradient"
                                onClick={createJob}
                            >
                                <DoneAllRoundedIcon />
                                Save
                            </Btn>
                        </div>
                        <div className="grid  md:grid-cols-2 md:mx-16 gap-y-10 md:gap-x-10 mt-8">
                            <div>
                                <TextField
                                    className="w-full"
                                    label="Position Name"
                                    id="outlined-size-small"
                                    onChange={e =>
                                        setPositionName(e.target.value)
                                    }
                                />
                            </div>

                            <div>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Experience
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={experience}
                                        label="Experience"
                                        onChange={e =>
                                            setExperience(e.target.value)
                                        }
                                    >
                                        <MenuItem value={"Fresher"}>
                                            Fresher
                                        </MenuItem>
                                        <MenuItem value={"0-1 years"}>
                                            0-1 years
                                        </MenuItem>
                                        <MenuItem value={"1-2 years"}>
                                            1-2 years
                                        </MenuItem>

                                        <MenuItem value={"2-3 years"}>
                                            2-3 years
                                        </MenuItem>
                                        <MenuItem value={"3-4 years"}>
                                            3-4 years
                                        </MenuItem>
                                        <MenuItem value={"4-5 years"}>
                                            4-5 years
                                        </MenuItem>
                                        <MenuItem value={"5+ years"}>
                                            5+ years
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Employment Type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={employmentType}
                                        label="Employment Type"
                                        onChange={e =>
                                            setEmploymentType(e.target.value)
                                        }
                                    >
                                        <MenuItem value={"Part-time"}>
                                            Part-time
                                        </MenuItem>
                                        <MenuItem
                                            value={"Part-time(Work from home)"}
                                        >
                                            Part-time(Work from home)
                                        </MenuItem>
                                        <MenuItem value={"Full-time"}>
                                            Full-time
                                        </MenuItem>
                                        <MenuItem
                                            value={"Full-time(Work from home)"}
                                        >
                                            Full-time(Work from home)
                                        </MenuItem>
                                        <MenuItem
                                            value={"Contractual(Part-Time)"}
                                        >
                                            Contractual(Part-Time)
                                        </MenuItem>
                                        <MenuItem
                                            value={"Contractual(Full-Time)"}
                                        >
                                            Contractual(Full-Time)
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {/*<div>*/}
                            {/*    <SelectR*/}
                            {/*        className="z-40"*/}
                            {/*        styles={customStyles}*/}
                            {/*        id="country"*/}
                            {/*        name="country"*/}
                            {/*        label="country"*/}
                            {/*        options={updatedCountries}*/}
                            {/*        value={values.country}*/}
                            {/*        placeholder="Select Country"*/}
                            {/*        onChange={(value) => {*/}
                            {/*            setValues(*/}
                            {/*                {country: value, state: null, city: null},*/}
                            {/*                false*/}
                            {/*            );*/}
                            {/*            console.log("country inside", values.country)*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            <div>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Country
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={country}
                                        label="Country"
                                        MenuProps={MenuProps}
                                        onChange={e => {
                                            setValues({
                                                country: e.target.value,
                                                state: null,
                                                city: null,
                                            });
                                            setCountry(e.target.value);
                                            e.target.value &&
                                                setStateList(
                                                    updatedStates(
                                                        e.target.value
                                                    )
                                                );
                                        }}
                                    >
                                        {updatedCountries.map(country => (
                                            <MenuItem
                                                key={country.id}
                                                value={country.id}
                                            >
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <div>
                <SelectR
                  className="z-20"
                  styles={customStyles}
                  id="state"
                  name="state"
                  options={updatedStates(
                    values.country ? values.country : null
                  )}
                  value={values.state}
                  placeholder="Select State"
                  onChange={(value) => {
                    setValues({ state: value, city: null }, false);
                  }}
                />
              </div> */}
                            <FormControl fullWidth>
                                <InputLabel id="state">State</InputLabel>
                                <Select
                                    labelId="state"
                                    id="state"
                                    value={values.state}
                                    label="state"
                                    onChange={e => {
                                        setValues(
                                            {
                                                state: e.target.value,
                                                city: null,
                                            },
                                            false
                                        );
                                        e.target.value &&
                                            setCityList(
                                                updatedCities(e.target.value)
                                            );
                                    }}
                                >
                                    {stateList.length > 0 &&
                                        stateList.map(data => (
                                            <MenuItem
                                                value={data.id}
                                                key={data.id}
                                            >
                                                {data.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {/* <div>
                <SelectR
                  className="z-20"
                  styles={customStyles}
                  id="city"
                  name="city"
                  placeholder="Select city"
                  options={updatedCities(
                    values.state ? values.state.value : null
                  )}
                  value={values.city}
                  onChange={(value) => setFieldValue("city", value)}
                />
              </div> */}
                            <FormControl fullWidth>
                                <InputLabel id="city">City</InputLabel>
                                <Select
                                    labelId="city"
                                    id="city"
                                    value={values.city}
                                    label="city"
                                    onChange={e =>
                                        setFieldValue("city", e.target.value)
                                    }
                                >
                                    {cityList.length > 0 &&
                                        cityList.map((data, index) => (
                                            <MenuItem
                                                value={data.name}
                                                key={data.id}
                                            >
                                                {data.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <div className="z-10">
                                <TextField
                                    className="w-full"
                                    type="text"
                                    label="Stipend / Salary"
                                    id="outlined-size-small"
                                    onChange={e => setStipend(e.target.value)}
                                />
                            </div>

                            <div>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={(_, val) => setEducation(val)}
                                    // value={company.length && company.find(dat=>dat.id === companyId) || null}
                                    options={ed_lis}
                                    renderOption={(props, o) => (
                                        <li {...props}>{o}</li>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Education"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="z-10">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Role Category
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={roleCatId}
                                        label="Role Category"
                                        onChange={roleCatChange}
                                    >
                                        {roleCat.map(roleCat => (
                                            <MenuItem
                                                key={roleCat.id}
                                                value={roleCat.id}
                                            >
                                                {roleCat.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="z-10">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Role Sub-Category
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={roleSubCatId}
                                        label="Role Sub-Category"
                                        onChange={roleSubCatChange}
                                    >
                                        {roleSubCat.map(roleSubCat => (
                                            <MenuItem
                                                key={roleSubCat.id}
                                                value={roleSubCat.id}
                                            >
                                                {roleSubCat.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={(_, val) => setCompanyId(val)}
                                    // value={company.length && company.find(dat=>dat.id === companyId) || null}
                                    options={company}
                                    getOptionLabel={option => option.name}
                                    renderOption={(props, o) => (
                                        <li {...props}>{o.name}</li>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Company List"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            <div>
                                <TextField
                                    className="w-full"
                                    label="Functional Area"
                                    id="outlined-size-small"
                                    onChange={e =>
                                        setFunctionalArea(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="mt-8 md:mx-16">
                            <TextField
                                multiline
                                minRows={5}
                                label="Skills (ReactJS, NodeJS, Typescript)"
                                className="w-full"
                                InputProps={{
                                    disableUnderline: true,
                                    style: { whiteSpace: "pre-wrap" },
                                }}
                                onChange={e => setSkills(e.target.value)}
                            />
                        </div>
                        <div className="mt-8 md:mx-16">
                            {/* <TextField
                multiline
                label="Job Description"
                minRows={5}
                className="w-full"
                onChange={(e) => setJobDesc(e.target.value)}
              /> */}
                            <Editor editor={editor} />
                        </div>
                        <div className="pr-20">
                            <div className="flex mt-10  px-16 justify-between">
                                <div className="flex ">
                                    <div>
                                        <Checkbox
                                            className="font-semibold"
                                            onChange={onChange}
                                        >
                                            Add Questionnaire
                                        </Checkbox>
                                    </div>
                                    {/* <div>
                    <Switch defaultChecked onChange={onChangeSwitch} />
                  </div> */}
                                </div>
                                {/* <div>
                  <Switch defaultChecked onChange={onChangeSwitch} />
                </div> */}
                            </div>
                        </div>
                        {checked ? (
                            <div className=" md:px-16">
                                <div className="  ">
                                    <div className="text-gray-400   text-sm md:text-base mb-2 font-semibold ml-1">
                                        Title
                                    </div>
                                    <TextField
                                        className="w-full"
                                        label="Enter Title"
                                        required
                                        id="outlined-size-small"
                                        onChange={e => setTitle(e.target.value)}
                                        size="small"
                                    />
                                </div>
                                <div className="mt-4 ">
                                    <div className="text-gray-400  text-sm md:text-base mb-2 font-semibold">
                                        Description
                                    </div>

                                    <TextField
                                        multiline
                                        minRows={5}
                                        label="Skills"
                                        className="w-full"
                                        onChange={e =>
                                            setDescription(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="row">
                                    {Array.from(
                                        { length: questionCount },
                                        (_, i) => (
                                            <>
                                                <div className="mt-4">
                                                    <div className="row">
                                                        <div className=" section_margin_top">
                                                            <hr className="my-4 mt-8" />
                                                            <div className="col-xl-12  noPadding">
                                                                <p className="bp-form-text-header mb-1">
                                                                    Question{" "}
                                                                    <span
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </p>
                                                                <input
                                                                    className="w-full border h-10 rounded-md p-4"
                                                                    type="text"
                                                                    name={`question${i}`}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    placeholder="Type your first question here..."
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <p className="bp-form-text-header mb-1">
                                                                    Description
                                                                </p>
                                                                <textarea
                                                                    className="h-20 2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                                    name={`questionDes${i}`}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    type="text"
                                                                    placeholder="Question Description"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mt-4">
                                                            <div>
                                                                <div className="col-xl-12 ">
                                                                    <p className="bp-form-text-header mb-1">
                                                                        Answer
                                                                        Mode{" "}
                                                                        <span
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </p>
                                                                    <select
                                                                        className="form-select   ml-1  appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                        name={`questionType${i}`}
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        // onChange={(e) => setQuestionType(e.target.value)}
                                                                    >
                                                                        <option
                                                                            className="h-20"
                                                                            value=""
                                                                        >
                                                                            Select
                                                                        </option>
                                                                        <option
                                                                            className="h-20"
                                                                            value="TextField"
                                                                        >
                                                                            TextField
                                                                        </option>
                                                                        <option
                                                                            className="h-20"
                                                                            value="ChoiceField"
                                                                        >
                                                                            ChoiceField
                                                                        </option>
                                                                        <option
                                                                            className="h-20"
                                                                            value="FileField"
                                                                        >
                                                                            File
                                                                        </option>
                                                                        <option
                                                                            className="h-20"
                                                                            value="BoolField"
                                                                        >
                                                                            Boolean
                                                                        </option>
                                                                        <option
                                                                            className="h-20"
                                                                            value="VideoField"
                                                                        >
                                                                            Video
                                                                        </option>
                                                                        <option
                                                                            className="h-20"
                                                                            value="ImageField"
                                                                        >
                                                                            Image
                                                                        </option>
                                                                    </select>
                                                                </div>

                                                                {data[
                                                                    `questionType${i}`
                                                                ] ===
                                                                "ChoiceField" ? (
                                                                    <>
                                                                        <div className="col-xl-12 section_margin_top">
                                                                            <p className="mt-3">
                                                                                First
                                                                                Option{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            </p>
                                                                            <input
                                                                                className="w-full border h-10 rounded-md p-4"
                                                                                type="text"
                                                                                onChange={
                                                                                    handleChange
                                                                                }
                                                                                name={`optionOne${i}`}
                                                                                placeholder="Type your first question here..."
                                                                            ></input>
                                                                        </div>
                                                                        <div className="col-xl-12 section_margin_top">
                                                                            <p className="mt-3">
                                                                                Second
                                                                                Option{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            </p>
                                                                            <input
                                                                                className="w-full border h-10 rounded-md p-4"
                                                                                name={`optionTwo${i}`}
                                                                                onChange={
                                                                                    handleChange
                                                                                }
                                                                                type="text"
                                                                                placeholder="Type your first question here..."
                                                                            ></input>
                                                                        </div>
                                                                        <div className="col-xl-12 section_margin_top">
                                                                            <p className="mt-3">
                                                                                Third
                                                                                Option
                                                                            </p>
                                                                            <input
                                                                                className="w-full border h-10 rounded-md p-4"
                                                                                name={`optionThree${i}`}
                                                                                onChange={
                                                                                    handleChange
                                                                                }
                                                                                type="text"
                                                                                placeholder="Type your first question here..."
                                                                            ></input>
                                                                        </div>
                                                                        <div className="col-xl-12 section_margin_top">
                                                                            <p className="mt-3">
                                                                                Forth
                                                                                Option
                                                                            </p>
                                                                            <input
                                                                                className="w-full border h-10 rounded-md p-4"
                                                                                name={`optionThree${i}`}
                                                                                onChange={
                                                                                    handleChange
                                                                                }
                                                                                type="text"
                                                                                placeholder="Type your first question here..."
                                                                            ></input>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    )}
                                    <div className=" mt-5">
                                        <div className="">
                                            <button
                                                className="bg-gray-500 text-white p-3 rounded-md"
                                                onClick={addQuestion}
                                            >
                                                Add Another Question +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNewPosition;
