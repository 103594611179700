import { Checkbox } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import Cookies from "universal-cookie";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toast } from "react-toastify";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export function Bookmark({ userId, checked }) {
    const cookies = new Cookies();
    const [checkdState, setChecked] = useState(checked);

    async function handleBookmarkClick() {
        setChecked(p => !p);
        try {
            const res = await axios.get(`/hri_admin/user-bookmark/${userId}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            });

            if (res.status !== 200) return toast.error("Something went wrong");
            if (res.status === 200) return toast.success("Status Changed Successfully");
        } catch (error) {
            throw new Error("Can't add into bookmark");
        }
    }

    return (
        <Checkbox
            {...label}
            onClick={handleBookmarkClick}
            checked={checkdState}
            icon={<BookmarkBorderIcon />}
            checkedIcon={<BookmarkIcon />}
        />
    );
}
