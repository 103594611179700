import { EditorContent } from "@tiptap/react";

import React from "react";
import MenuBar from "./MenuBar";

/**
 * @typedef {object} Props
 * @property {import("@tiptap/react").Editor | null} editor
 *
 */

/**
 * @param {Props} props
 * @returns {React.ReactNode} Render Rich text editor
 * Attributes -
 * -  setState: This state stores the user input
 */

export default function Editor({ editor }) {
    return (
        <div >
            {editor && <MenuBar editor={editor} />}
            <EditorContent editor={editor} />
        </div>
    );
}
