import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import noDataImg from "../../Images/noData.png";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const Skills = () => {
    const navigate = useNavigate();

    const [skillData, setSkillData] = useState([]);

    const getSkillData = () => {
        axios
            .get(
                `hri_admin/bulk-resume-detail/${localStorage.getItem(
                    "Un-registered User"
                )}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then(res => {
                console.log("Skill Data: ", res.data.skills_list);
                setSkillData(res.data.skills_list);
            })
            .then(err => {
                console.log("Error occurred fetching Skill data", err);
            });
    };

    useEffect(() => {
        getSkillData();
    }, []);

    return (
        <div className="bg-white rounded-md p-4 m-4">
            {skillData ? (
                <p className="text-2xl text-[#0865B6] font-semibold">Skills</p>
            ) : (
                <img src={noDataImg} />
            )}

            <div className="text-base flex flex-wrap gap-2">
                {skillData &&
                    skillData?.map(skill => (
                        <p
                            key={skill}
                            className="bg-[#f1f1f1] px-4 py-2 rounded-full"
                        >
                            {skill ? skill : "NA"}
                        </p>
                    ))}
            </div>
        </div>
    );
};

export default Skills;
