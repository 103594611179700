/**
 * This class create HttpStatus instance
 * @class
 */

export class HttpResponse {
    /**
     * @type {"httpResponse"}
     */
    type = "httpStatus";
    /**
     * @type {number}
     */
    statusCode;
    /**
     * @type {string|null}
     */
    success;
    /**
     * @type {string|null}
     */
    error;
  
    /**
     *
     * @param {number} statusCode
     * @param {string|null} success
     * @param {string|null} error
     * @returns {HttpStatusModel} instance of HttpStatusModel
     */
    constructor(statusCode, success = null, error = null) {
      this.statusCode = statusCode;
      this.success = success;
      this.error = error;
    }
  }
  