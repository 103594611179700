import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const BasicDetails = () => {

    const navigate = useNavigate();

    const [details, setDetails] = useState([]);
    const [skills, setSKills] = useState([]);

    const basicDetails = () => {
        axios
            .get(
                `hri_admin/bulk-resume-detail/${localStorage.getItem("Un-registered User")}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                console.log("Basic Details: ", res.data);
                setDetails(res.data);
                setSKills(res.data.skill_list)
            })
            .catch((err) => {
                console.log("Error Occurred while fetching basic details", err);
            })
    }

    useEffect(() => {
        basicDetails();
    }, [])

    return (
        <div className="bg-white text-lg rounded-md p-4 m-4 ">
            <div className="space-y-4">
                <p className="text-2xl text-[#0865B6] font-semibold">
                    Basic Details
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 ">
                    <div className="text-gray-400">
                        Name
                        <div className="text-black">
                            {details.name ? details.name : "NA"}
                        </div>
                    </div>

                    <div className="text-gray-400">
                        Email
                        <div className="text-black">
                            {details.email ? details.email : "NA"}
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 ">
                    <div className="text-gray-400">
                        Date of Birth
                        <div className="text-black">
                            {details.date_of_birth ? details.date_of_birth : "NA"}
                        </div>
                    </div>

                    <div className="text-gray-400">
                        Contact
                        <div className="text-black">
                            {details.contact_no ? details.contact_no : "NA"}
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 ">
                    <div className="text-gray-400">
                        Address
                        <div className="text-black">
                            {details.address ? details.address : "NA"}
                        </div>
                    </div>

                    <div className="text-gray-400">
                        Languages
                        <div className="text-base flex flex-wrap gap-2">
                            {details.language_list &&
                                details.language_list?.map(language => (
                                    <p
                                        key={language}
                                        className="bg-[#f1f1f1] px-4 py-2 rounded-full text-black"
                                    >
                                        {language ? language: "NA"}
                                    </p>
                                ))}
                        </div>
                    </div>
                </div>

                <div  className="text-gray-400">
                    Total Years of Experience
                    <div className="text-black">
                        {details.total_years_experience ? details.total_years_experience : "NA"}
                    </div>
                </div>

            </div>

        </div>
    )
}

export default BasicDetails;