import React, { useEffect, useState } from 'react'

import Cookies from "universal-cookie";
import { MdArrowBackIos } from "react-icons/md";
import axios from 'axios';
import { BiChevronDown } from 'react-icons/bi'
import { Route, Routes, useNavigate } from "react-router-dom";
import { MdDelete } from 'react-icons/md'
import Box from '@mui/material/Box';
import { Modal, } from 'antd';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { EmptyState } from '../../components/EmptyStates/EmptyState';
import noProfile from "../../Images/empty_states/no-payment.png"

const cookies = new Cookies();

const BIll = () => {
    const navigate = useNavigate();
    
    const [billDetails, setBillDetails] = useState("")
    useEffect(()=>{
    setBillDetails(window.location.pathname.split("/")[2])
    })

    useEffect(() => {
        if (cookies.get("token")) {
            return
        } else {
            navigate("/");
        }
    }, []);

    return (
        <>
            <div className="bg-white whitespace-nowrap mt-2 space-x-1 flex items-center lg:space-x-4 text-sm md:text-lg xl:text-xl  font-semibold p-[.2rem] md:pl-8 text-gray-600  md:p-1">
                <div onClick={() => {navigate("/financials/your-bills");}} className={billDetails === "your-bills" ? ' rounded-lg md:px-8 py-1 bg-slate-100 text-blue-700 cursor-pointer px-4' : 'cursor-pointer px-4 py-1 rounded-lg md:px-8  hover:bg-slate-100 hover:text-blue-700'}>Your Bills</div>

                <div onClick={() => {navigate("/financials/team-bills")}} className={billDetails === "team-bills" ? 'rounded-lg md:px-8 py-1 bg-slate-100 text-blue-700 cursor-pointer px-4' : 'cursor-pointer px-4 py-1 rounded-lg md:px-8  hover:bg-slate-100 hover:text-blue-700'}>Bills By Team</div>

                <div onClick={() => {navigate("/financials/requested-bills");}} className={billDetails === "requested-bills" ? ' rounded-lg md:px-8 py-1 bg-slate-100 text-blue-700 cursor-pointer px-4' : 'cursor-pointer px-4 py-1 rounded-lg md:px-8  hover:bg-slate-100 hover:text-blue-700'}>Bill Requests</div>
            </div>
            <div className='overflow-auto mx-3 sm:mx-4  mt-4 mb-20'>
                <Routes>
                    <Route path='/team-bills' element={<TeamBill/>} />
                    <Route path='/requested-bills' element={<RequestBill/>} />
                    <Route path='/*' element={<YourBill/>} />
                </Routes>



            </div>

        </>
    )
}

export default BIll



export function YourBill() {

    const [data, setData] = useState([]);
    const [loading , setLoading] = useState(true)
    const navigate = useNavigate();

    const { confirm } = Modal;

    async function getData() {
        await axios
            .get('hri_admin/bill/admin', {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });

    }

    var id

    const showConfirm = () => {
        confirm({
            title: 'Are you sure you want to change the bill to paid?',
            //   icon: <ExclamationCircleFilled />,
            content: 'You will be redirected to a new page to fill the payout/transaction details of the bill',
            onOk() {
                console.log('OK', id);
                navigate("/Financials/" + id + "/payout")
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    useEffect(()=>{
        getData();
    },[])

    return (
        <div>
           {data.length ? (
                    data.map((user) => {
                        cookies.set("id", user.id)
                        const date = new Date(user.updated_at.slice(0, 10))
                        const month = date.toLocaleString('default', { month: 'long' });


                        return (
                            <div
                                key={user.id}
                                className="bg-white mb-4 rounded-lg shadow-sm px-8 py-3"
                            >
                                <div className="py-3 md:pr-10 flex justify-between text-base text-gray-700 whitespace-nowrap">
                                    <h6>{user.complete_bill_id}</h6>
                                    <h>Status: <span className={user.paid ? "text-green-500 font-semibold" : "text-red-500 font-semibold"}>{user.paid ? "Paid" : "Un-Paid"}</span></h>
                                </div>
                                <div className='flex gap-2 md:gap-4 lg:text-lg'>
                                    <div className='whitespace-nowrap font-semibold'>Bill Description:</div>
                                    <h className="text-gray-500 w-full truncate pt-[-1rem]">{user.bill_description}</h>
                                </div>
                                <div className='flex gap-2 md:gap-4 lg:text-lg'>
                                    <div className='font-semibold'>Notes:</div>
                                    <h className="text-gray-500 pt-[-1rem]">{user.note}</h>
                                </div>
                                <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:grid-rows-2 lg:text-lg'}>
                                    <div className="sm:flex gap-2 md:gap-4">
                                        <div className='font-semibold'>Bill Date:</div>
                                        <h className="text-gray-500 whitespace-nowrap ">
                                            {date.getDate()}&nbsp;
                                            {month.slice(0, 3)}&nbsp;
                                            {date.getFullYear()}
                                        </h>
                                    </div>
                                    <div className='sm:flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Amount:</div>
                                        <h className="text-gray-500">{user.currency}{" "}{user.amount}</h>
                                    </div>
                                    <div className='sm:flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Created By:</div>
                                        <h className="text-gray-500 sm:w-auto w-24 truncate">{user.created_by_name}</h>
                                    </div>
                                    <div className='sm:flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Payment To:</div>
                                        <h className="text-gray-500 truncate sm:w-auto w-24">{user.created_for_name}</h>
                                    </div>
                                    {user.paid ? <div className='py-[.75rem] md:py-8 col-span-2 md:col-start-3 md:col-end-4 md:row-start-1 md:row-end-3'></div>
                                    :<div onClick={() => id = user.id}
                                        className="py-3 col-span-2 md:col-start-3 md:col-end-4 md:row-start-1 md:row-end-3 text-base text-gray-700 whitespace-nowrap">
                                        <div
                                            onClick={() => { showConfirm() }}
                                            className='bg-blue-500 max-w-[10rem] ml-auto hover:bg-blue-600 py-2 rounded-lg text-white hover:text-blue-100 text-center cursor-pointer shadow-md'> Change to Paid
                                        </div>
                                    </div>}
                                </div>
                            </div>

                        )
                    })
                ) : (
                    <div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div className="">
                            <EmptyState 
                            btn="Add New Bill"
                            img={noProfile}
                            title="No Bills Found Yet"
                            message="There are currently no bills or invoices to display. Check back later for updates."
                            btnLink="/AddBill"
                            />
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}


export function TeamBill() {


    const [data, setData] = useState([]);
    const [loading , setLoading] = useState(true)
    const navigate = useNavigate();

    const { confirm } = Modal;

    async function getData() {
        await axios
            .get('hri_admin/bill/team', {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });

    }

    var id

    const showConfirm = () => {
        confirm({
            title: 'Are you sure you want to change the bill to paid?',
            //   icon: <ExclamationCircleFilled />,
            content: 'You will be redirected to a new page to fill the payout/transaction details of the bill',
            onOk() {
                console.log('OK', id);
                navigate("/Financials/" + id + "/payout")
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    useEffect(()=>{
        getData();
    },[])

    return (
        <div>
           {data.length ? (
                    data.map((user) => {
                        cookies.set("id", user.id)
                        const date = new Date(user.updated_at.slice(0, 10))
                        const month = date.toLocaleString('default', { month: 'long' });


                        return (
                            <div
                                key={user.id}
                                className="bg-white mb-4 rounded-lg shadow-sm px-8 py-3"
                            >
                                <div className="py-3 md:pr-10 flex justify-between text-base text-gray-700 whitespace-nowrap">
                                    <h6>{user.complete_bill_id}</h6>
                                    <h>Status: <span className={user.paid ? "text-green-500 font-semibold" : "text-red-500 font-semibold"}>{user.paid ? "Paid" : "Un-Paid"}</span></h>
                                </div>
                                <div className='flex gap-2 md:gap-4 lg:text-lg'>
                                    <div className='whitespace-nowrap font-semibold'>Bill Description:</div>
                                    <h className="text-gray-500 w-full truncate pt-[-1rem]">{user.bill_description}</h>
                                </div>
                                <div className='flex gap-2 md:gap-4 lg:text-lg'>
                                    <div className='font-semibold'>Notes:</div>
                                    <h className="text-gray-500 pt-[-1rem]">{user.note}</h>
                                </div>
                                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:grid-rows-2 lg:text-lg'>
                                    <div className="flex gap-2 md:gap-4">
                                        <div className='font-semibold'>Bill Date:</div>
                                        <h className="text-gray-500 whitespace-nowrap ">
                                            {date.getDate()}&nbsp;
                                            {month.slice(0, 3)}&nbsp;
                                            {date.getFullYear()}
                                        </h>
                                    </div>
                                    <div className='flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Amount:</div>
                                        <h className="text-gray-500">{user.currency}{" "}{user.amount}</h>
                                    </div>
                                    <div className='flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Created By:</div>
                                        <h className="text-gray-500">{user.created_by_name}</h>
                                    </div>
                                    <div className='flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Payment To:</div>
                                        <h className="text-gray-500">{user.created_for_name}</h>
                                    </div>

                                    <div onClick={() => id = user.id}
                                        className="py-3 col-span-2 md:col-start-3 md:col-end-4 md:row-start-1 md:row-end-3 text-base text-gray-700 whitespace-nowrap">
                                        <div
                                            onClick={() => { showConfirm() }}
                                            className='bg-blue-500 max-w-[10rem] ml-auto hover:bg-blue-600 py-2 rounded-lg text-white hover:text-blue-100 text-center cursor-pointer shadow-md'> Change to Paid
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                ) : (
                    <div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div className="">
                            <EmptyState 
                            btn="Add New Bill"
                            img={noProfile}
                            title="No Bills Found Yet"
                            message="There are currently no bills or invoices to display. Check back later for updates."
                            btnLink="/AddBill"
                            />
                            </div>
                        )}
                    </div>

                )}
        </div>
    )
}



export function RequestBill() {

    const [data, setData] = useState([]);
    const [loading , setLoading] = useState(true)
    const navigate = useNavigate();

    const { confirm } = Modal;

    async function getData() {
        await axios
            .get('hri_admin/bill/request', {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });

    }

    var id

    const showConfirm = () => {
        confirm({
            title: 'Please confirm accepting this bill',
            //   icon: <ExclamationCircleFilled />,
            content: 'You will confirm this bill to proceeding further',
            onOk() {
             axios
                .get('hri_admin/bill-accept/'+ id, {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
                .then((res) => {
                    toast.success(res.data.message,{
                        position: "top-center"
                    })
                    getData()
                    
                })
                .catch((err) => {
                    toast.warning("somthing went wrong!")
                    console.log(err);
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    useEffect(()=>{
        getData();
    },[])

    return (
        <div>
           {data.length ? (
                    data.map((user) => {
                        cookies.set("id", user.id)
                        const date = new Date(user.updated_at.slice(0, 10))
                        const month = date.toLocaleString('default', { month: 'long' });


                        return (
                            <div
                                key={user.id}
                                className="bg-white mb-4 rounded-lg shadow-sm px-8 py-3"
                            >
                                <div className="py-3 md:pr-10 flex justify-between text-base text-gray-700 whitespace-nowrap">
                                    <h6>{user.complete_bill_id}</h6>
                                    <h>Status: <span className={user.paid ? "text-green-500 font-semibold" : "text-red-500 font-semibold"}>{user.paid ? "Paid" : "Un-Paid"}</span></h>
                                </div>
                                <div className='flex gap-2 md:gap-4 lg:text-lg'>
                                    <div className='whitespace-nowrap font-semibold'>Bill Description:</div>
                                    <h className="text-gray-500 w-full truncate pt-[-1rem]">{user.bill_description}</h>
                                </div>
                                <div className='flex gap-2 md:gap-4 lg:text-lg'>
                                    <div className='font-semibold'>Notes:</div>
                                    <h className="text-gray-500 pt-[-1rem]">{user.note}</h>
                                </div>
                                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:grid-rows-2 lg:text-lg'>
                                    <div className="flex gap-2 md:gap-4">
                                        <div className='font-semibold'>Bill Date:</div>
                                        <h className="text-gray-500 whitespace-nowrap ">
                                            {date.getDate()}&nbsp;
                                            {month.slice(0, 3)}&nbsp;
                                            {date.getFullYear()}
                                        </h>
                                    </div>
                                    <div className='flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Amount:</div>
                                        <h className="text-gray-500">{user.currency}{" "}{user.amount}</h>
                                    </div>
                                    <div className='flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Created By:</div>
                                        <h className="text-gray-500">{user.created_by_name}</h>
                                    </div>
                                    <div className='flex gap-2 md:gap-4'>
                                        <div className='font-semibold'>Payment To:</div>
                                        <h className="text-gray-500">{user.created_for_name}</h>
                                    </div>

                                    {user.paid ? null
                                    :<div onClick={() => id = user.id}
                                        className="py-3 col-span-2 md:col-start-3 md:col-end-4 md:row-start-1 md:row-end-3 text-base text-gray-700 whitespace-nowrap">
                                            <div className='flex flex-wrap gap-4 justify-around font-semibold'>
                                        <div
                                            onClick={() => { showConfirm() }}
                                            className='bg-green-500 min-w-[10rem] hover:shadow-md hover:bg-green-600 py-2 px-8 rounded-lg text-white hover:text-blue-100 text-center cursor-pointer shadow-md'> Accept
                                        </div>
                                        <div className='bg-red-500 min-w-[10rem] hover:shadow-md hover:bg-red-600 py-2 px-8 rounded-lg text-white hover:text-blue-100 text-center cursor-pointer shadow-md'> Decline
                                        </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                        )
                    })
                ) : (
                    <div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div className="">
                            <EmptyState 
                            btn="Add New Bill"
                            img={noProfile}
                            title="No Bills Found Yet"
                            message="There are currently no bills or invoices to display. Check back later for updates."
                            btnLink="/AddBill"
                            />
                            </div>
                        )}
                    </div>

                )}
        </div>
    )
}

