import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { MdArrowBackIos } from 'react-icons/md';
import axios from 'axios';
import { BiChevronDown } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { IoReceiptOutline } from 'react-icons/io5';
import Spinner from '../../components/Spinner';
import { EmptyState } from '../../components/EmptyStates/EmptyState';
import noProfile from "../../Images/empty_states/no-payment.png"

const cookies = new Cookies();
const Payout = () => {
    const [data, setData] = useState([]);
    const [loading , setLoading] = useState(true)
    const navigate = useNavigate();
    const [selectedReceipt, setSelectedReceipt] = useState('');

    async function getData() {
        await axios
            .get('hri_admin/payout', {
                headers: {
                    Authorization: 'Token ' + cookies.get('token'),
                },
            })
            .then(resp => {
                setData(resp.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (cookies.get('token')) {
            console.log(cookies.get('BillId'));
            getData();
        } else {
            navigate('/');
        }
    }, []);

    function handleViewReceipt(receiptURL) {
        setSelectedReceipt(receiptURL);
    }

    return (
        <>
            <div className="overflow-auto mx-4 rounded-lg mt-4 shadow   mb-20">
                {data.length ? (
                    <table className="w-full ">
                        <thead className="bg-slate-200 border-b-2 border-gray-200">
                            <tr className="text-blue-700 text-sm ">
                                <th className="w-40 p-3 pl-10  font-semibold tracking-wide text-center">
                                    Transaction ID
                                </th>
                                <th className="w-12 p-3  font-semibold tracking-wide text-center">
                                    Bill ID
                                </th>

                                <th className="w-40 p-3 font-semibold tracking-wide text-center">
                                    Transaction Date
                                </th>
                                <th className="w-40 p-3  font-semibold tracking-wide text-center">
                                    Amount Paid
                                </th>
                                <th className="w-40 p-3 font-semibold tracking-wide text-center">
                                    Mode of Payment
                                </th>
                                <th className="w-24 p-3  font-semibold tracking-wide text-center">
                                    Receipt
                                </th>
                                <th className="w-24 p-3  font-semibold tracking-wide text-center">
                                    Details
                                </th>
                            </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-300 text-center">
                            {data.map(user => {
                                cookies.set('id', user.id);
                                const date = new Date(
                                    user.updated_at.slice(0, 10)
                                );
                                const month = date.toLocaleString('default', {
                                    month: 'long',
                                });

                                return (
                                    <tr
                                        key={user.id}
                                        className="bg-white text-sm  hover:bg-slate-100"
                                    >
                                        <td className="p-3  pl-10  text-gray-700 whitespace-nowrap">
                                            {user.transaction_id}
                                        </td>
                                        <td className="p-3  pl-10 text-gray-700 whitespace-nowrap">
                                            {user.user_bill}
                                        </td>
                                        <td className="p-3  MessageLine  text-gray-700 whitespace-nowrap">
                                            {date.getDate()}&nbsp;
                                            {month.slice(0, 3)}&nbsp;
                                            {date.getFullYear()}
                                        </td>

                                        <td className="p-3   text-gray-700 whitespace-nowrap">
                                            {user.mode_payment} {user.amount}
                                        </td>
                                        <td className="p-3   text-gray-700 whitespace-nowrap">
                                            {user.mode_payment}
                                        </td>
                                        <td className="p-3  text-gray-700 flex justify-center whitespace-nowrap">
                                            <div
                                                key={user.id}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalScrollable"
                                                className="ml-1 cursor-pointer md:ml-24 mt-8"
                                            >
                                                {/* Modal */}
                                                <div
                                                    className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                                                    id="exampleModalScrollable"
                                                    tabIndex="-1"
                                                    aria-labelledby="exampleModalScrollableLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                                                        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                                            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                                                <h5
                                                                    className="text-xl font-medium leading-normal text-gray-800"
                                                                    id="exampleModalScrollableLabel"
                                                                >
                                                                    <div>
                                                                        Payment
                                                                        Receipt
                                                                    </div>
                                                                </h5>
                                                            </div>
                                                            <div className="modal-body relative p-4">
                                                                <iframe
                                                                    title="Payment Receipt"
                                                                    src={
                                                                        selectedReceipt
                                                                    }
                                                                    width="100%"
                                                                    className="h-96"
                                                                />
                                                            </div>
                                                            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                                                <button
                                                                    type="button"
                                                                    className="inline-block px-6 py-2.5 bg-black text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                                                                    data-bs-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex md:ml-[-90px] justify-center">
                                                <div className="flex">
                                                    <div
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModalScrollable"
                                                        onClick={() =>
                                                            handleViewReceipt(
                                                                user.payment_receipt
                                                            )
                                                        }
                                                        className="text-sm ml-2  cursor-pointer text-blue-500  flex justify-center hover:bg-gray-200 px-4 py-2 rounded-lg"
                                                    >
                                                        <IoReceiptOutline className="text-lg mr-2" />
                                                        <div>View</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="p-3   text-gray-700 whitespace-nowrap">
                                            <div
                                                onClick={() => {
                                                    navigate('/PayoutDetails');
                                                    cookies.set(
                                                        'PayoutId',
                                                        user.id
                                                    );
                                                }}
                                                className="bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-gray-200 hover:text-blue-700 cursor-pointer "
                                            >
                                                {' '}
                                                View Details
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div className="">
                            <EmptyState 
                            btn="Add New Payout"
                            img={noProfile}
                            title="No Payouts Yet"
                            message="Currently, there are no payouts to display. Rest assured, payouts will be shown once they are processed"
                            btnLink="/Financials/bill/payout"
                            />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Payout;
