import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Autocomplete } from '@mui/material';
const cookies = new Cookies();

const AddBill = () => {

  useEffect(() => {
    if (cookies.get("token")) {

    } else {
      navigate("/");
    }
  }, []);

  const navigate = useNavigate();
  const [currency, setCurrency] = useState("INR");
  const [amount, setAmount] = useState("");
  const [payTo, setPayTo] = useState("");
  const [id, setId] = useState("");
  const [team, setTeam] = useState("");
  const [bill_description, setbill_description] = useState("");
  const [note, setNote] = useState("");

  async function send(e) {
    e.preventDefault();
    let data = { amount, currency, bill_description, note, created_for:id };
    console.log(data);
    axios
      .post("hri_admin/bill/create", data, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        }
      })
      .then((resp) => {


        navigate("/Financials");

      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    axios.get("hri_admin/teamemail", {
      headers: {
        Authorization: "Token " + cookies.get("token")
      }
    })
      .then(res => {
        setTeam(res.data)
      }).catch(err => {
        console.log(err)
      })
  }, [])





  return (
    <>
      <Header />
      <Sidebar />

      <div className="bg-gray-100 w-full h-screen">
        <div

        >
          <div className='pt-4'>
            <div className="rounded-t-lg bg-sky-100 ">
              <div className="flex items-center justify-between px-[.5rem] md:px-5 py-2 rounded-md z-10">
                <div onClick={()=>navigate(-1)}>
                  <div className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                    <ArrowBackIosNewIcon style={{ height: 20 }} />
                    <span>Back</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 mx-4 space-y-5 rounded-b-lg mb-4">
            <div className="">
              <div className="grid  md:grid-cols-2 lg:grid-cols-3  gap-y-4 gap-x-10 ">
                <FormControl sx={{ minWidth: 130 }}>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    value={currency}
                    label="Currency"
                    onChange={(e) => {
                      setCurrency(e.target.value);
                    }}
                  >
                    <MenuItem value={"INR"}>INR</MenuItem>
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"POUND"}>POUND</MenuItem>
                    <MenuItem value={"EURO"}>EURO</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Amount"
                  type="number"
                  variant="outlined"
                  placeholder="Enter the amount"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />

                <Autocomplete
                  disablePortal
                  // value={email}
                  onChange={(e, newValue) => { setId(newValue.user_id) }}
                  id="combo-box-demo"
                  getOptionLabel={(option) => option.name}
                  options={team}
                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                  sx={{ width: "auto" }}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Search By Name"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }} />}
                />
              </div>

            </div>
            <div className="flex flex-col space-y-2">
              <span className="font-semibold text-gray-400 text-lg">
                Bill Description
              </span>
              <textarea
                rows="4"
                cols="50"
                className="border-2 border-gray-300 outline-none w-full rounded-md p-2 text-lg"
                placeholder="Bill Description will shown here every amount in details will be in description"
                value={bill_description}
                onChange={(e) => {
                  setbill_description(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <span className="font-semibold text-gray-400 text-lg">Notes</span>
              <textarea
                rows="2"
                cols="50"
                className="border-2 border-gray-300 outline-none w-full rounded-md p-2 text-lg"
                placeholder="Notes will shown here every amount in details will be in Notes"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>
            <div className="flex items-center space-x-5">
              <Button
                variant="contained"
                startIcon={<SendIcon />}
                onClick={send}
                disabled={!amount || !bill_description || !note ? true : false}
              >
                Send
              </Button>
              <Button onClick={() => navigate(-1)} variant="outlined" startIcon={<CloseIcon />} >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBill