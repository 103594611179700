import React from 'react'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Image from "./Avatar.png"
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { message } from 'antd';
import { toast } from 'react-toastify';
import { Box, Modal } from "@mui/material";
import { BsDiamond, BsFillDiamondFill } from 'react-icons/bs';
import { Cancel, WorkspacePremium } from '@mui/icons-material';
import { changeInterviewStatus } from '../../controller/interviewController';
import { HTTPStatus } from '../../utils/helperObj';
import { handleControllerResultException } from '../../utils/handleException';

const ViewDetail = () => {

    const { id } = useParams();

    const cookies = new Cookies();
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [status, setStatus] = useState("");
    const [changingStatus, setChangingStatus] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [open, setOpen] = useState(false);
    const [list, setList] = useState("");
    const [profileId , setProfileId] = useState(0)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    useEffect(() => {
        getData();

        axios.get("/survey/interview-review-detail/" + id, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            }
        }).then(res => {
            setList(res.data)
            console.log(res)
        }).catch(err => {
            console.log({ err })
            if (err?.response?.status !== 400) {
                toast.error("Something went wrong!")
            }
        })

    }, [])

    function getData() {
        // setIsPending(true);
        axios.get(`hri_admin/interview-detail/${id}`, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        })
            .then((response) => {
                setData(response.data);
                setProfileId(response.data.id)
                setStatus(response.data.progress_status)
                setIsPending(false);
            }).catch((err) => {
                toast.warning(err.message, {
                    position: "top-center"
                })
                console.log(err)
            });
    }

    async function handleChangeStatus(status) {
        setChangingStatus(true);
        const res = await changeInterviewStatus(profileId, status);
        setChangingStatus(false);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            message.success(res.success);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginY: "40px",
        transform: "translate(-50%, -50%)",
        // width: "auto",
        bgcolor: "background.paper",
        borderRadius: "10px",
        boxShadow: 24,
    };

    var { review_answers } = list

    return (
        <div className="bg-default-background w-full">
            <Header />
            <Sidebar />
            <div className="">
                {isPending ? <div className='flex justify-center items-center h-[90vh]'><h1 className='text-3xl'>Loading<span className='animate-pulse'>.</span><span className='animate-pulse'>.</span><span className='animate-ping'>.</span></h1></div>
                    :
                    <div className="mt-2 pb-20 mx-2 bg-white">
                        <div className='flex justify-between md:mx-10 mx-[.5rem] pt-4'>
                            <Button onClick={() => navigate(-1)} size="large" className="text-blue-600 text-xl cursor-pointer w-12 p-2 my-2 mx-4"><NavigateBeforeIcon />Back</Button>
                            <Button size="large" onClick={handleOpen} variant='contained' className="text-xl mr-auto cursor-pointer p-2 my-2">View Answers</Button>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                className="overflow-hidden"
                            >
                                <Box
                                    sx={style}
                                >

                                    <div className='h-[60vh] p-4 overflow-auto'>
                                        {
                                            list ? <>
                                                <div className='md:m-4'>
                                                    <h1 className='md:text-2xl'>{list.interview_form_details?.name}</h1>
                                                    <p className='whitespace-nowrap md:text-xl'>{list.interview_form_details?.description}</p>
                                                    <p>Unique Id :{" "}{list.interview_form_details?.unique_id}</p>
                                                </div>
                                                {review_answers.length ?
                                                    review_answers.map((data, index) => (
                                                        <div key={index} className='md:m-4'>
                                                            <h1 className='md:text-xl'>{index+1}.{" "}Email :{" "}{data.email}</h1>
                                                            {data.answers.map((ans, index)=>(
                                                            <div className='ml-4' >
                                                            <h1 className='md:text-xl'>Q{index+1}.{" "}{ans?.question_detail?.question}</h1>
                                                            {ans.answer?<p className='md:text-lg'>Ans :{" "}{ans.answer}</p> : null}
                                                            {ans.answer_media? <p className='md:text-lg'>File :{" "}<a href={ans.answer_media} target="_blank" rel="noreferrer">View</a></p>
                                                            :null}
                                                            </div>
                                                            ))
                                                            }

                                                        </div>
                                                    ))
                                                    : "No Answers"}
                                            </>
                                                : <div className='text-xl m-4 py-10'><span className='whitespace-nowrap'>No review form is attached</span> with this interview</div>
                                        }
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-col my-10 sm:flex-row justify-between">
                                <div className="flex gap-3 w-32 lg:ml-[2.5rem]">
                                    <img src={data.personal_details.profile_pic ? data.personal_details.profile_pic : Image} alt="" className="md:w-32 md:h-32 rounded-full ml-2" />
                                    <div className="text-xl font-bold mt-[1rem] lg:ml-6 lg:mt-[2rem]">{data.personal_details.name || "Not Available"}</div>

                                </div>
                                <div className="flex flex-col md:ml-[10rem] lg:ml-[30rem]">
                                    <div className="flex">
                                        <Link to={`/send-message?user=${data.user}`}>
                                            <div className="p-3 text-base whitespace-nowrap">
                                                <div className="bg-slate-100 px-2 py-2 rounded-lg text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer">Message <MessageOutlinedIcon /></div>
                                            </div>
                                        </Link>
                                        <a target='_blank' href={`mailto:${data.user_email}`} rel="noreferrer">
                                            <div className="p-3 text-base whitespace-nowrap">
                                                <div className="bg-slate-100 px-2 py-2 rounded-lg text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer">Mail <EmailOutlinedIcon /></div>
                                            </div>
                                        </a>
                                    </div>
                                    <Link to={`/applications-details/${data.user}`}>
                                        <div className="p-3 text-base whitespace-nowrap">
                                            <div className="flex items-center justify-center bg-slate-100 px-1 py-1 rounded-lg text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer">View Profile</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row">
                                <div className="flex flex-col">
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">Interview ID</div><div className="text-xl md:ml-[6.5rem]">:</div><div className="text-xl ml-[1rem]">{data.interview_unique_id || "Not available"}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">Skill</div><div className="text-xl md:ml-[10.7rem] ml-20">:</div><div className="text-xl  ml-[1rem]">{data.skill || "Not available"}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">Interviewer number</div><div className="text-xl md:ml-8 ml-2">:</div><div className="text-xl ml-[1rem]">{data.interviewer_no}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[4rem]">Applicant Status</div>
                                        <div className="text-xl ml-4 md:ml-[4rem] mr-4">:</div>
                                        <FormControl variant="standard" sx={{ m: 0, minWidth: 140 }}>
                                            <Select
                                                disabled={changingStatus}
                                                value={status}
                                                onChange={e => {
                                                    handleChangeStatus(e.target.value);
                                                    setStatus(e.target.value);
                                                }}
                                                variant="standard"
                                            >
                                                <MenuItem value={"Initiated"}>Initiated</MenuItem>
                                                <MenuItem value={"Selected"}>Selected</MenuItem>
                                                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="flex flex-col text-left">
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[13rem]">Date</div><div className="text-xl ml-11 lg:ml-[3.2rem]">:</div><div className="text-xl  ml-[1rem]">{data.date}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[13rem]">Time</div><div className="text-xl ml-12">:</div><div className="text-xl  ml-[1rem]">{data.format_time}</div>
                                    </div>
                                    <div className="flex mt-[1rem]">
                                        <div className="text-xl ml-[1rem] lg:ml-[13rem]">Duration</div><div className="text-xl lg:ml-[1rem] ml-6">:</div><div className="text-xl ml-[1rem]">{data.duration === 61 ? "1+ Hours" : <p>{data.duration} minutes</p>} </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="text-neutral-600 md:ml-[5rem] text-lg mt-[2rem] ml-3">{data.updated_at.slice(0, 10)}</div>
                                <div className="flex">
                                    {(data.progress_status === "Initiated" || data.progress_status === "initiated") ?
                                        <div className='mt-10 ml-8'>
                                            <div>
                                                <BsFillDiamondFill className='text-blue-600' />
                                                <div className='w-[2px] h-32 mx-auto border-r-[2px] border-blue-600'>
                                                </div>
                                            </div>
                                            <div>
                                                <BsDiamond className='text-blue-600' />
                                                <div className='w-[2px] border-r-[2px] border-dashed border-blue-500 h-[4.5rem] mx-auto '>
                                                </div>
                                            </div>
                                            <div>
                                                <BsDiamond className='text-blue-600' />
                                                <div className='w-[2px] border-r-[2px] border-dashed border-blue-600 h-[4rem] mx-auto '>
                                                </div>
                                            </div>
                                            <div className='text-blue-500'>
                                                <BsDiamond className='text-blue-600' />

                                            </div>
                                        </div>
                                        : data.progress_status === "Initiated" ?
                                            <div className='mt-10 ml-8'>
                                                <div>
                                                    <BsFillDiamondFill className='text-blue-600' />
                                                    <div className='w-[2px] h-32 mx-auto border-r-[2px] border-blue-600'>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BsFillDiamondFill className='text-blue-600' />
                                                    <div className='w-[2px] border-r-[2px] border-blue-500 h-[4.5rem] mx-auto '>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BsDiamond className='text-blue-600' />
                                                    <div className='w-[2px] border-r-[2px] border-dashed border-blue-600 h-[4rem] mx-auto '>
                                                    </div>
                                                </div>
                                                <div className='text-blue-500'>
                                                    <BsDiamond className='text-blue-600' />

                                                </div>
                                            </div>
                                            : data.progress_status === "Selected" ?
                                                <div className='mt-10 ml-8'>
                                                    <div>
                                                        <BsFillDiamondFill className='text-green-500' />
                                                        <div className='w-[2px] h-32 mx-auto border-r-[2px] border-green-500'>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-green-500' />
                                                        <div className='w-[2px] border-r-[2px] border-green-500 h-[4.5rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-green-500' />
                                                        <div className='w-[2px] border-r-[2px] border-green-500 h-[4rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div className='text-blue-500'>
                                                        <BsFillDiamondFill className='text-green-600' />

                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-10 ml-8'>
                                                    <div>
                                                        <BsFillDiamondFill className='text-red-500' />
                                                        <div className='w-[2px] h-32 mx-auto border-r-[2px] border-red-500'>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-red-500' />
                                                        <div className='w-[2px] border-r-[2px]  border-red-500 h-[4.5rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <BsFillDiamondFill className='text-red-500' />
                                                        <div className='w-[2px] border-r-[2px] border-red-500 h-[4rem] mx-auto '>
                                                        </div>
                                                    </div>
                                                    <div className='text-red-500'>
                                                        <BsFillDiamondFill className='text-red-500' />

                                                    </div>
                                                </div>

                                    }

                                </div>

                                {
                                    data.progress_status === "Rejected" ?
                                        <div className="flex flex-col">
                                            <div className="text-lg font-bold mt-[2rem] ml-[2rem]">Uploaded</div>
                                            <div className="text-lg font-bold mt-[7rem] ml-[2rem]">Working in Progress</div>
                                            <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Contacted:</div>
                                            <div className="text-base text-neutral-600 ml-[2rem]">ABC University of Delhi</div>
                                            {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                                <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">View Report</div>
                                            </div> */}
                                            <div className="text-lg font-bold mt-[3rem] ml-[2rem]"><Cancel className='text-red-500' />Rejected</div>
                                            <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Note : The person is vrifiedby the candidate father name ramu</div>
                                            {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                                <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">Re - Verify</div>
                                            </div> */}
                                        </div>
                                        :
                                        <div className="flex flex-col">
                                            <div className="text-lg font-bold mt-[2rem] ml-[2rem]">Uploaded</div>
                                            <div className="text-lg font-bold mt-[7rem] ml-[2rem]">Working in Progress</div>
                                            <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Contacted:</div>
                                            <div className="text-base text-neutral-600 ml-[2rem]">ABC University of Delhi</div>
                                            {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                                <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">View Report</div>
                                            </div> */}
                                            <div className="text-lg font-bold mt-[3rem] ml-[2rem]">{data.progress_status === "Selected" ? <span className='flex'><WorkspacePremium className="text-blue-500 animate-[wiggle_1s_ease-in-out_infinite]" />Selected</span> : "Selected"}</div>
                                            <div className="text-base text-neutral-600 ml-[2rem] mt-[1rem]">Note : candidate is selected</div>
                                            {/* <div className="w-[8rem] h-[1.5rem] border-2 bg-slate-100 rounded-lg ml-[2rem]">
                                                <div className="text-neutral-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer ml-[1rem]">Re - Verify</div>
                                            </div> */}
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                }

            </div>
        </div >
    )
}

export default ViewDetail;
