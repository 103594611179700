/**
 * @typedef {object} JobApplicationModel
 * @property {number} id
 * @property {number} assessmentId
 * @property {boolean} isAssessment
 * @property {boolean} isCompanyBookmark
 * @property {string} jobName
 * @property  {number} positionId
 * @property {string} resumeFileName
 * @property {string} resumeFileLink
 * @property {string} applicantFirstName
 * @property {string} applicantLastName
 * @property {string} applicantProfilePic
 * @property {number}  applicantId
 * @property {string} status
 * @property {string} appliedDate
 * @property {string} applicantSpecialization
 * @property {import("./UserApplicationModel").UserSkillModel[]} skills
 */

import { createdAtToDate } from "../utils/helperFunction";
import { createUserSkillObj } from "./UserApplicationModel";



export function createJobApplicationObj(data) {

    /**
     * @type {JobApplicationModel}
     */
    const result = {
        id: data.id,
        assessmentId: data.assessment_id,
        isAssessment: data.is_assessment,
        isCompanyBookmark: data.company_bookmark,
        jobName: data.job_name,
        positionId: data.position,
        resumeFileName: data.resume_name,
        resumeFileLink: data.user_resume,
        applicantFirstName: data.user_firstname,
        applicantLastName: data.user_lastname,
        applicantProfilePic: data.user_pic,
        applicantId: data.user,
        appliedDate: createdAtToDate(data.applied_on),
        status: data.status,
        applicantSpecialization: data.user_specialization,
        skills: []
    }

    for (let x of data.user_skills) {
        result.skills.push(createUserSkillObj(x))
    }
    return result
}
