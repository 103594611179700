import axios from "axios";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const getRequest = (endPoint, setData, setLoading) => {
  axios
    .get(endPoint, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      if (setLoading) {
        setLoading(false);
      }
      setData(res.data);
    })
    .catch((err) => {
      toast.error("Some error occurred");
    });
};

const postRequest = (endPoint, data, path) => {
  axios
    .post(`${endPoint}`, data, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      console.log("response from submitting the form successful", res.data);
      toast.success("Successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#1ab394", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#BAE8DE" },
      });
      window.location.replace(path);
    })
    .catch((err) => {
      console.log("ERROR  from update in form", err);
      toast.error("Error", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#FF6347", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#FFB1A3" },
      });
    });
};

const updateRequest = (endPoint, data, path) => {
  axios
    .put(`${endPoint}`, data, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      console.log("response from submitting the form successful", res.data);
      window.location.replace(path);
    })
    .catch((err) => {
      console.log("ERROR  from update in form", err);
      toast.error("Some error occurred");
    });
};

const deleteRequest = (endPoint, path) => {
  axios
    .get(`${endPoint}`, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      console.log("response from submitting the form successful", res.data);
      window.location.replace(path);
    })
    .catch((err) => {
      console.log("ERROR  from update in form", err);
      toast.error("Some error occurred");
    });
};

export { getRequest, postRequest, updateRequest, deleteRequest };
