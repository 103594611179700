import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

/**
 * @typedef {object} Props
 * @property {import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">> & {muiName:string}} Icon
 * @property {string} text
 * @property {string} link
 */

/**
 * @param {Props} props
 */
export default function ApplicationLinkedButton({ Icon, text, link }) {
    return (
        <a href={link} target="_blank">
            <Box
                sx={{
                    color: grey[600],
                    backgroundColor: grey[300],
                    marginX: "5px",
                }}
                padding={"2px 4px"}
                borderRadius={"5px"}
                display={"flex"}
                alignItems={"center"}
            >
                <Typography fontWeight={"bold"} fontSize={"13px"}>
                    {text}
                </Typography>
                <Icon fontSize="13px" sx={{ marginLeft: "5px" }} />
            </Box>
        </a>
    );
}
