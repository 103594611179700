import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";


import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";
const cookies = new Cookies();

const BizZone = () => {

    const navigate = useNavigate();

    const [whoAreWe, setWhoAreWe] = useState("");
    const [aboutUsHead, setAboutUsHead] = useState("");
    const [aboutUs, setAboutUs] = useState("");

    const getBusinessDetails = () => {
        axios
            .get(
                `hri_admin/business-detail-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                // console.log("Business Details: ", res.data);
                setWhoAreWe(res.data[0].who_are_we);
                setAboutUsHead(res.data[0].about_us_heading);
                setAboutUs(res.data[0].about_us);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const putBizData = () => {
        axios
            .put(
                `hri_admin/business-detail-biz-zone-create-update`,
                {
                    who_are_we: whoAreWe,
                    about_us_heading: aboutUsHead,
                    about_us: aboutUs,
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                console.log("Biz Zone Data updated", res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log("Error updating the Biz Zone data", err);
            })
    }

    useEffect(() => {
        if (cookies.get("token")) {
            getBusinessDetails();
        } else {
            navigate("/");
        }
    },[]);


    return (
        <div className="py-4 px-4 md:px-10 space-y-5">
            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    ABOUT THE BUSINESS
                </p>
            </div>
            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    Who we are
                </p>
                <TextField
                    multiline
                    fullWidth
                    minRows={3}
                    value={whoAreWe}
                    label="Who are we"
                    onChange={(e) => setWhoAreWe(e.target.value)}
                />
            </div>
            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    About Us -Heading
                </p>
                <TextField
                    label="Our Mission-Heading"
                    className="w-full md:w-1/2"
                    value={aboutUsHead}
                    onChange={(e) =>  setAboutUsHead(e.target.value)}
                />
            </div>
            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    About Us
                </p>
                <TextField
                    fullWidth
                    label="Our Mission-statement / Our Achievements"
                    multiline
                    minRows={3}
                    className=""
                    value={aboutUs}
                    onChange={(e) => setAboutUs(e.target.value)}
                />
            </div>
            <div className="flex flex-col md:flex-row md:space-x-5">
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                    onClick={putBizData}
                >
                    Save
                </Button>
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default BizZone;