import React from "react";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const DetailsTab = ({title, subtitle, location, span}) => {
  return (
    <div className={span}>
      <div>
        <p className="text-gray-400 font-semibold text-lg">{title}</p>
        <div className={`flex items-start space-x-1`}>
        {location && <LocationOnOutlinedIcon className="text-gray-300 my-1"/>}
        <span className="text-default-dark-gray font-semibold text-lg">
          {subtitle}
        </span>
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
