/**
 * @typedef {object} ApplicantEducation
 * @property {string} title
 * @property {string} course
 * @property {string} specialization
 * @property {string} instituteName
 * @property {string} courseType
 * @property {string} passingYear
 * @property {boolean} isCompleted
 */

/**
 * @typedef {object} Resume
 * @property {string} resumeFileName
 * @property {string} resumeFileLink
 * @property {string} resumeVideoLink
 * @property {string} resumeVideoFileName
 */

/**
 * @typedef {object} WorkExperienceModel
 * @property {string} id
 * @property {string} companyName
 * @property {string} position
 * @property {string} jobStatus
 * @property {string} city
 * @property {string} country
 * @property {boolean} isCompleted
 * @property {string} startingDate
 * @property {string} endingDate
 * @property {string} description
 */

/**
 * @typedef {object} UserSkillModel
 * @property {number} id
 * @property {string} skill
 * @property {number} rating
 */

/**
 * @typedef {object} CertificateModel
 * @property {number} id
 * @property {string} certificateFileName
 * @property {string} certificateLink
 * @property {string} degree
 * @property {string} university
 * @property {string} degreeStatus
 * @property {string} startingYear
 * @property {string} endingYear
 * @property {string} city
 * @property {string} country
 * @property {string} educationMode
 */

/**
 * @typedef {object} UserApplicationModel
 * @property {number} id
 * @property {number} userId
 * @property {string | null} role
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} profilePic
 * @property {string} martialStatus
 * @property {string} gender
 * @property {string} dateOfBirth
 * @property {string} email
 * @property {string} phoneNumber
 * @property {string} address
 * @property {string} currentAddress
 * @property {string} pinCode
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {ApplicantEducation} education
 * @property {Resume} resume
 * @property {string} language
 * @property {string} linkedinLink
 * @property {string} githubLink
 * @property {WorkExperienceModel[]} workExperience
 * @property {UserSkillModel[]} userSkills
 * @property {CertificateModel[]} certificates
 *
 */

export function createWorkExperienceObj(data) {
    /**
     * @type {WorkExperienceModel}
     */
    const result = {
        id: data.id,
        companyName: data.company_name,
        position: data.position,
        jobStatus: data.job_status,
        city: data.city,
        country: data.country,
        isCompleted: data.is_completed,
        startingDate: data.start_date,
        endingDate: data.end_date,
        description: data.description,
    };
    return result;
}

export function createUserSkillObj(data) {
    /**
     * @type {UserSkillModel}
     */
    const result = {
        id: data.id,
        skill: data.skills,
        rating: data.rating,
    };
    return result;
}

export function createCertificateObj(data) {
    /**
     * @type {CertificateModel}
     */
    const result = {
        id: data.id,
        certificateFileName: data.certificate_name,
        certificateLink: data.certificate,
        degree: data.degree_name,
        university: data.university_name,
        degreeStatus: data.degree_status,
        startingYear: data.start_year,
        endingYear: data.end_year,
        city: data.city,
        country: data.country,
        educationMode: data.education_mode,
    };
    return result;
}

function createEducationObj(data) {
    if (Array.isArray(data) && data.length > 0) {
        /**
         * @type {ApplicantEducation}
         */
        const result = {
            title: data[0].title,
            course: data[0].course,
            specialization: data[0].specialization,
            instituteName: data[0].institute_name,
            courseType: data[0].course_type,
            passingYear: data[0].pass_year,
            isCompleted: data[0].is_complete,
        };
        return result;
    } else {
        /**
         * @type {ApplicantEducation}
         */
        const result = {
            title: "",
            course: "",
            specialization: "",
            instituteName: "",
            courseType: "",
            passingYear: "",
            isCompleted: "",
        };
        return result;
    }
}

function createResumeObj(data) {
    if (Array.isArray(data) && data.length > 0) {
        /**
         * @type {Resume}
         */
        const result = {
            resumeFileName: data[0].resume_name,
            resumeFileLink: data[0].resume,
            resumeVideoLink: data[0].vdo_cv,
            resumeVideoFileName: data[0].vdo_cv_name,
        };
        return result;
    } else {
        /**
         * @type {Resume}
         */
        const result = {
            resumeFileName: "",
            resumeFileLink: "",
            resumeVideoLink: "",
            resumeVideoFileName: "",
        };
        return result;
    }
}

export function createUserApplicationObj(data) {
    /**
     * @type {UserApplicationModel}
     */

    const result = {
        id: data.id,
        userId: data.user,
        role: data.role,
        email: data.email,
        phoneNumber: data.mobile_no,
        firstName: data.first_name,
        lastName: data.last_name,
        profilePic: data.profile_pic,
        martialStatus: data.marital_status,
        gender: data.gender,
        dateOfBirth: data.dob,
        address: data.address,
        currentAddress: data.current_address,
        pinCode: data.pin,
        city: data.city,
        state: data.state,
        country: data.country,
        education: createEducationObj(data.education),
        resume: createResumeObj(data.resume),
        language: data.language,
        linkedinLink: data.linkedin_id,
        githubLink: data.github_id,
        workExperience: [],
        userSkills: [],
        certificates: [],
    };
    if (
        Array.isArray(data.work_experience) &&
        data.work_experience.length > 0
    ) {
        /**
         * @type {WorkExperienceModel[]}
         */
        const workExperienceList = [];
        for (let x = 0; x < data.work_experience.length; x++) {
            workExperienceList.push(
                createWorkExperienceObj(data.work_experience[x])
            );
        }
        result.workExperience = workExperienceList;
    }

    if (Array.isArray(data.skills) && data.skills.length > 0) {
        /**
         * @type {UserSkillModel[]}
         */
        const userSkillsList = [];

        for (let x = 0; x < data.skills.length; x++) {
            userSkillsList.push(createUserSkillObj(data.skills[x]));
        }

        result.userSkills = userSkillsList;
    }

    if (Array.isArray(data.certificate) && data.certificate.length > 0) {
        /**
         * @type {CertificateModel[]}
         */
        const certificateList = [];

        for (let x = 0; x < data.certificate.length; x++) {
            certificateList.push(createCertificateObj(data.certificate[x]));
        }
        result.certificates = certificateList;
    }
    return result;
}
