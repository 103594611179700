/**
 * @typedef {object} BroadcastMessageModel
 * @property {number} id
 * @property {number} senderId
 * @property {string} senderEmail
 * @property {string} header
 * @property {string} message
 * @property {string[]} reciverIdList
 * @property {{email:string}[]} reciverEmailList 
 * @property {string} date
 * @property {boolean} isActive
 * @property {boolean} isAdminApproved
 */

import { createdAtToDate } from "../utils/helperFunction";



export function createBroadcastMessageObj(data) {

    /**
     * @type {BroadcastMessageModel}
     */
    const result = {
        id: data.id,
        senderId: data.sender,
        senderEmail: data.sender_email,
        header: data.header,
        message: data.message,
        reciverIdList: data.receiver.split(","),
        reciverEmailList: data.receiver_email,
        date: createdAtToDate(data.created_at),
        isActive: data.is_active,
        isAdminApproved: data.admin_approval
    }
    return result
}