import { Box, Modal, Switch, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LabeledText from "../../components/LabeledText";
import RichDescription from "../../components/richdescription/RichDescription";
import { Delete } from "@mui/icons-material";
import { changeBroadcastMessageStatus, deleteBroadcastMessage } from "../../controller/broadcastMessageController";
import { HTTPStatus } from "../../utils/helperObj";
import { message as messageToast } from "antd";
import { useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../utils/handleException";

/**
 * @typedef {object} Props
 * @property {boolean} open
 * @property {()=>void} onClose
 * @property {import("../../models/BroadcastMessageModel").BroadcastMessageModel} message
 * @property {React.Dispatch<React.SetStateAction<import("../../models/BroadcastMessageModel").BroadcastMessageModel[]>>} setMessageList
 */

/**
 * @param {Props} props
 */
export default function BroadcastMessageDetailModal({
    open,
    onClose,
    message,
    setMessageList,
}) {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(message.isActive);

    useEffect(() => {
        setIsActive(message.isActive)
    }, [message])

    async function handleDeleteMessage() {
        const res = await deleteBroadcastMessage(message.id);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            setMessageList(prevState =>
                prevState.filter(data => data.id !== message.id)
            );
            messageToast.success(res.success);
            onClose();
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    async function handleChangeStatus(checked) {
        const res = await changeBroadcastMessageStatus(message.id);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            setIsActive(checked)
            checked ? messageToast.success("Message is active") : messageToast.success("Message is deactive")
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minWidth: 400,
                    width: "60%",
                    maxHeight: "800px",
                    overflowY: "auto",
                    bgcolor: "background.paper",
                    p: 6,
                }}
            >
                <Box>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                        <Tooltip title="message status">
                            <Switch
                                checked={isActive}
                                onChange={(e) => handleChangeStatus(e.target.checked)}
                                color="success"
                            />
                        </Tooltip>
                        <button onClick={handleDeleteMessage}>
                            <Tooltip title="Delete">
                                <Delete color="error" />
                            </Tooltip>
                        </button>
                    </Box>
                    <LabeledText heading="Sender" text={message.senderEmail} />
                    <LabeledText
                        heading="Topic"
                        text={message.header}
                        sx={{ marginTop: "1rem" }}
                    />
                    <Typography fontSize={"16px"} marginTop={"1rem"}>
                        Message
                    </Typography>
                    <RichDescription>{message.message}</RichDescription>
                </Box>
            </Box>
        </Modal>
    );
}
