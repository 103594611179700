/**
 * @typedef {object} JobQuestionnaire
 * @property {number} id
 * @property {string} question
 * @property {string} description
 * @property {"TextFiled" | "ChoiceField" | "File" | "Boolean" | "Video" | "Image" } answerMode
 * @property {string[]} choiceFieldQuestions
 * @property {boolean} required
 */

/**
 * @typedef {object} JobModel
 * @property {number} id
 * @property {string} positionName
 * @property {string} roleCategoryId
 * @property {string} roleSubCategoryId
 * @property {string} roleCategoryName
 * @property {string} roleSubCategoryName
 * @property {string} education
 * @property {string} jobDescription
 * @property {string} skills
 * @property {string} experience
 * @property {string}  employmentType
 * @property {string[]} locationIds
 * @property {string} location
 * @property {string} salary
 * @property {string} functionalArea
 * @property {boolean} hasQuestionnaire
 * @property {boolean} isActive
 * @property {number} questionnaireId
 * @property {string} questionnaireTitle
 * @property {string} questionnaireDescription
 * @property {string} company
 * @property {string} companyName
 * @property {string} date
 * @property {JobQuestionnaire[] }JobQuestionnaire
 * @property {[countryId:string, cityId:string, stateId:string]} locationIds
 */

import { createdAtToDate } from "../utils/helperFunction";

/**
 *
 * @param {JobModel} param
 */
export function createJobServerObj({
    positionName,
    roleSubCategoryId,
    education,
    jobDescription,
    skills,
    experience,
    employmentType,
    location,
    salary,
    functionalArea,
    JobQuestionnaire,
    hasQuestionnaire,
    questionnaireId = 0,
    questionnaireDescription,
    questionnaireTitle,
    company,
    locationIds,
    isActive
}) {
    const result = {
        position_name: positionName,
        assessment: hasQuestionnaire ? {
            id: questionnaireId,
            name: questionnaireTitle,
            description: questionnaireDescription,
            questions: JobQuestionnaire.map(data => ({
                id: data.id,
                question: data.question,
                question_type: data.answerMode,
                description: data.description,
                required: data.required,
                options:
                    data.answerMode === "ChoiceField"
                        ? data.choiceFieldQuestions.toString()
                        : null,
            })),
        } : null,
        is_assessment: hasQuestionnaire,
        is_active: isActive,
        role_sub_category: roleSubCategoryId,
        criteria: education,
        job_description: jobDescription,
        skills: skills,
        experience: experience,
        employment_type: employmentType,
        location,
        stipend: salary,
        functional_area: functionalArea,
        company,
        country_city_state_fe_id: locationIds.toString(),
    };
    return result;
}

export function createJobObj(data) {
    /**
     * @type {JobModel}
     */
    const result = {
        id: data.id,
        positionName: data.position_name,
        roleCategoryId: data.role_category_id,
        roleSubCategoryId: data.role_sub_category,
        roleCategoryName: data.role_category_name,
        roleSubCategoryName: data.role_sub_category_name,
        education: data.criteria,
        company: data.company,
        employmentType: data.employment_type,
        isActive: data.is_active,
        experience: data.experience,
        functionalArea: data.functional_area,
        hasQuestionnaire: data.is_assessment,
        jobDescription: data.job_description,
        locationIds: data.country_city_state_fe_id
            ? data.country_city_state_fe_id.split(",")
            : [],
        location: data.location,
        salary: data.stipend,
        skills: data.skills,
        questionnaireId:
            data.assessment_details !== null ? data.assessment_details.id : 0,
        questionnaireTitle:
            data.assessment_details !== null
                ? data.assessment_details.name
                : "",
        questionnaireDescription:
            data.assessment_details !== null
                ? data.assessment_details.description
                : "",
        companyName: data.company_name,
        JobQuestionnaire:
            data.assessment_details !== null
                ? data.assessment_details.questions
                    ? createJobQuestionnaireList(
                        data.assessment_details.questions
                    )
                    : []
                : [],
        date: createdAtToDate(data.created_at),
    };

    return result;
}

export function createJobQuestionnaireList(data) {
    return data.map(data => ({
        id: data.id,
        question: data.question,
        description: data.description,
        required: data.required,
        answerMode: data.question_type,
        choiceFieldQuestions:
            data.options !== null ? data.options.split(",") : [],
    }));
}
