import { Grid } from "@mui/material";
import JobCard from "./JobCard";

const today = new Date();


export default function JobList ({
    jobList,
}) {

    return (
        <div style={{ marginTop: "1rem" }}>
      
          <Grid container spacing={2} padding={2}>

            {jobList.map((item) => {
              const updatedAt = new Date(item.created_at);

              const one_day = 1000 * 60 * 60 * 24;

              const timeDifference = today.getTime() - updatedAt.getTime();
              const difference = Math.round(timeDifference / one_day);


              return (
                  <JobCard 
                    jobId={item.id}
                    companyLogo={item.company_logo}
                    positionName={item.position_name}
                    isActive={item.is_active}
                    experience={item.experience}
                    stipend={item.stipend}
                    employmentType={item.employment_type}
                    location={item.location}
                    criteria={item.criteria}
                    skills={item.skills}
                    difference={difference}
                  />
                
              );
            })}
          </Grid>

    </div>
    )
}