import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Container, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { Popover } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import noRegistered from "../../Images/empty_states/no-registered.png";
import { EmptyState } from "../../components/EmptyStates/EmptyState";
import Spinner from "../../components/Spinner";
import { blue } from "@mui/material/colors";

const cookies = new Cookies();

const UnRegisteredUser = () => {
    const navigate = useNavigate();

    const [userList, setUserList] = useState([]);
    const [loading , setLoading] = useState(true)

    // const [btnSpinner, setBtnSpinner] = useState({ next: false, prev: false });
    const [totalPages, setTotalPages] = useState(1);


    // const getDetailList = () => {
    //     axios
    //         .get(`hri_admin/bulk-resume-list`, {
    //             headers: {
    //                 Authorization: "Token " + cookies.get("token"),
    //             },
    //         })
    //         .then(res => {
    //             console.log("Un-registered Users List: ", res.data);
    //             setUserList(res.data);
    //             setLoading(false)
    //         });
    // };

    // const automateResume = id => {
    //     axios
    //         .get(`hri_admin/bulk-resume-affinda/${id}`, {
    //             headers: {
    //                 Authorization: "Token " + cookies.get("token"),
    //             },
    //         })
    //         .then(res => {
    //             console.log("Resume Automated Successflly", res.data);
    //             window.location.reload();
    //         })
    //         .catch(err => {
    //             console.log("Error Automating Resume", err);
    //         });
    // };
    // const basicAutomateResume = id => {
    //     axios
    //         .get(`hri_admin/bulk-resume-basic/${id}`, {
    //             headers: {
    //                 Authorization: "Token " + cookies.get("token"),
    //             },
    //         })
    //         .then(res => {
    //             toast.success("Resume Automated Successflly");
    //         })
    //         .catch(err => {
    //             console.log("Error Automating Resume", err);
    //         });
    // };

    // Pagination getData

    async function getData(page = 1) {
        await axios
          .get('hri_admin/bulk-resume-list', {
            headers: {
              Authorization: "Token " + cookies.get("token"),
            },
            params: {
              page,
          },
          })
          .then((resp) => {
            setUserList(resp.data.results);
            setLoading(false)
            setTotalPages(Math.ceil(resp.data.count / 10));
    
            // setJobs(resp.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    

    

    useEffect(() => {
        // getDetailList();
        getData();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ margin: "3rem auto" }}>
            {userList.length > 0 ? (
            <div>
                <TableContainer component={Paper} elevation={2} sx={{ mt: 4, mb: 10 }}>
                    <Table sx={{ width: "100%"}}>
                    <TableHead sx={{ backgroundColor: blue[700] }}>
                        <TableRow>
                            <TableCell sx={{ color: "white" }}>
                                Applicants
                            </TableCell>
                            <TableCell sx={{ color: "white" }}>
                                Resume
                            </TableCell>
                            <TableCell sx={{ color: "white" }}>
                                Contact
                            </TableCell>
                            <TableCell sx={{ color: "white" }}>
                                Details
                            </TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userList?.map((data) => (
                            <TableRow key={data.id}>
                            <TableCell>
                                {data.name ? data.name : "NA"}
                            </TableCell>
                            <TableCell>
                                <a href={data.resume} target="_blank" rel="noreferrer">
                                    {data.resume_name
                                        ? data.resume_name
                                        : "NA"
                                    }
                                </a>
                            </TableCell>
                            <TableCell>
                                <Box display="flex" gap={2}>
                                <Tooltip title={data.contact_no ? data.contact_no: "NA"}>
                                    <IconButton>
                                    <PhoneIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={data.email ? data.email : "NA"}>
                                    <IconButton>
                                    <MailOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                                </Box>
                            </TableCell>
                            <TableCell>
                            <Button
                                size="small"
                                varaint="contained"
                                onClick={() => {
                                    navigate(
                                    "/user-management/unregistered-user/details/personal"
                                    );
                                    localStorage.setItem(
                                    "Un-registered User",
                                    data.id
                                    );
                                }}
                            >
                                Details
                            </Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            <Box mt={4} display="flex" justifyContent="center">
                <Pagination
                count={totalPages}
                onChange={(_, page) => getData( page)}
                color="primary"
                />
            </Box>
        </div>
      ) : (
        <EmptyState image={noRegistered} text="No Registered User found" />
      )}
      
      {loading && <Spinner />}
    </Container>
    );
};

export default UnRegisteredUser;
