import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import {
    createInstitute,
    instituteDetails,
    updateInstituteDetails,
} from "../../controller/instituteController";
import { HTTPStatus } from "../../utils/helperObj";
import { message } from "antd";
import { handleControllerResultException } from "../../utils/handleException";

export default function InstituteForm() {
    const navigate = useNavigate();
    const [recruitmentHead, setRecruitmentHead] = useState("");
    const [instituteName, setInstituteName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [address, setAddress] = useState("");
    const [instituteLogo, setInstituteLogo] = useState();
    const [loading, setLoading] = useState(false);
    const [fetchingInstituteDetails, setFetchingInstituteDetails] =
        useState(false);
    const { id } = useParams();

    async function handleInstiuteDetails(instituteId) {
        setFetchingInstituteDetails(true);
        const res = await instituteDetails(instituteId);
        setFetchingInstituteDetails(false);
        if (res[0] !== null) {
            setRecruitmentHead(res[0].recruitmentHead);
            setInstituteName(res[0].instituteName);
            setEmail(res[0].email);
            setCity(res[0].city);
            setState(res[0].state);
            setCountry(res[0].country);
            setPinCode(res[0].pinCode);
            setAddress(res[0].address);
            setPhoneNumber(res[0].phoneNumber);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }

    useEffect(() => {
        if (id) {
            handleInstiuteDetails(id);
        }
    }, [id]);

    /**
     *
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function handleSave(e) {
        e.preventDefault();
        setLoading(true);
        const res = await createInstitute({
            instituteName,
            recruitmentHead,
            phoneNumber,
            city,
            state,
            country,
            pinCode,
            email,
            address,
            instituteLogo,
        });
        setLoading(false);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            message.success(res.success);
            navigate(-1);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    /**
     *
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function handleUpdate(e) {
        e.preventDefault();
        setLoading(true);
        const res = await updateInstituteDetails({
            instituteLogo,
            id,
            instituteName,
            recruitmentHead,
            phoneNumber,
            city,
            state,
            country,
            address,
            pinCode,
        });
        setLoading(false);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            message.success(res.success);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    if (fetchingInstituteDetails) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress size={"4rem"} />
            </Box>
        );
    }

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>
                <Button variant="contained" onClick={() => navigate(-1)}>
                    <KeyboardBackspace
                        fontSize="small"
                        sx={{ marginRight: "10px" }}
                    />
                    Return
                </Button>
                <form
                    action="#"
                    style={{ marginTop: "3rem" }}
                    onSubmit={!id ? handleSave : handleUpdate}
                >
                    <Grid container spacing={4}>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={instituteName}
                                onChange={e => setInstituteName(e.target.value)}
                                label="Institute name"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={recruitmentHead}
                                onChange={e =>
                                    setRecruitmentHead(e.target.value)
                                }
                                label="Recruitment head"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                                label="Phone number"
                                variant="outlined"
                                fullWidth
                                type="number"
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                label="Email"
                                variant="outlined"
                                fullWidth
                                type="email"
                                required
                                disabled={id ? true : false}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                label="City"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={state}
                                onChange={e => setState(e.target.value)}
                                label="State"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                                label="Country"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={pinCode}
                                onChange={e => setPinCode(e.target.value)}
                                label="Pin code"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={address}
                                onChange={e => setAddress(e.target.value)}
                                label="Address"
                                variant="outlined"
                                fullWidth
                                required
                                multiline
                                rows={5}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={e =>
                                    setInstituteLogo(e.target.files[0])
                                }
                                label="Institute logo"
                                variant="outlined"
                                fullWidth
                                type="file"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display={"flex"} justifyContent={"flex-end"}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={loading}
                                    sx={{ width: "100px" }}
                                >
                                    {loading ? (
                                        <CircularProgress size="1.6rem" />
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Box>
    );
}
