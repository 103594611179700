import React, { useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import pdfImage from "../png-transparent-pdf-computer-icons-pdf-miscellaneous-blue-angle-removebg-preview 1 (1).png"
import TnC from "../1313041.png";
import Policy from "../6598627.png"
import Button from "@mui/material/Button";
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Cookies from "universal-cookie";

const cookies = new Cookies();

const GeneralInfo = () => {

    const navigate = useNavigate();

    const [businessName, setBusinessName] = useState("");
    const [tagLine, setTagLine] = useState("");
    const [logo, setLogo] = useState([]);
    const [userImage, setUserImage] = useState("");
    const [terms, setTerms] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState("");

    const getBusinessDetails = () => {
        axios
            .get(
                `hri_admin/business-detail-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                setBusinessName(res.data[0].name);
                setTagLine(res.data[0].tag_line);
                setLogo(res.data[0].logo);
                setUserImage(res.data[0].user_image);
                setTerms(res.data[0].terms);
                setPrivacyPolicy(res.data[0].privacy_policy);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const postGeneralInfo = () => {

        let formField = new FormData();

        formField.append("name", businessName);
        formField.append("tag_line", tagLine);
        formField.append("logo", logo);
        formField.append("user_image", userImage);
        formField.append("terms", terms);
        formField.append("privacy_policy", privacyPolicy);

        axios
            .post(
                `hri_admin/business-detail-gen-info-create-update`,
                formField,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                console.log("Contact Info updated", res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log("Error updating the Contact Info data", err);
            })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: "auto",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 5,
        borderRadius: "10px",
    };

    const [openTerms, setOpenTerms] = React.useState(false);
    const handleOpenTerms = () => setOpenTerms(true);
    const handleCloseTerms = () => setOpenTerms(false);


    const [openPrivacy, setOpenPrivacy] = React.useState(false);
    const handleOpenPrivacy = () => setOpenPrivacy(true);
    const handleClosePrivacy = () => setOpenPrivacy(false);

    useEffect(() => {
        getBusinessDetails();
    }, []);

    return (
        <div className="py-4 px-10 space-y-4">
            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    Name of the Business
                </p>

                <TextField
                    label="Business Name"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                />
            </div>

            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    Tag Line of the Business
                </p>

                <TextField
                    multiline
                    fullWidth
                    minRows={3}
                    value={tagLine}
                    label="Tag Line of the Business"
                    onChange={(e) => setTagLine(e.target.value)}
                />
            </div>

            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    Logo Of the Business
                </p>

                <div className="bg-[#F1F1F1] rounded-lg md:w-2/12 space-y-3">
                    <div className="flex justify-center">
                        <Button component="label">
                            <img src={logo} className="h-20 w-20" alt="" />
                            <input
                                hidden
                                accept="*"
                                type="file"
                                onChange={(e) => setLogo(e.target.files[0])}
                            />
                        </Button>

                    </div>
                    <p className="text-sm text-center text-[#157EFB]">
                        {logo?.slice(0, 5) === "https"
                            ? logo.substring((logo.lastIndexOf("/") + 1), (logo.indexOf('?')))
                            : "please select a file" ||
                                logo?.slice(0, 5) !== "https"
                                ? logo?.name
                                : "please select a file"}
                    </p>
                </div>
            </div>

            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    Promotional Images of your Business
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 space-y-4 md:space-y-0">
                    <div className="flex-grow">
                        <div className="bg-[#F1F1F1] rounded-lg md:w-4/12 space-y-3">
                            <div className="flex justify-center">
                                <Button component="label">
                                    <img src={userImage} className="w-auto h-20" alt="" />
                                    <input
                                        hidden
                                        accept="*"
                                        type="file"
                                        onChange={(e) => setUserImage(e.target.files[0])}
                                    />
                                </Button>
                            </div>
                            <p className="text-sm text-center text-[#157EFB]">
                                {userImage?.slice(0, 5) === "https"
                                    ? userImage.substring((userImage.lastIndexOf("/") + 1), (userImage.indexOf('?')))
                                    : "please select a file" ||
                                        userImage?.slice(0, 5) !== "https"
                                        ? userImage?.name
                                        : "please select a file"}
                            </p>
                        </div>
                    </div>

                    <TextField
                        fullWidth
                        label="Or place the a url"
                    />
                </div>
            </div>

            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    Terms and condition
                </p>

                <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                    <div className="bg-[#F1F1F1] pb-2 rounded-lg md:w-4/12 space-y-3 md:col-span-6">
                        <div className="flex justify-center">
                            <Button onClick={handleOpenTerms}>
                                <img src={TnC} className="p-2 h-20" alt="" />
                            </Button>

                        </div>
                        <p className="text-sm text-center text-[#157EFB]">
                            Terms and Condition
                        </p>
                        <Button variant="outlined" fullWidth className=""><a href={terms} target="_blank">open in new tab</a></Button>
                        <Modal
                            open={openTerms}
                            onClose={handleCloseTerms}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box
                                // sx={style}
                                className="md:w-[50%] w-full m-auto px-2 py-4 rounded-lg bg-slate-50"
                            >
                                <div className="flex">
                                    <Typography className="flex-grow" id="modal-modal-title" variant="h6"
                                        component="h2">
                                        Terms And Conditions
                                    </Typography>
                                    <IconButton onClick={handleCloseTerms}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </div>
                                <iframe src={terms} className="h-[30rem] w-full" title='...'>

                                </iframe>

                                <Button variant="contained" component="label" className="mt-3">
                                    Select New T&C
                                    <input
                                        hidden
                                        accept="*"
                                        type="file"
                                        onChange={(e) => setTerms(e.target.files[0])}
                                    />
                                </Button>
                            </Box>
                        </Modal>
                    </div>


                    <div className="bg-[#F1F1F1] rounded-lg md:w-4/12 space-y-3 md:col-span-6">
                        <div className="flex justify-center">
                            <Button onClick={handleOpenPrivacy}>
                                <img src={Policy} className="p-2 h-20 " alt="" />
                            </Button>
                        </div>
                        <p className="text-sm text-center text-[#157EFB]">
                            Privacy Policy
                        </p>
                        <Button variant="outlined" fullWidth className="my-2"><a href={privacyPolicy} target="_blank">open in new tab</a></Button>

                        <Modal
                            open={openPrivacy}
                            onClose={handleClosePrivacy}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box
                                // sx={style}
                                className="md:w-[50%] w-full m-auto px-2 py-4 rounded-lg bg-slate-50"
                            >
                                <div className="flex">
                                    <Typography className="flex-grow" id="modal-modal-title" variant="h6"
                                        component="h2">
                                        Privacy Policy
                                    </Typography>
                                    <IconButton onClick={handleClosePrivacy}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </div>

                                <iframe src={privacyPolicy} className="h-[30rem] w-full" title='...'>

                                </iframe>
                                <Button variant="contained" component="label" className="mt-3">
                                    Select New Privacy Policy
                                    <input
                                        hidden
                                        accept="*"
                                        type="file"
                                        onChange={(e) => setPrivacyPolicy(e.target.files[0])}
                                    />
                                </Button>
                            </Box>
                        </Modal>

                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-5">
                <Button
                    variant="contained"
                    startIcon={<DoneAllRoundedIcon />}
                    style={{ color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold" }}
                    className="my-2 px-4"
                    onClick={postGeneralInfo}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    startIcon={<CloseRoundedIcon />}
                    style={{ color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold" }}
                    className="my-2 px-4"
                >
                    Cancel
                </Button>
            </div>

        </div>
    )
}

export default GeneralInfo;