import { isValidSalary, isValidSkills } from "../utils/validation";

export function isValidJob(data) {
    if (!data.position_name) return "Please add a position name";
    if (!data.experience) return "Please select an experience";
    if (!data.employment_type) return "Please select an employment type";
    if (!data.location) return "Please select country state and city";
    // if (!data.stipend || isValidSalary(data.stipend) === false)
    //     return "Please add a valid stipend";
    if (!data.role_sub_category) return "Please select roles";
    // if (!data.skills || isValidSkills(data.skills) === false)
    //     return "Skills should not be empty and should be seperated with one comma";
    if (!data.job_description) return "Please add job description";

    return null;
}