import React from "react";
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ApplicationDetails from "./Pages/ApplicationDetails/ApplicationDetails";
import ApplicationList from "./Pages/ApplicationDetails/Applications";
import UserApplicationDetails from "./Pages/Applications/UserApplicationDetails";
import Broadcast from "./Pages/Broadcast Message/Broadcast";
import BroadcastMessage from "./Pages/Broadcast Message/BroadcastMessage";
import SuccessBroadcast from "./Pages/Broadcast Message/SuccessBroadcast";
import Calendar from "./Pages/Calendar/Calendar";
import Companies from "./Pages/Company/Companies";
import CompanyAdd from "./Pages/Company/CompanyAdd";
import CompanyDetails from "./Pages/Company/CompanyDetails";
import CompanyEditDetails from "./Pages/Company/CompanyEditDetails";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import AddBill from "./Pages/Financial/AddBill";
import BillDetails from "./Pages/Financial/BillDetails";
import FinanceHeader from "./Pages/Financial/FinanceHeader";
import ManualPayout from "./Pages/Financial/ManualPayout";
import PaymentDetailsDetails from "./Pages/Financial/PaymentDetailsDetails";
import PayoutDetails from "./Pages/Financial/PayoutDetails";
import BillPayout from "./Pages/Financial/billPayout";
import Help from "./Pages/Help and FAQ/Help";
import HelpAndFAQ from "./Pages/Help and FAQ/HelpAndFAQ";
import Home from "./Pages/Home/Dashboard";
import Interview from "./Pages/Interview/Interview";
import InterviewReview from "./Pages/Interview/InterviewReview";
import NewInterview from "./Pages/Interview/NewInterview";
import ViewDetail from "./Pages/Interview/ViewDetail";
import ActiveJob from "./Pages/Jobs Posted/ActiveJob";
import AddNewPosition from "./Pages/Jobs Posted/AddNewPosition";
import EditJobDetails from "./Pages/Jobs Posted/EditJobDetails";
import Jobs from "./Pages/Jobs Posted/Jobs";
import Login from "./Pages/Login/Login";
import Message from "./Pages/Message/Message";
import Messages from "./Pages/Message/Messages.jsx";
import SendMessage from "./Pages/Message/SendMessage";
import SendReply from "./Pages/Message/SendReply";
import PaymentAddForm from "./Pages/PaymentAddForm";
import ProfileDetails from "./Pages/Profile Shared by Me/ProfileDetails";
import ProfileShared from "./Pages/Profile Shared by Me/ProfileShared";
import NewVerify from "./Pages/Profile Verification/NewVerify";
import ProfileVerify from "./Pages/Profile Verification/ProfileVerify.js";
import VerifyDetail from "./Pages/Profile Verification/VerifyDetail";
import EditProfile from "./Pages/Profile/EditProfile";
import Profile from "./Pages/Profile/Profile";
import Survey from "./Pages/Servey/Survey";
import SurveyForm from "./Pages/Servey/SurveyForm";
import JobList from "./Pages/Settings/Promotion/JobList";
import Settings from "./Pages/Settings/Settings";
import AddNewMember from "./Pages/Team Manage/AddNewMember";
import TeamManage from "./Pages/Team Manage/TeamManage";
import SendReplyUser from "./Pages/User Management/SendReplyUser";
import UserDetails from "./Pages/User Management/UserDetails";
import UserManagement from "./Pages/User Management/UserManagement";
import BroadCastMessages from "./Pages/broadcast/BroadCastMessages";
import BroadcastMessageForm from "./Pages/broadcast/BroadcastMessageForm";
import Institute from "./Pages/institute/Institute";
import InstituteForm from "./Pages/institute/InstituteForm";
import InstituteStudents from "./Pages/institute/InstituteStudents";
import AddNewJob from "./Pages/jobs/AddNewJob";
import ApplicantServeyResult from "./Pages/jobs/Jobdetails/ApplicantSurveyResult";
import JobDescription from "./Pages/jobs/Jobdetails/JobDescription";
import Webinar from "./Pages/webinar/Webinar";
import WebinarForm from "./Pages/webinar/WebinarForm";
import Fresher from "./Pages/Profile Verification/Fresher.js";

function App() {
    return (
        <div className="App z-40">
            <Router>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Navigate replace to="/login" />}
                    />
                    <Route path="/login/*" element={<Login />} />
                    <Route path="/dashboard/*" element={<Home />} />
                    <Route
                        path="/application-list"
                        element={<ApplicationList />}
                    />
                    <Route path="/applications" element={<ApplicationList />} />
                    <Route
                        exact
                        path="/applications-details/:id"
                        element={<ApplicationDetails />}
                    />
                    <Route
                        exact
                        path="/user-detail/:id"
                        element={<UserApplicationDetails />}
                    />
                    <Route exact path="/add-job" element={<AddNewJob />} />

                    <Route exact path="/add-job/:id" element={<AddNewJob />} />

                    <Route path="/posted-jobs/*" element={<Jobs />} />
                    <Route
                        path="/posted-jobs/add-new-position/*"
                        element={<AddNewPosition />}
                    />
                    <Route
                        path="/posted-jobs/job-details/:id"
                        element={<JobDescription />}
                    />
                    <Route
                        path="/posted-jobs/active-job/:id"
                        element={<ActiveJob />}
                    />
                    <Route
                        path="/posted-jobs/job-details/edit/*"
                        element={<EditJobDetails />}
                    />

                    <Route path="/broadcast/*" element={<Broadcast />} />
                    <Route
                        path="/broadcast-list"
                        element={<BroadCastMessages />}
                    />
                    <Route
                        path="/broadcast-form"
                        element={<BroadcastMessageForm />}
                    />
                    <Route
                        path="/broadcast-form/:id"
                        element={<BroadcastMessageForm />}
                    />

                    <Route
                        exact
                        path="/broadcast/message-success"
                        element={<SuccessBroadcast />}
                    />
                    <Route exact path="/survey" element={<Survey />} />
                    <Route exact path="/survey-form" element={<SurveyForm />} />
                    <Route
                        exact
                        path="/survey-form/:id"
                        element={<SurveyForm />}
                    />
                    <Route exact path="/survey-result/:jobId/:userId" element={<ApplicantServeyResult />} />
                    <Route
                        path="/broadcast/message"
                        element={<BroadcastMessage />}
                    />

                    <Route exact path="/calendar" element={<Calendar />} />
                    <Route exact path="/institute" element={<Institute />} />
                    <Route
                        exact
                        path="/institute-students/:id"
                        element={<InstituteStudents />}
                    />

                    <Route
                        exact
                        path="/user-management/*"
                        element={<UserManagement />}
                    />
                    <Route
                        exact
                        path="/institute-form"
                        element={<InstituteForm />}
                    />
                    <Route
                        exact
                        path="/institute-form/:id"
                        element={<InstituteForm />}
                    />
                    <Route
                        exact
                        path="/user-management/unregistered-user/details/*"
                        element={<UserDetails />}
                    />

                    <Route path="/team-manage/*" element={<TeamManage />} />
                    <Route
                        path="/team-manage/add-new-member"
                        element={<AddNewMember />}
                    />

                    <Route path="/profile-shared" element={<ProfileShared />} />
                    <Route
                        path="/profile-shared/profile-details"
                        element={<ProfileDetails />}
                    />

                    <Route path="/companies" element={<Companies />} />
                    <Route
                        path="/companies/companies-details/:id"
                        element={<CompanyDetails />}
                    />
                    <Route
                        path="/companies/companies-details/add/*"
                        element={<CompanyAdd />}
                    />
                    <Route
                        path="/companies/companies-details/edit/*"
                        element={<CompanyEditDetails />}
                    />

                    <Route
                        path="/Financials/:id/payout"
                        element={<BillPayout />}
                    />
                    <Route
                        path="/Financials/bill/payout"
                        element={<ManualPayout />}
                    />
                    <Route
                        exact
                        path="/Financials/*"
                        element={<FinanceHeader />}
                    />
                    <Route
                        exact
                        path="/BillDetails"
                        element={<BillDetails />}
                    />
                    <Route exact path="/AddBill" element={<AddBill />} />
                    <Route
                        exact
                        path="/PayoutDetails"
                        element={<PayoutDetails />}
                    />
                    <Route
                        exact
                        path="/AddPaymentDetails"
                        element={<PaymentAddForm />}
                    />
                    <Route
                        exact
                        path="/PaymentDetailsDetails"
                        element={<PaymentDetailsDetails />}
                    />

                    <Route
                        path="/profileVerification/*"
                        element={<ProfileVerify />}
                    />
                    <Route
                        path="/ProfileVerification/NewVerification"
                        element={<NewVerify />}
                    />
                    <Route
                        path="/ProfileVerification/ViewDetails/:id"
                        element={<VerifyDetail />}
                    />
                    <Route
                        path="/ProfileVerification/*"
                        element={<Navigate replace to="/ProfileVerification" />}
                    />

                    <Route exact path="/message" element={<Message />} />
                    <Route path="/messages/:messageType" element={<Messages />} />
                    <Route
                        exact
                        path="/send-message"
                        element={<SendMessage />}
                    />
                    <Route exact path="/send-reply" element={<SendReply />} />
                    <Route
                        exact
                        path="/send-reply-user"
                        element={<SendReplyUser />}
                    />
                    <Route exact element={<Webinar />} path="/webinar" />
                    <Route
                        exact
                        element={<WebinarForm />}
                        path="/webinar/form"
                    />
                    <Route
                        exact
                        element={<WebinarForm />}
                        path="/webinar/form/:id"
                    />

                    {/*<Route path="/schedule-meet/*" element={<Meet/>}/>*/}
                    <Route
                        exact
                        path="/AddNewPosition"
                        element={<AddNewPosition />}
                    />

                    <Route path="/interview/:interviewType" element={<Interview />} />
                    
                    <Route
                        path="/interview/add-new"
                        element={<NewInterview />}
                    />
                    <Route
                        path="/interview/review"
                        element={<InterviewReview />}
                    />
                    <Route
                        path="/interview/view-details/:id"
                        element={<ViewDetail />}
                    />

                    <Route path="/profile" element={<Profile />} />
                    <Route path="/profile/edit" element={<EditProfile />} />
                    <Route path="/settings/*" element={<Settings />} />
                    <Route
                        path={"/settings/promotion/joblist"}
                        element={<JobList />}
                    />
                    <Route path="/help" element={<Help />} />
                    <Route path="/help/*" element={<HelpAndFAQ />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </Router>
            {/* <ToastContainer /> */}
        </div>
    );
}

export default App;
