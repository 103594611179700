import React from "react";

const DetailsInput = ({ title, span, placeholder, textarea, value, onChange }) => {
  return (
    <div className={span}>
      <div>
        <p className="text-gray-400 font-semibold text-lg">{title}</p>
        <div className={`flex items-start space-x-1`}>
          {textarea ? (
            <textarea
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              className="border-[1px] border-black px-2 py-2 rounded-sm outline-none w-full"
            />
          ) : (
            <input
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              className="border-[1px] border-black px-2 py-2 rounded-sm outline-none w-full"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsInput;
