export const BackendAPI = {
    Webinar: {
        CreateWebinar: "/hri_admin/webinar-create",
        WebinarList: "/hri_admin/webinar-list",
        WebinarDetail: "/hri_admin/webinar-detail",
        UpdateWebinar: "/hri_admin/webinar-update",
    },
    institute: {
        instituteList: "/hri_admin/institute-info-list",
        createInstitute: "/hri_admin/institute-info-create",
        instituteDetails: "/hri_admin/institute-info-detail",
        instituteInfoUpdate: "/hri_admin/institute-info-update",
        studentList: "/hri_admin/institute-user-list",
    },
    userApplication: {
        applicationDetail: "/hri_admin/user-detail",
        matchingApplicantList: "/hri_admin/profile-recommendation"
    },

    student: {
        studentList: "/hri_admin/username",
    },
    meeting: {
        createMeeting: "/hri_admin/multi-meeting/create"
    },
    company: {
        companyMailList: "/hri_admin/companyemail",
        companyList: "/hri_admin/company"
    },
    education: {
        educationList: "/hri_admin/education-list"
    },
    jobRole: {
        roleCategory: "/hri_admin/roles",
        roleSubCategory: "/hri_admin/sub_roles"
    },
    job: {
        createJob: "/hri_admin/newposition/add",
        jobDetails: "/hri_team/job-detail-open",
        openJobDetail: "/hri_team/job-detail-open",
        jobUpdate: "/hri_admin/newposition/update",
        applicantList: "`/hri_admin/applicant-list", // applicant list based on job
        removeServeyQuestion: "/survey/question-delete",
    },
    applicant: {
        applicantList: "/hri_admin/application",  // all applicat list
        applicantStatusChange: "/hri_admin/application/status-change",
        shareApplication: "/hri_admin/profileshare/send",
        applicationFilterJobList: "/hri_admin/application-filter-data",
        applicationFilterList: "/hri_admin/application-filter"
    },
    specialization: {
        specializationList: "/hri_admin/specialization-list"
    },
    team: {
        teamMailList: "/hri_admin/teamemail"
    },
    survey: {
        createSurvey: "/survey/form-create",
        surveyList: "/survey/admin-form-list",
        surveyDetails: "/survey/assignment-detail",
        surveyUpdate: "/survey/form-update",
        surveyResult: "/survey/answer-list"
    },
    broadcastMessage: {
        messageList: "/hri_admin/broadcast-list",
        messageDetails: "/hri_admin/broadcast-detail",
        deleteMessage: "/hri_admin/broadcast-delete",
        changeStatus: "/hri_admin/broadcast-status-change",
        createMessage: "/hri_admin/broadcast/create",
        updateBroadcastMessage: "/hri_admin/broadcast-update"
    }
};
