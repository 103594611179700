import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import { Box, Pagination } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import noMessage from "../../Images/empty_states/no-bradcastmessage.png";
import { EmptyState } from "../../components/EmptyStates/EmptyState";
import Spinner from "../../components/Spinner";

const cookies = new Cookies();

const All = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const [toDate, setToDate] = useState("3000-1-1");
    const [fromDate, setFromDate] = useState("1900-1-1");
    const [totalPages, setTotalPages] = useState(1)

    const from = new Date(fromDate);
    const to = new Date(toDate);

    const getData = (page = 1) => {
        axios
            .get(
                `hri_admin/broadcast-list?broadcast_type=All`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                    params: {
                        page,
                    }
                }
            )
            .then((res) => {
                setData(res.data.results);
                setTotalPages(Math.ceil(res.data.count / 10));
                setLoading(false)
            })
            .catch((err) => {
                console.log("Error occurred while fetching Broadcast All message data", err);
            })
    };

    useEffect(() => {
        getData();
    }, [])

    console.log(data, "data")


    return (
        <div>
            {data.length ?
                (
                    <div className='overflow-auto mx-2 mt-4 rounded-lg shadow'>
                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                                <tr className='text-blue-700'>
                                    <th className="w-20 p-3 pl-10 text-lg font-semibold tracking-wide text-center">
                                        Sender
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Broadcast Message
                                    </th>
                                    <th className="w-10 p-3 text-lg font-semibold tracking-wide text-center">
                                        Date
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Details
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-100 text-center">
                                {data.map((user) => {
                                    cookies.set("id", user.id)
                                    const date = new Date(user.updated_at.slice(0, 10))
                                    const month = date.toLocaleString('default', { month: 'long' });
                                    if (from <= date && date <= to) {
                                        return (
                                            <tr

                                                key={user.id}
                                                // onClick={() => getMessage(user.id)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalCenter"
                                                className="bg-white hover:bg-slate-100"

                                            >
                                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                                    {user.sender_email}
                                                    {cookies.set("senderName", user.sender_name)}

                                                </td>
                                                <td className="p-3 MessageLine text-base text-gray-700 whitespace-nowrap">

                                                    <div className='font-semibold  text-lg'>{user.header}</div>

                                                </td>
                                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                                    {date.getDate()}&nbsp;
                                                    {month.slice(0, 3)}&nbsp;
                                                    {date.getFullYear()}
                                                </td>
                                                <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                    <div
                                                        className='bg-slate-100 cursor-pointer py-2 rounded-lg text-blue-600'
                                                        onClick={() => {
                                                            navigate("/broadcast/details");
                                                            localStorage.setItem("BroadcastID", user.id)
                                                        }}
                                                    >
                                                        View Broadcast
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                        <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                            marginBottom={"0.3rem"}
                            mt={"1rem"}
                        >
                            <Pagination
                                count={totalPages}
                                color="primary"
                                shape="rounded"
                                onChange={(e, page) => {
                                    getData(page);
                                }}
                            />
                        </Box>
                    </div>
                ) : (
                    <div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div className="mt-11">
                                <EmptyState
                                    img={noMessage}
                                    title="No broadcast Messages"
                                    message="There are currently no active broadcast messages to display. Stay tuned for future announcements and updates"
                                    btn="Home"
                                    btnLink="/dashboard"
                                />
                            </div>
                        )}
                    </div>
                )}

        </div>
    )
}

export default All;