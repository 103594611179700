import {
    isValidEmail,
    isValidPhoneNumber,
    isValidPin,
} from "../utils/validation";

export function isValidCompany(data) {
    if (!data.name) return "Please add a company name";
    if (!data.company_number) return "Please add a valid company phone number";
    if (!data.company_email || isValidEmail(data.company_email) === false)
        return "Please add a valid email";
    if (!data.country || !data.state || !data.city)
        return "Please add a country, state and city";
    if (!data.pin || isValidPin(data.pin) === false)
        return "Please add a valid pin";
    if (!data.established_in) return "Please select a Established in date";
}
