import React from 'react'
import { useState } from 'react';
import HashLoader from 'react-spinners/HashLoader';


const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const Spinner = ({ loading }) => {

  const [color, setColor] = useState('#2563EB');
  // const [loading, setLoading] = useState(true)
  return (
    <>
      {
        loading ? <div className='flex items-center h-[80vh] justify-center'>
          <div>
            <HashLoader
              color={color}
              // loading={loading || loading}
              cssOverride={override}
              size={100}
            />
          </div>
        </div> : <div classname="font-semibold text-2xl h-[60vh] justify-center items-center">
                No data to Show
                </div>
      }


    </>

  )
}

export default Spinner