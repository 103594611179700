import { FormControlLabel, Switch } from "@mui/material";
import axios from "axios";
import React , { useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
const cookies = new Cookies()

export default function JobStatus ({jobId , activeState , landingStatus }) {
    const [isActive , setIsActive] = useState(activeState)

    const change_Active = () => {
        axios
          .get(`hri_admin/job-landing-status/${jobId}`, {
            headers: {
              Authorization: "Token " + cookies.get("token"),
            },
          })
          .then((res) => {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .catch((err) => {
            toast.error(err.message, {
              // position: toast.POSITION.TOP_CENTER,
              style: { backgroundColor: "#FF6347", color: "white" },
              icon: false,
              progressStyle: { backgroundColor: "#FFB1A3" },
            });
          });
      };
    
    return (
            <FormControlLabel
                control={
                <Switch
                    defaultChecked={landingStatus !== null
                                  ? landingStatus
                                  : false}
                    onChange={(e) => {
                    setIsActive(!activeState);
                    change_Active();
                    }}
                />
                }
                label=""
                />
    )
}