import { createSlice } from "@reduxjs/toolkit";


/**
 * @typedef {object} HeaderData
 * @property {boolean} isEmpty
 * @property {string} logo
 * @property {string} name
 * @property {number} unread_msg_count
 * @property {string} user_image
 * @property {string} user_name
 */



/**
 * @type {HeaderData}
 */
const initialState = {
    isEmpty: true,
    logo: "",
    name: "",
    unread_msg_count: 0,
    user_image: "",
    user_name: ""
}

const headerDataSlice = createSlice({
    name: "headerData",
    initialState,
    reducers: {
        /**
         * 
         * @param {HeaderData} state 
         * @param {{payload:HeaderData}} action 
         */
        setHeaderData: (state, action) => {
            state.isEmpty = false
            state.logo = action.payload.logo;
            state.name = action.payload.name;
            state.unread_msg_count = action.payload.unread_msg_count;
            state.user_image = action.payload.user_image;
            state.user_name = action.payload.user_name
        },
        decrementUnreadMessage: (state) => {
            state.unread_msg_count--;
        }
    }
})

export default headerDataSlice.reducer;
export const { setHeaderData, decrementUnreadMessage } = headerDataSlice.actions;