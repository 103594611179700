/**
 * @typedef {object} InstituteModel
 * @property {number} id
 * @property {string} email
 * @property {string} instituteName
 * @property {string} recruitmentHead
 * @property {string} phoneNumber
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} address
 * @property {string} pinCode
 * @property {string} insituteLogoUrl
 */

export function createInstituteObj(data) {
    /**
     * @type {InstituteModel}
     */
    const result = {
        id: data.id,
        email: data.personal_details.email,
        instituteName: data.institute_name,
        recruitmentHead: data.personal_details.name,
        phoneNumber: data.personal_details.phone_number,
        city: data.personal_details.city,
        state: data.personal_details.state,
        country: data.personal_details.country,
        address: data.personal_details.add,
        pinCode: data.personal_details.pin,
        insituteLogoUrl: data.institute_logo
    };
    return result;
}

