import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Container, IconButton, Modal, Pagination, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import noRegistered from "../../Images/empty_states/no-registered.png";
import { EmptyState } from "../../components/EmptyStates/EmptyState";
import Spinner from "../../components/Spinner";
import { Bookmark } from "./Bookmark";
import DetailsModal from "./DetailsModal";

const cookies = new Cookies();

const IndividualUser = ({ searchedText }) => {
  
  const [data, setData] = useState([]);
  const [search, setSearch] = useState({ state: false, data });
  const [status, setStatus] = useState("");
  const [resume, setResume] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [detailsData, setDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleDetails, setIsModalVisibleDetails] = useState(false);

  const defaultLoaders = { pause: false, block: false, active: false };
  const [loaders, setLoaders] = useState(defaultLoaders);

  const [totalPages, setTotalPages] = useState(1);
  const [countPage, setCountPage] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisibleDetails(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelDetails = () => {
    setIsModalVisibleDetails(false);
  };

  async function searchText() {
    setLoading(true);
    getData(searchedText);
    setLoading(false);
  }

  useEffect(() => {
    let timeOut = 0;

    timeOut = setTimeout(() => {
      searchText();
    }, [700]);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchedText]);

  async function getData(searchedText = "", page = 1) {
    await axios
      .get(`/hri_admin/registered-user-list?keyword=${searchedText}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        },
      })
      .then((resp) => {
        setData(resp.data.results);
        setLoading(false);
        setTotalPages(Math.ceil(resp.data.count / 10));
        setCountPage(resp.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getDetails = (id) => {
    axios
      .get(`hri_admin/application-user/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setIsModalVisibleDetails(true);
        setDetailsData(res.data);
      })
      .catch((err) => {
        console.log("Error Automating Resume", err);
      });
  };

  async function blockUser(id, fname, lname) {
    try {
      setLoaders((p) => ({ ...p, block: id }));

      const response = await axios.get(
        `hri_admin/user-block-status-change/${id}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      );

      setStatus(response.data);
      toast.error(`User ${fname} ${lname} - has been blocked`);

      getData("");
    } catch (err) {
      toast.error("Opps! Can't block user");
      throw new Error("Unable to block user", err);
    } finally {
      setLoaders(defaultLoaders);
    }
  }

  async function pauseUser(id, fname, lname, activate) {
    try {
      if (activate) setLoaders((p) => ({ ...p, active: id }));
      else setLoaders((p) => ({ ...p, pause: id }));

      const response = await axios.get(
        `hri_admin/user-pause-status-change/${id}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      );

      setStatus(response.data);

      if (activate)
        toast.success(`Account ${fname} ${lname} - is activated`);
      else toast.warn(`Account ${fname} ${lname} - is paused`);

      getData("");
    } catch (err) {
      setLoaders(defaultLoaders);

      if (activate) toast.error(`Opps! Unable to activate account`);
      else toast.error("Opps! Can't pause account");

      throw new Error("Unable to pause/activate account", err);
    } finally {
      setLoaders(defaultLoaders);
    }
  }

  useEffect(() => {
    getData();
  }, []);
 
  const dynamicData = search.state === true ? search.data : data;

  return (
    <Container maxWidth="lg" sx={{ margin: "3rem auto" }}>
      {data.length > 0 && countPage && (
        <Typography variant="h6" gutterBottom>
          Total <Typography variant="h6" color="primary" component="span">{countPage}</Typography> Registered User found
        </Typography>
      )}
      {data.length > 0 ? (
        <div>
            <TableContainer component={Paper} elevation={2} sx={{ mt: 4, mb: 10 }}>
                <Table sx={{ width: "100%"}}>
                <TableHead sx={{ backgroundColor: blue[700] }}>
                    <TableRow>
                        <TableCell sx={{ color: "white" }}>
                            Applicants
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            Resume
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            Contact
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            Account Actions
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            Status
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            <FilterAltIcon />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
              {dynamicData?.map((data) => (
                <TableRow key={data.id}>
                  <TableCell>
                    <Link to={`/applications-details/${data.user_id}`} style={{ color: "#1d1d1d", textDecoration: 'none' }}>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Avatar src={data.profile_pic} alt="" />
                        <Typography>{data.first_name} {data.last_name}</Typography>
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={2}>
                      <IconButton onClick={() => {
                        setResume(data.resume);
                        setResumeName(data.resume.slice(0, 10));
                        showModal();
                      }}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                      <Typography variant="body2" marginTop={1}>
                        {data.resume ? data.resume.slice(54, 68) : "No Resume"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={2}>
                      <Tooltip title={data.mobile_no}>
                        <IconButton>
                          <PhoneIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={data.email}>
                        <IconButton>
                          <MailOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {data.status !== "Pause" && data.status !== "Block" ? (
                      <Box display="flex" justifyContent="center" gap={2}>
                        <LoadingButton
                          variant="contained"
                          loading={loaders.pause === data.user}
                          onClick={() =>
                            pauseUser(
                              data.user,
                              data.first_name,
                              data.last_name,
                              false
                            )
                          }
                          sx={{ backgroundColor: "#C7AC23", fontSize: 11 }}
                        >
                          Pause Account
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          loading={loaders.block === data.user}
                          onClick={() =>
                            blockUser(
                              data.user,
                              data.first_name,
                              data.last_name
                            )
                          }
                          sx={{ backgroundColor: "#ED4A4A", fontSize: 11 }}
                        >
                          Block Account
                        </LoadingButton>
                      </Box>
                    ) : (
                      <LoadingButton
                        variant="contained"
                        loading={loaders.active === data.user}
                        onClick={() =>
                          pauseUser(
                            data.user,
                            data.first_name,
                            data.last_name,
                            true
                          )
                        }
                        sx={{ backgroundColor: "#80AB49", fontSize: 11 }}
                      >
                        Activate Account
                      </LoadingButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                        size="small"
                        varaint="contained"
                        onClick={() => {
                            getDetails(data.user);
                            handleOk()
                        }}
                    >
                    Details
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Bookmark
                        userId={data.user_id}
                        checked={data.is_bookmarked}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
                </Table>
            </TableContainer>
            <Box mt={4} display="flex" justifyContent="center">
                <Pagination
                count={totalPages}
                onChange={(_, page) => getData("", page)}
                color="primary"
                />
            </Box>
        </div>
      ) : (
        <EmptyState image={noRegistered} text="No Registered User found" />
      )}
      
      <Modal open={isModalVisible} onClose={handleCancel}>
        <Box p={4} maxWidth={500} mx="auto" my={4} bgcolor="background.paper">
          <Typography variant="h6" gutterBottom>Resume: {resumeName}</Typography>
          <iframe src={resume} width="100%" height="500px" />
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="primary" onClick={handleCancel}>
              Close
            </Button>
            <Button variant="contained" color="secondary" onClick={() => window.open(resume)}>
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
      <DetailsModal 
        isModalVisibleDetails={isModalVisibleDetails}
        handleCancelDetails={handleCancelDetails}
        detailsData={detailsData}
      />
      {loading && <Spinner />}
    </Container>
  );
};

export default IndividualUser;
