import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { getRequest, postRequest } from "../../../utils/request";
import moment from "moment";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Button from "@mui/material/Button";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { InputLabel, TextField } from "@mui/material";
import axios from "axios";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";

import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const cookies = new Cookies();

const EditCategory = () => {
  const navigate = useNavigate();

  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [categoryList, setCategoryList] = useState("");

  const getQuestion = () => {
    axios
      .get(
        `hri_admin/help-qa-detail/${localStorage.getItem(
          "Cat-QuestionId"
        )}?help_section=${localStorage.getItem("help-type")}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )

      .then((res) => {
        console.log("Edit Category Data: ", res.data);
        setCategory(res.data.help_category);
        setQuestion(res.data.help_question);
        setAnswer(res.data.help_answer);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const getCategory = () => {
    axios
      .get(
        `hri_admin/help-category-list?help_section=${localStorage.getItem(
          "help-type"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Category List: ", res.data);
        setCategoryList(res.data);
      });
  };

  const updateQuestion = () => {
    axios
      .put(
        `hri_admin/help-qa-update/${localStorage.getItem(
          "Cat-QuestionId"
        )}?help_section=${localStorage.getItem("help-type")}`,
        {
          help_question: question,
          help_answer: answer,
          help_category: category,
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Question Updated Successfully", res.data);
        toast.success("Question Updated Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate(`/help/${localStorage.getItem("help-type")}/category`);
      })
      .catch((err) => {
        console.log("Error: ", err.response);
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  useEffect(() => {
    getCategory();
    getQuestion();
  }, []);

  return (
    <div>
      <div className="bg-white px-5 py-4 m-4 rounded-md z-10">
        <p className="text-2xl text-[#0856B6] font-bold">Edit Category</p>
        <div className="space-y-5 my-4">
          <div className="mx-4 flex">
            {/*<span className="text-default-blue font-semibold text-lg">*/}
            {/*    Choose Category*/}
            {/*</span>*/}

            <FormControl className="w-96">
              <InputLabel id="demo-simple-select-label">
                Choose Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Choose Category"
                onChange={(e) => setCategory(e.target.value)}
              >
                {categoryList.length ? (
                  categoryList.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Category</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="flex px-4 space-y-2 ">
            <TextField
              className="w-96"
              label="Question"
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>

          <div className="px-4 space-y-2">
            <TextField
              multiline
              fullWidth
              minRows={4}
              label="Answer"
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </div>

          <div className="flex items-center space-x-3 mx-4">
            <Button
              variant="contained"
              endIcon={<DoneAllRoundedIcon />}
              onClick={updateQuestion}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/help/${localStorage.getItem("help-type")}/category`)
              }
            >
              x Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
