import { Add } from "@mui/icons-material";
import {
    Box,
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "../../components/Header";
import { getRequest } from "../../utils/request";
import { blue } from "@mui/material/colors";
import { Popconfirm } from "antd";
import { BsFillDiamondFill } from "react-icons/bs";

const cookies = new Cookies();

export default function Companies() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    useEffect(() => {
        if (cookies.get("token")) {
            getRequest("hri_admin/company", setList, setLoading);
        } else {
            navigate("/");
        }
    }, [navigate]);

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "2rem auto" }}>
                <Box
                    marginBottom={"2rem"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                >
                    <Link to={"/companies/companies-details/add"}>
                        <Button variant="contained">
                            <Add />
                            Add New Company
                        </Button>
                    </Link>
                </Box>
                {list.length > 0 ? (
                    <TableContainer component={Paper} elevation={2}>
                        <Table sx={{ width: "100%" }}>
                            <TableHead sx={{ backgroundColor: blue[700] }}>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>
                                        Status
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Company Name
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Contact Person
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Details
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map(user => (
                                    <TableRow key={user.id}>
                                        <TableCell>
                                            <Popconfirm
                                                title={user.status === false ? "Sure to Activate?" : "In-Activate?"}
                                                okText={user.status === false ? "Activate" : "In-Activate"}
                                                onConfirm={() => console.log("Confirmed")}
                                            >
                                                <BsFillDiamondFill
                                                    style={{
                                                        color: user.status ? 'green' : 'red',
                                                        marginLeft: '12px',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Popconfirm>
                                        </TableCell>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.contact_person}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    navigate(`/companies/companies-details/${user.id}`);
                                                    cookies.set("CompanyId", user.id);
                                                }}
                                            >
                                                View Details
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="h5" textAlign={"center"}>
                        No message found
                    </Typography>
                )}
            </Container>
        </Box>
    );
}
