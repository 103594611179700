import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getMessages } from "../../controller/MessageController";
import Header from "../../components/Header";
import {
    Box,
    Container,
    Tabs,
    Tab,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Pagination,
    TextField,
    Fab,
    Typography,
} from "@mui/material";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { blue, grey } from "@mui/material/colors";
import { createdAtToDate } from "../../utils/utils";
import { ArrowRightAlt, Send } from "@mui/icons-material";
import SendMessageModal from "../../components/messages/SendMessageModal";
import MessageDetailModel from "../../components/messages/MessageDetailModel";
import DeleteMessage from "../../components/messages/DeleteMessage";
import { Link } from "react-router-dom";
import noDataImg from "../../Images/empty_states/no-messages.png"
/**
 * @type {import('../../controller/MessageController').MessageModel[]}
 */
const initialMessageState = [];

export default function Messages() {
    const { messageType } = useParams();
    const navigate = useNavigate();
    const messageTypes = useMemo(() => ["Received", "Unread", "Sent"], []);
    const [messageList, setMessageList] = useState(initialMessageState);
    const [totalPages, setTotalPages] = useState(0);
    const [currentTab, setCurrentTab] = useState(1);
    const [currentPage, setCurrentPage] = useState(1)
    const [startingDate, setStartingDate] = useState("");
    const [endingDate, setEndingDate] = useState("");
    const [showSendMessageModal, setShowSendMessageModal] = useState(false);
    const [showMessageDetailModal, setShowMessageDetailModal] = useState({
        visible: false,
        message: {}
    })


    const handleFetchMessage = useCallback(async (messageType, currentPage = 1, startDate = "", endDate = "") => {
        const res = await getMessages(messageType, currentPage, startDate, endDate);
        if (res[0] !== null) {
            setMessageList(res[0].result);
            setTotalPages(Math.ceil(res[0].count / 10));
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, []);

    useEffect(() => {
        handleFetchMessage(messageType);
    }, [handleFetchMessage, messageType]);

    useEffect(() => {
        if (messageType && messageTypes.includes(messageType)) {
            setCurrentTab(messageTypes.indexOf(messageType));
        }
    }, [messageType, messageTypes]);

    return (
        <div>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "5rem auto" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Tabs
                        value={currentTab}
                        onChange={(_e, value) => {
                            setCurrentPage(1)
                            navigate(`/messages/${messageTypes[value]}`)
                        }
                        }
                    >
                        <Tab label={messageTypes[0]} sx={{ textTransform: "capitalize" }} />
                        <Tab label={messageTypes[1]} sx={{ textTransform: "capitalize" }} />
                        <Tab label={messageTypes[2]} sx={{ textTransform: "capitalize" }} />
                    </Tabs>
                    <Link to="/send-message">
                        <Button variant="outlined"
                        sx={{
                            display: {
                                md: "block",
                                xs: "none"
                            }
                        }}>Send Message</Button>
                    </Link>
                    <Link to="/send-message">
                        <Fab  color="primary" sx={{
                            position: "fixed",
                            bottom: 20,
                            right: 20,
                            display: {
                                md: "none"
                            }
                        }} variant="extended">
                            <Send sx={{ transform: "rotate(-90deg)", mr: 1, fontSize: "20px" }} />
                            Send
                        </Fab>
                    </Link>
                    
                </Box>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    marginTop={"1rem"}
                >
                    <TextField
                        value={startingDate}
                        onChange={(e) => setStartingDate(e.target.value)}
                        type="date"
                        variant="standard"
                        sx={{ marginRight: "1rem" }}
                    />
                    <ArrowRightAlt sx={{ marginRight: "1rem" }} />
                    <TextField
                        value={endingDate}
                        onChange={(e) => setEndingDate(e.target.value)}
                        type="date"
                        variant="standard"
                        sx={{ marginRight: "1rem" }}
                    />
                    <Button onClick={() => {
                        handleFetchMessage(messageType, 1, startingDate, endingDate)
                        setCurrentPage(1)
                    }}>Filter</Button>
                    {startingDate !== "" || endingDate !== "" ? <Button onClick={() => {
                        setStartingDate("")
                        setEndingDate("")
                        setCurrentPage(1)
                        handleFetchMessage(messageType, 1)
                    }}>Clear</Button> : <></>}
                </Box>
                {messageList.length > 0 ? (
                    <TableContainer
                        component={Paper}
                        elevation={2}
                        sx={{ marginTop: "4rem" }}
                    >
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead sx={{ backgroundColor: blue[700] }}>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>
                                    {messageType === "Received" || messageType === "Unread" ? "Sender" : "Receiver"}
                                     </TableCell>
                                    <TableCell sx={{ color: "white" }}>Message</TableCell>
                                    <TableCell sx={{ color: "white" }}>Date</TableCell>
                                    <TableCell sx={{ color: "white" }}>Details</TableCell>
                                    <TableCell sx={{ color: "white" }}>Delete</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {messageList.map((data) => (
                                    <TableRow key={data.id}>
                                        <TableCell>
                                        {messageType === "Received" || messageType === "Unread" ? (
                                            data.sender_name ? data.sender_name : "NA"
                                        ) : (data.receiver_name ? data.receiver_name : "NA")}
                                        </TableCell>
                                        <TableCell>{data.header}</TableCell>
                                        <TableCell>{createdAtToDate(data.created_at)}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => {
                                                setShowMessageDetailModal({
                                                    visible: true,
                                                    message: data
                                                })
                                            }}>Details</Button>
                                        </TableCell>
                                        <TableCell>
                                            <DeleteMessage messageId={data.id} setMessageList={setMessageList} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
          <Box display={"flex"} justifyContent={"center"} marginTop={2}>
            <Box>
              <img
                src={noDataImg}
                alt="no webinar"
                height={"200px"}
                width={"200px"}
              />
              <Typography
                textAlign={"center"}
                margin={"1rem 0rem"}
                color={grey[600]}
                fontSize={"14px"}
              >
                No Messages Found
              </Typography>
            </Box>
          </Box>
        )}
                {messageList.length > 0 && (
                    <Box display={"flex"} justifyContent={"center"} margin={"2rem 0rem"}>
                        <Pagination
                            count={totalPages}
                            color="primary"
                            page={currentPage}

                            onChange={(_e, value) => {
                                setCurrentPage(value)
                                handleFetchMessage(messageType, value, startingDate, endingDate)
                            }}
                        />
                    </Box>
                )}
                <SendMessageModal onClose={() => setShowSendMessageModal(false)} open={showSendMessageModal} />
                <MessageDetailModel
                    message={showMessageDetailModal.message}
                    isUnreadMessage={messageType === "Unread"}
                    setMessageList={setMessageList}
                    open={showMessageDetailModal.visible}
                    onClose={() => {
                        setShowMessageDetailModal({ visible: false, message: {} });
                    }}
                />
            </Container>
        </div>
    );
}
