import axios from "axios";
import { handleException } from "../utils/handleException";
import { BackendAPI } from "../constants/backendAPI";
import Cookies from "universal-cookie";
import { HttpResponse } from "../models/HttpResponse";
import { createSurveyObj, createSurveyServerObj } from "../models/SurveyModel";
import { createSurveyResultObj } from "../models/SurveyResultModel";

const cookies = new Cookies();

export async function createSurvey(data) {
    try {
        const res = await axios.post(
            `${BackendAPI.survey.createSurvey}`,
            data,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Form created successfully");
    } catch (err) {
        return handleException(err);
    }
}

/**
 *
 * @param {"survey"| "assignment" | "interview_review"} formType
 * @param {number} page
 * @returns {Promise<[{results:import("../models/SurveyModel").SurveyModel[], count:number} | null, HttpResponse | null]>}
 */
export async function getSurveyList(formType, page = 1) {
    const result = [null, null];
    try {
        const res = await axios.get(`${BackendAPI.survey.surveyList}`, {
            headers: { Authorization: `Token ${cookies.get("token")}` },
            params: {
                survey_type: formType,
                page
            }
        });
        const surveyList = [];
        for (let x of res.data.results) {
            surveyList.push(createSurveyObj(x));
        }

        result[0] = {
            results: surveyList,
            count: res.data.count
        }
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 * 
 * @param {string | number} surveyId
 * @returns  {Promise<[import("../models/SurveyModel").SurveyModel | null, HttpResponse | null]>}
 */
export async function getSurveyDetails(surveyId) {
    const result = [null, null];
    try {
        const res = await axios.get(`${BackendAPI.survey.surveyDetails}/${surveyId}`, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        result[0] = createSurveyObj(res.data);
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result
    }
}



export async function updateSurveyData({
    id,
    name,
    description,
    form_type,
    questions
}) {
    const result = [null, null];
    try {
        const res = await axios.put(`${BackendAPI.survey.surveyUpdate}/${id}`, 
        {
            name,
            description,
            form_type,
            questions,
            id
        }, 
        {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        });
        result[0] = createSurveyServerObj(res.data);
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result
    }
}

/**
 * 
 * @param {string} jobId 
 * @param {string} userId 
 * @returns {Promise<[import("../models/SurveyResultModel").SurveyResultModel[] | null, HttpResponse | null]>}
 */
export async function getSurveyResult(jobId, userId) {
    const result = [null, null];
    try {
        const res = await axios.get(`${BackendAPI.survey.surveyResult}/${jobId}/${userId}`, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        const surveyList = [];
        for (let x of res.data) {
            if (x.question_detail) {
                surveyList.push(createSurveyResultObj(x.question_detail))
            }
        }
        result[0] = surveyList;
        return result
    } catch (err) {
        result[1] = handleException(err)
        return result
    }
}