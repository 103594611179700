import React, { useCallback, useEffect, useState } from "react";
import { applicationFilterInitialData } from "../../controller/UserApplicationController";
import { useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../utils/handleException";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

/**
 * @typedef {object} Props
 * @property {string} jobName
 * @property {React.Dispatch<React.SetStateAction<string>>} setJobName
 * @property {string} status
 * @property {React.Dispatch<React.SetStateAction<string>>} setStatus
 * @property {string} education
 * @property {React.Dispatch<React.SetStateAction<string>>} setEducation
 * @property {string} specialization
 * @property {React.Dispatch<React.SetStateAction<string>>} setSpecialization
 * @property {()=>void} filterFunc
 * @property {()=>void} clearFunc
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setIsSearched
 */

/**
 * @param {Props} props
 *
 */
export default function SortApplications({
    filterFunc,
    setIsSearched,
    education,
    setEducation,
    specialization,
    setSpecialization,
    jobName,
    setJobName,
    setStatus,
    status,
    clearFunc,
    setCurrentPage
}) {
    const [jobList, setJobList] = useState([]);
    const [educationList, setEducationList] = useState([]);
    const [specializationList, setSpecializationList] = useState([]);
    const [fetchingSearchResult, setFetchingSearchResult] = useState(false);

    const navigate = useNavigate();

    const handleInitialData = useCallback(async () => {
        const res = await applicationFilterInitialData();
        if (res[0] !== null) {
            setJobList(res[0].jobList);
            setEducationList(res[0].educationList);
            setSpecializationList(res[0].specializationList);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, []);

    useEffect(() => {
        handleInitialData();
    }, []);

    function handleSearch() {
        if (education !== "" || specialization !== "" || jobName !== "" || status !== "") {
            setFetchingSearchResult(true);
            filterFunc();
            setFetchingSearchResult(false);
            setIsSearched(true);
            setCurrentPage(1)
        }
    }

    function handleClear() {
        clearFunc();
        setIsSearched(false);
        setJobName("");
        setSpecialization("");
        setEducation("");
        setStatus("");
    }

    const statusList = ["Applied","In-review","Interview","Selected","Rejected"]

    return (
        <Box>
            <FormControl sx={{ minWidth: "200px" }}>
                <InputLabel id="jobSelector">Select job</InputLabel>
                <Select
                    labelId="jobSelector"
                    label="Select jon"
                    value={jobName}
                    onChange={e => setJobName(e.target.value)}
                    variant="standard"
                >
                    {jobList.length > 0 &&
                        jobList.map((data, index) => (
                            <MenuItem key={index} value={data}>
                                {data}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "200px", marginLeft: "1rem" }}>
                <InputLabel id="educationSelector">Select education</InputLabel>
                <Select
                    labelId="educationSelector"
                    label="Select education"
                    value={education}
                    onChange={e => setEducation(e.target.value)}
                    variant="standard"
                >
                    {educationList.map((data, index) => (
                        <MenuItem key={index} value={data}>
                            {data}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "200px", marginLeft: "1rem" }}>
                <InputLabel id="specializationSelector">
                    Select specialization
                </InputLabel>

                <Select
                    value={specialization}
                    onChange={e => setSpecialization(e.target.value)}
                    labelId="specializationSelector"
                    variant="standard"
                    label="Select specialization"
                >
                    {specializationList.map((data, index) => (
                        <MenuItem key={index} value={data}>
                            {data}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "200px", marginLeft: "1rem" }}>
                <InputLabel id="statusSelector">
                    Select status
                </InputLabel>

                <Select
                    value={status}
                    onChange={e => setStatus(e.target.value)}
                    labelId="statusSelector"
                    variant="standard"
                    label="Select status"
                >
                    {statusList.map((data, index) => (
                        <MenuItem key={index} value={data}>
                            {data}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                sx={{ marginLeft: "1rem", width: "120px", height: "40px" }}
                variant="outlined"
                onClick={handleSearch}
                disabled={fetchingSearchResult}
            >
                {fetchingSearchResult ? (
                    <CircularProgress size={"20px"} />
                ) : (
                    "Search"
                )}
            </Button>
            <Button
                sx={{ marginLeft: "1rem", width: "120px", height: "40px" }}
                variant="outlined"
                onClick={handleClear}
            >
                Clear
            </Button>
        </Box>
    );
}
