import { LinkOutlined, WorkspacePremium } from '@mui/icons-material';
import { Box, Button, Container, Pagination, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsDiamond, BsFillDiamondFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import noInterview from "../../Images/empty_states/no-interview.png";
import { getprofileVerification } from '../../controller/profileVerificationController';
import { handleControllerResultException } from '../../utils/dataFetchHelper';
import Avatar from "./Avatar.png";

const initialFresherState = [];

function Fresher({ currentSubTab, onSubTabChange }) {
    const navigate = useNavigate();
    const fresherTypes = useMemo(() => ["All", "Initiated", "Verified", "Rejected"], []);
    const [fresherList, setFresherList] = useState(initialFresherState);
    const [totalPages, setTotalPages] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleFresherProfile = useCallback(async (fresherType, currentPage = 1) => {
        const res = await getprofileVerification(fresherType, "Fresher", currentPage);
        if (res[0] !== null) {
            setFresherList(Array.isArray(res[0].result) ? res[0].result : []);
            setTotalPages(Math.ceil(res[0].count / 10));
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, [navigate]);

    useEffect(() => {
        handleFresherProfile(currentSubTab || "All");
    }, [handleFresherProfile, currentSubTab]);

    useEffect(() => {
        if (currentSubTab && fresherTypes.includes(currentSubTab)) {
            setCurrentTab(fresherTypes.indexOf(currentSubTab));
        } else {
            setCurrentTab(0);  // Default to "All" tab
        }
    }, [currentSubTab, fresherTypes]);

    const handleTabChange = (_e, value) => {
        setCurrentPage(1);
        const selectedFresherType = fresherTypes[value];
        onSubTabChange(selectedFresherType);
    };

    return (
        <Box>
            <Container maxWidth="lg" sx={{ margin: "auto", padding: { xs: "1rem", sm: "2rem" } }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "space-between", gap: { xs: 2, sm: 0 } }}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        {fresherTypes.map((type, index) => (
                            <Tab key={index} label={type} sx={{ textTransform: "capitalize" }} />
                        ))}
                    </Tabs>
                    <Button variant="contained" sx={{ mt: { xs: 2, sm: 0 } }} onClick={() => navigate("/ProfileVerification/NewVerification")}>
                        + New Verification
                    </Button>
                </Box>
                {fresherList.length > 0 ? (
                    <div>
                        <TableContainer component={Paper} elevation={2} sx={{ mt: 4, mb: 10 }}>
                            <Table sx={{ width: "100%" }}>
                                <TableHead sx={{ backgroundColor: blue[700] }}>
                                    <TableRow>
                                        <TableCell sx={{ color: "white" }}>Status</TableCell>
                                        <TableCell sx={{ color: "white" }}>Employee</TableCell>
                                        <TableCell sx={{ color: "white" }}>Report Type</TableCell>
                                        <TableCell sx={{ color: "white" }}>Last Update</TableCell>
                                        <TableCell sx={{ color: "white" }}>Profile Link</TableCell>
                                        <TableCell sx={{ color: "white" }}>Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fresherList.map(interview => (
                                        <TableRow key={interview.id}>
                                            <TableCell>
                                                {interview.status === "Initiated" ? (
                                                    <Box display="flex" alignItems="center" color="green" gap={1}>
                                                        <BsDiamond />
                                                        <Typography>{interview.status}</Typography>
                                                    </Box>
                                                ) : interview.status === "Verified" ? (
                                                    <Box display="flex" alignItems="center" color="blue" gap={1}>
                                                        <WorkspacePremium />
                                                        <Typography>{interview.status}</Typography>
                                                    </Box>
                                                ) : interview.status === "Rejected" ? (
                                                    <Box display="flex" alignItems="center" color="red" gap={1}>
                                                        <BsFillDiamondFill />
                                                        <Typography>{interview.status}</Typography>
                                                    </Box>
                                                ) : (
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        <BsDiamond />
                                                        <Typography>{interview.status}</Typography>
                                                    </Box>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center" gap={2}>
                                                    <Box width={48} height={48}>
                                                        <img src={interview.personal_details.profile_pic || Avatar} alt="profile_pic" style={{ width: '100%', borderRadius: '50%' }} />
                                                    </Box>
                                                    <Typography>{interview.personal_details.name || "Not Available"}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {interview.report_type === "id_verification" ? "ID VERIFICATION"
                                                        : interview.report_type === "prev_company_verification" ? "PREVIOUS COMPANY"
                                                        : interview.report_type}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {interview.updated_at && interview.updated_at.slice(0, 10)}
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {interview.resume === null ? "Not Available"
                                                        : <div style={{ display: 'flex', width: '6rem', gap: '0.5rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            <LinkOutlined />
                                                            <a href={interview.resume.resume} target="_blank" rel="noreferrer">{interview.resume?.resume_name}</a>
                                                        </div>
                                                    }
                                                </Typography>
                                                <Typography>{interview.format_time}</Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Button variant='outlined' onClick={() => navigate(`/ProfileVerification/ViewDetails/${interview.id}`)}>
                                                    View Details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display={"flex"} justifyContent={"center"} margin={"2rem 0rem"}>
                            <Pagination
                                count={totalPages}
                                color="primary"
                                page={currentPage}
                                onChange={(_e, value) => {
                                    setCurrentPage(value);
                                    handleFresherProfile(currentSubTab || "All", value);
                                }}
                            />
                        </Box>
                    </div>
                ) : (
                    <Box display={"flex"} justifyContent={"center"} marginTop={2}>
                        <Box>
                            <img
                                src={noInterview}
                                alt="no interview"
                                height={"200px"}
                                width={"200px"}
                            />
                            <Typography
                                textAlign={"center"}
                                margin={"1rem 0rem"}
                                color={grey[600]}
                                fontSize={"14px"}
                            >
                                No Profile Verification
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Container>
        </Box>
    );
}

export default Fresher;
