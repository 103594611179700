import React, {useEffect, useState} from 'react'
import Cookies from 'universal-cookie';

import {Link, useNavigate} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from 'axios';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

const cookies = new Cookies();

const PaymentDetailsDetails = () => {

    const [amount, setAmount] = useState('')
    const [amountWitheId, setAmountWitheId] = useState('')
    const [amountPaid, setAmountPaid] = useState('')
    const [data, setData] = useState([])
    const [desc, setDesc] = useState("")
    const [note, setNote] = useState('')
    const [id, setId] = useState("")

    const navigate = useNavigate();

    async function getData() {
        await axios
            .get(`hri_admin/paymentdetails/${cookies.get("PaymentId")}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                console.log(resp)
                setData(resp.data)

            })
            .catch((err) => {
                console.log(err);
            });

    }

    useEffect(() => {
        if (cookies.get("token")) {
        console.log(cookies.get("PaymentId"))
        getData();
        } else {
            navigate("/");
        }
    },[]);
    return (
        <>
            <Header/>
            <Sidebar/>
            <div className="bg-gray-100  mt-[68px] w-full h-screen">
                <div

                >
                    <div className='pt-4'>
                        <div className=" mx-4 rounded-t-lg bg-sky-100 ">
                            <div className="flex items-center justify-between px-5 py-2 rounded-md z-10">
                                <div onClick={()=>navigate(-1)}>
                                    <div
                                        className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                                        <ArrowBackIosNewIcon style={{height: 20}}/>
                                        <span>Back</span>
                                    </div>
                                </div>


                            </div>
                        </div>
                        {data.map((user) => {
                            return (
                                <div className="bg-white px-5  rounded-b-lg py-4 mx-4 z-10">
                                    <div className='grid md:grid-cols-2 gap-y-4 md:gap-y-4 lg:grid-cols-3'>
                                        <div className='md:flex'>
                                            <div className='text-lg font-semibold '>Bank Branch : &nbsp;</div>
                                            <div className='mt-1'>{user.bank_name}</div>
                                        </div>
                                        <div className='md:flex'>
                                            <div className='text-lg font-semibold'>IFSC : &nbsp;</div>
                                            <div className='mt-1'>{user.ifsc}</div>
                                        </div>
                                        <div className='md:flex'>
                                            <div className='text-lg font-semibold'>VPA :&nbsp; </div>
                                            <div className='mt-1'>{user.vpa}</div>
                                        </div>
                                        <div className='md:flex'>
                                            <div className='text-lg font-semibold'>Account Type :&nbsp; </div>
                                            <div className='mt-1'>{user.account_type}</div>
                                        </div>
                                        <div className='md:flex'>
                                            <div className='text-lg font-semibold'>MMID :&nbsp; </div>
                                            <div className='mt-1'>{user.mmid}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                    </div>

                </div>
            </div>
        </>
    )
}

export default PaymentDetailsDetails