import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import Editor from "../../components/richEditor/Editor";
import useCreateEditor from "../../hooks/useCreateEditor";
import { LoadingButton } from "@mui/lab";
import {
    createWebinar,
    getWebinarDetail,
    updateWebinarDetail,
} from "../../controller/webinarController";
import { useNavigate, useParams } from "react-router-dom";
import { HTTPStatus } from "../../utils/helperObj";
import { message } from "antd";
import { handleControllerResultException } from "../../utils/handleException";
import { ArrowBack, Save } from "@mui/icons-material";
import moment from "moment";

export default function WebinarForm() {
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [relatedSkills, setRelatedSkills] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [isOnline, setIsOnline] = useState(true);
    const [price, setPrice] = useState("");
    const [onlineMeetingLink, setOnlineMeetingLink] = useState("");
    const [onlineMeetingPasscode, setOnlineMeetingPassCode] = useState("");
    const [offlineAddress, setOfflineAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [pin, setPin] = useState("");
    const [state, setState] = useState("");
    const [description, setDescription] = useState("");
    const [speaker, setSpeaker] = useState("");
    const [speakerDesignation, setSpeakerDesignation] = useState("");
    const [error, setError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [fetchingWebinarDetails, setFetchingWebinarDetails] = useState(false);

    const editor = useCreateEditor(description);
    const navigate = useNavigate();

    function handleTabChange(_e, index) {
        if (index === 0) {
            setIsOnline(true);
        } else {
            setIsOnline(false);
        }
    }

    function checkInput() {
        return (
            title !== "" &&
            date !== "" &&
            startTime !== "" &&
            relatedSkills !== "" &&
            contactEmail !== "" &&
            speaker !== "" &&
            speakerDesignation !== "" &&
            (isOnline
                ? onlineMeetingLink !== ""
                : state !== "" &&
                  country !== "" &&
                  city !== "" &&
                  pin !== "" &&
                  pin.length === 6 &&
                  offlineAddress !== "")
        );
    }

    async function handleSave() {
        setError(false);
        if (checkInput()) {
            setSaving(true);
            const res = await createWebinar({
                title,
                date,
                startTime,
                relatedSkills,
                contactEmail,
                isOnline,
                price,
                onlineMeetingLink,
                onlineMeetingPasscode,
                offlineAddress,
                city,
                country,
                state,
                speaker,
                speakerDesignation,
                description: editor.getHTML(),
                pin,
            });
            setSaving(false);

            if (res.statusCode === HTTPStatus.SUCCESS) {
                message.success(res.success);
                navigate(-1);
            } else {
                handleControllerResultException(res, navigate);
            }
        } else {
            setError(true);
        }
    }

    const handleWebinarDetail = useCallback(
        async id => {
            setFetchingWebinarDetails(true);
            const res = await getWebinarDetail(id);
            setFetchingWebinarDetails(false);
            if (res[0] !== null) {
                setTitle(res[0].title);
                setDate(res[0].date);
                setStartTime(res[0].startTime);
                setRelatedSkills(res[0].relatedSkills);
                setContactEmail(res[0].contactEmail);
                setIsOnline(res[0].isOnline);
                setPrice(res[0].price);
                setOnlineMeetingLink(res[0].onlineMeetingLink);
                setOnlineMeetingPassCode(res[0].onlineMeetingPasscode);
                setCity(res[0].city);
                setCountry(res[0].country);
                setPin(res[0].pin);
                setState(res[0].state);
                setOfflineAddress(res[0].offlineAddress);
                setDescription(res[0].description);
                setSpeaker(res[0].speaker);
                setSpeakerDesignation(res[0].speakerDesignation);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (id) {
            handleWebinarDetail(id);
        }
    }, [handleWebinarDetail, id]);

    async function handleUpdate() {
        if (checkInput()) {
            setSaving(true);
            const res = await updateWebinarDetail({
                id,
                title,
                date,
                startTime,
                relatedSkills,
                contactEmail,
                isOnline,
                price,
                onlineMeetingLink,
                onlineMeetingPasscode,
                offlineAddress,
                city,
                country,
                state,
                speaker,
                speakerDesignation,
                description: editor.getHTML(),
                pin,
            });
            setSaving(false);
            if (res.statusCode === HTTPStatus.SUCCESS) {
                message.success(res.success);
            } else {
                handleControllerResultException(res, navigate);
            }
        } else {
            setError(true);
        }
    }

    return (
        <Box>
            <Header />
            <Container
                maxWidth="xl"
                sx={{ margin: "auto", padding: "3rem 0rem" }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginBottom={"3rem"}
                >
                    <Button variant="contained" onClick={() => navigate(-1)}>
                        <ArrowBack sx={{ marginRight: "10px" }} />
                        Back
                    </Button>
                    <LoadingButton
                        loading={saving}
                        onClick={id ? handleUpdate : handleSave}
                        variant="contained"
                    >
                        <Save sx={{ marginRight: "10px" }} />
                        {id ? "Update" : "Save"}
                    </LoadingButton>
                </Box>
                {!fetchingWebinarDetails ? (
                    <Grid container spacing={6}>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                variant="filled"
                                label="Title"
                                fullWidth
                                error={error && title === ""}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={date}
                                onChange={e => setDate(e.target.value)}
                                variant="filled"
                                label="Date"
                                type="date"
                                inputProps={{
                                    min: moment().format("YYYY-MM-DD"),
                                }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error && date === ""}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={startTime}
                                onChange={e => setStartTime(e.target.value)}
                                variant="filled"
                                label="Start time"
                                fullWidth
                                type="time"
                                InputLabelProps={{ shrink: true }}
                                error={error && startTime === ""}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={relatedSkills}
                                onChange={e => setRelatedSkills(e.target.value)}
                                variant="filled"
                                label="Related skills (use space between skills)"
                                fullWidth
                                error={error && relatedSkills === ""}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={contactEmail}
                                onChange={e => setContactEmail(e.target.value)}
                                variant="filled"
                                label="Contact email"
                                fullWidth
                                error={error && contactEmail === ""}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={price}
                                onChange={e => setPrice(e.target.value)}
                                variant="filled"
                                type="number"
                                label="Price (optional)"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={speaker}
                                onChange={e => setSpeaker(e.target.value)}
                                variant="filled"
                                label="Speaker"
                                fullWidth
                                error={error && speaker === ""}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                value={speakerDesignation}
                                onChange={e =>
                                    setSpeakerDesignation(e.target.value)
                                }
                                variant="filled"
                                label="Speaker designation"
                                fullWidth
                                error={error && speakerDesignation === ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                fontWeight={600}
                                fontSize={14}
                                marginBottom={"1rem"}
                            >
                                Description
                            </Typography>
                            <Editor editor={editor} />
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                onChange={handleTabChange}
                                centered
                                value={isOnline ? 0 : 1}
                            >
                                <Tab label="Online Webinar" />
                                <Tab label="Offline Webinar" />
                            </Tabs>
                        </Grid>
                        {isOnline && (
                            <>
                                <Grid xs={6} item>
                                    <TextField
                                        value={onlineMeetingLink}
                                        onChange={e =>
                                            setOnlineMeetingLink(e.target.value)
                                        }
                                        variant="filled"
                                        label="Meeting link"
                                        fullWidth
                                        error={
                                            error && onlineMeetingLink === ""
                                        }
                                    />
                                </Grid>
                                <Grid xs={6} item>
                                    <TextField
                                        value={onlineMeetingPasscode}
                                        onChange={e =>
                                            setOnlineMeetingPassCode(
                                                e.target.value
                                            )
                                        }
                                        variant="filled"
                                        label="Meeting passcode (optional)"
                                        fullWidth
                                        error={
                                            error &&
                                            onlineMeetingPasscode === ""
                                        }
                                    />
                                </Grid>
                            </>
                        )}
                        {!isOnline && (
                            <>
                                <Grid item md={4} xs={6}>
                                    <TextField
                                        value={city}
                                        onChange={e => setCity(e.target.value)}
                                        variant="filled"
                                        label="City"
                                        fullWidth
                                        error={error && city === ""}
                                    />
                                </Grid>

                                <Grid item md={4} xs={6}>
                                    <TextField
                                        value={country}
                                        onChange={e =>
                                            setCountry(e.target.value)
                                        }
                                        variant="filled"
                                        label="Country"
                                        fullWidth
                                        error={error && country === ""}
                                    />
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <TextField
                                        value={state}
                                        onChange={e => setState(e.target.value)}
                                        variant="filled"
                                        label="State"
                                        fullWidth
                                        error={error && state === ""}
                                    />
                                </Grid>

                                <Grid item md={4} xs={6}>
                                    <TextField
                                        value={pin}
                                        onChange={e => setPin(e.target.value)}
                                        variant="filled"
                                        label="Pin code (must be 6 digit)"
                                        fullWidth
                                        error={error && pin === ""}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        value={offlineAddress}
                                        onChange={e =>
                                            setOfflineAddress(e.target.value)
                                        }
                                        variant="filled"
                                        label="Address"
                                        fullWidth
                                        multiline
                                        rows={5}
                                        error={error && offlineAddress === ""}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                ) : (
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        marginTop={"5rem"}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Box>
    );
}
