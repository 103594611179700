import React, { useEffect, useState } from "react";
import { getUserList } from "../../controller/StudentController";
import { useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../utils/handleException";
import { getCompanyMailList } from "../../controller/companyController";
import MultiSelectSuggestionTextField from "../../components/ScheduleMeet/MultiSelectSuggestionTextField";
import { getTeamList } from "../../controller/teamController";

/**
 * @typedef {object} Props
 * @property {"Users"|"Teams"|"Companies"} broadcastReciverType
 * @property {string[]} selectedUserList
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedUserList
 * @property {string[]} selectedTeamList
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedTeamList
 * @property {string[]} selectedCompanyList
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedCompanyList
 * @property {boolean} error
 */

/**
 * @param {Props} props
 */
export default function SelectBroadcastRecivers({
    broadcastReciverType,
    selectedUserList,
    setSelectedUserList,
    selectedCompanyList,
    setSelectedCompanyList,
    selectedTeamList,
    setSelectedTeamList,
    error
}) {
    const [userList, setUserList] = useState(userListType);
    const [companyList, setCompanyList] = useState(companyListType);
    const [teamList, setTeamList] = useState(teamListType)
    const navigate = useNavigate();


    async function fetchUserList(searchText) {
        const res = await getUserList(searchText);
        if (res[0] !== null) {
            setUserList(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }

    async function fetchCompanyList(searchText) {
        const res = await getCompanyMailList(searchText);
        if (res[0] !== null) {
            setCompanyList(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }

    async function fetchTeamList(searchText) {
        const res = await getTeamList(searchText);
        if (res[0] !== null) {
            setTeamList(res[0])
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }



    function renderMultiSelectField() {
        if (broadcastReciverType === "Users") {
            return (
                <MultiSelectSuggestionTextField
                    options={userList.map(data => data.email)}
                    searchFunction={fetchUserList}
                    selectedOptions={selectedUserList}
                    setSelectOption={setSelectedUserList}
                    textFieldLabel="Tag user"
                    error={error && selectedUserList.length === 0}
                />
            );
        } else if (broadcastReciverType === "Companies") {
            return (
                <MultiSelectSuggestionTextField
                    options={companyList.map(data => data.email)}
                    searchFunction={fetchCompanyList}
                    selectedOptions={selectedCompanyList}
                    setSelectOption={setSelectedCompanyList}
                    textFieldLabel="Tag company"
                    error={error && selectedCompanyList.length === 0}
                />
            );
        } else {
            return <MultiSelectSuggestionTextField
                options={teamList.map(data => data.email)}
                searchFunction={fetchTeamList}
                selectedOptions={selectedTeamList}
                setSelectOption={setSelectedTeamList}
                textFieldLabel="Tag team"
                error={error && selectedTeamList.length === 0}
            />
        }
    }

    return <>
        {renderMultiSelectField()}
    </>;
}

/**
 * @type {import("../../models/StudentModel").StudentModel[]}
 */
const userListType = [];

/**
 * @type {import("../../models/CompanyModel").CompanyMailModel[]}
 */

const companyListType = [];

/**
 * @type {import("../../models/TeamModel").TeamModel[]}
 */
const teamListType = [];