import React, { useEffect, useState } from "react";
import { Card, CardContent, FormControlLabel } from "@mui/material";
import moment from "moment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import axios from "axios";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const RequestDetails = () => {
  const [data, setData] = useState([]);
  const [id, setID] = useState("");
  const [header, setHeader] = useState("");
  const [receiver_email, setReceiver_email] = useState([]);
  const [message, setMessage] = useState("");
  const [is_active, setIs_active] = useState("");
  const [broadcast_type, setBroadcast_type] = useState("");
  const [admin_approval, setAdmin_approval] = useState("");

  const GetRequestDetails = () => {
    axios
      .get(`hri_admin/broadcast-detail/${localStorage.getItem("RequestID")}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setData(res.data);
        setHeader(res.data.header);
        setReceiver_email(res.data.receiver_email);
        setMessage(res.data.message);
        setIs_active(res.data.is_active);
        setBroadcast_type(res.data.broadcast_type);
        setAdmin_approval(res.data.admin_approval);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("Some error occurred: ", err);
      });
  };

  const ApproveRequest = () => {
    axios
      .get(
        `hri_admin/broadcast/request-accept/${localStorage.getItem(
          "RequestID"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Successfully getting data", res.data);
        alert("Approved successfully");
      })
      .catch((err) => {
        console.log("Some Error ocurred", err);
      });
  };

  const DeleteRequestBroadcast = () => {
    axios
      .delete(
        `hri_admin/broadcast-delete/${localStorage.getItem("RequestID")}`,
        {
          headers: {
            Authorization: "Token  " + cookies.get("token"),
          },
        }
      )
      .then(() => {
        navigate("/broadcast/request ");
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  useEffect(() => {
    GetRequestDetails();
  }, []);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const navigate = useNavigate();

  const date = new Date();
  const newDate = moment(date).format("YYYY-MM-DD");

  return (
    <div>
      <Card className="m-4" elevation={0}>
        <CardContent className="space-y-7 mx-3">
          <div className="mt-3 flex flex-col space-y-4 md:space-y-0 md:flex-row">
            <div className="flex-grow">
              <p
                className="text-sky-700 hover:bg-[#F1F1F1] rounded w-24 px-4 py-1"
                onClick={() => navigate("/broadcast/request")}
              >
                <ArrowBackIosNewIcon sx={{ fontSize: "medium" }} />
                Back
              </p>
            </div>
            <div className="text-lg mr-2">
              <p
                className="px-4 py-1 rounded bg-[#F1F1F1] text-sky-700"
                onClick={() => {
                  ApproveRequest();
                  navigate("/broadcast/request");
                }}
              >
                Approve
                <DoneAllIcon className="ml-1 mb-1" sx={{ fontSize: "large" }} />
              </p>
            </div>
            <div className=" text-lg">
              <p
                className="bg-[#F1F1F1] w-32 cursor-pointer text-center text-red-400 px-4 py-1 rounded"
                onClick={() => {
                  DeleteRequestBroadcast();
                }}
              >
                Delete
                <DeleteIcon className="mb-1" sx={{ fontSize: "large" }} />
              </p>
            </div>
          </div>

          <div>
            <div className="ml-3 flex flex-col space-y-4 md:ml-10  md:space-y-0 md:flex-row">
              <div className="flex-grow">
                <div className="text-lg">
                  Receiver IDs:{" "}
                  {receiver_email.map((email4, i) => (
                    <div key={email4.id}>
                      <p>{email4.email}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col space-y-3">
                <div>
                  <p className="font-semibold text-gray-500">
                    Date: <span className="text-default-blue">{newDate}</span>
                  </p>
                </div>
                <div>
                  <p className="font-semibold text-gray-500">
                    Broadcast Type:{" "}
                    <span className="text-default-blue">
                      {broadcast_type === "notification"
                        ? "Notification"
                        : "Email"}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="ml-2 mr-2 mt-5 md:ml-10 md:mr-10">
              <p className="text-xl font-bold">{header}</p>
              <p className="text-justify">{message}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RequestDetails;
