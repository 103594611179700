import {
    Box,
    Button,
    Container,
    Pagination,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import { Add, Edit } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { getWebinarList } from "../../controller/webinarController";
import { handleControllerResultException } from "../../utils/handleException";
import { blue, grey } from "@mui/material/colors";
import moment from "moment";
import noDataImg from "../../Images/empty_states/no-dashboardmessage.png"

/**
 * @type {import("../../models/WebinarModel").WebinarModel[]}
 */
const webinarListType = [];

export default function Webinar() {
    const webinarType = useMemo(() => ["upcoming", "previous"], []);
    const [webinarList, setWebinarList] = useState(webinarListType);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTab, setCurrentTab] = useState(0);
    const navigate = useNavigate();

    const handleWebinarList = useCallback(
        async (page, webinarType) => {
            const res = await getWebinarList(page, webinarType);
            if (res[0] !== null) {
                setTotalPages(Math.ceil(res[0].count / 10));
                setWebinarList(res[0].results);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        handleWebinarList(currentPage, webinarType[currentTab]);
    }, [handleWebinarList, currentPage, currentTab, webinarType]);

    function handlePageChange(_e, value) {
        setCurrentPage(value);
    }

    function handleCurrenTab(_e, value) {
        setCurrentTab(value);
    }

    return (
        <Box>
            <Header />
            <Container
                maxWidth="lg"
                sx={{ margin: "auto", padding: "3rem 0rem" }}
            >
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography sx={{ fontSize: "1.7rem", marginBottom: "1.6rem", fontWeight: "500", color: grey[800] }}>Webiners</Typography>

                    <Link to="/webinar/form">
                        <Button variant="contained">
                            <Add />
                            Add webinar
                        </Button>
                    </Link>
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    margin={"2rem 0rem"}
                >
                    <Tabs value={currentTab} onChange={handleCurrenTab}>
                        <Tab
                            label={webinarType[0]}
                            sx={{ textTransform: "capitalize" }}
                        />
                        <Tab
                            label={webinarType[1]}
                            sx={{ textTransform: "capitalize" }}
                        />
                    </Tabs>
                </Box>
                {webinarList.length > 0 ? (
                    <div>
                        <TableContainer component={Paper} elevation={2}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead sx={{ backgroundColor: blue[700] }}>
                                    <TableRow>
                                        <TableCell sx={{ color: "white" }}>
                                            Title
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Date
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Speaker
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Start time
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Price
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Webinar type
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {webinarList.map(data => (
                                        <TableRow key={data.id}>
                                            <TableCell>
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            `/webinar/form/${data.id}`
                                                        )
                                                    }
                                                >
                                                    <Edit
                                                        fontSize="small"
                                                        color={"primary"}
                                                    />
                                                </Button>
                                                {data.title}
                                            </TableCell>
                                            <TableCell>
                                                {moment(data.date).format(
                                                    "DD/MM/YYYY"
                                                )}
                                            </TableCell>
                                            <TableCell>{data.speaker}</TableCell>
                                            <TableCell>
                                                {" "}
                                                {moment(
                                                    data.startTime,
                                                    "HH:mm"
                                                ).format("h:mm A")}
                                            </TableCell>
                                            <TableCell>
                                                {data.price !== "" && data.price > 0
                                                    ? data.price
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {data.isOnline
                                                    ? "Online"
                                                    : "Offline"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            margin={"2rem 0rem"}
                        >
                            <Pagination
                                count={totalPages}
                                color="primary"
                                onChange={handlePageChange}
                            />
                        </Box>
                    </div>
                ) : (
                    <Box display={"flex"} justifyContent={"center"}>
                        <Box>
                            <img
                                src={noDataImg}
                                alt="no webinar"
                                height={"200px"}
                                width={"200px"}
                            />
                            <Typography
                                textAlign={"center"}
                                margin={"1rem 0rem"}
                                color={grey[600]}
                                fontSize={"20px"}
                                fontWeight={"bold"}
                            >
                                No webinar Available
                            </Typography>
                        </Box>
                    </Box>

                )}

            </Container>
        </Box>
    );
}

