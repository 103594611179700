import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
// import Header from "../NavBar-Sidebar/Header"
import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
// import {IoPersonCircleOutline} from "react-icons/io5"
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { toastError, toastSuccess } from "../../utils/Notification";

const MessageModal = ({ open, onClose }) => {
  const cookies = new Cookies();
  // const currentState = useSelector(selectHeader);

  const date = new Date();
  const newDate = moment(date).format("YYYY-MM-DD");
  const [header, setHeader] = useState("");
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [receiver , setReciever] = useState("")
  const headers = {
    Authorization: `Token ${cookies.get("token")}`,
  };

  function Send() {
    if (message.length > 10) {
      setLoading(true);
      axios
        .post("hri_admin/message/send", { header, message , receiver : cookies.get("Userid") }, { headers: headers })
        .then((res) => {
          setLoading(false);
          toastSuccess(res.data.message);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          toastError(err.message);
        });
    } else {
      toastError("Please provide all information correctly");
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setHeader("");
        setMessage("");
        setReciever("")
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "5px",
          p: 4,
        }}
      >
        <Typography fontSize={"15px"}>
          Date:{" "}
          <span style={{ fontWeight: "bold", color: blue[700] }}>
            {newDate}
          </span>
        </Typography>
        <TextField
          value={header}
          onChange={(e) => setHeader(e.target.value)}
          fullWidth
          variant="outlined"
          label="Subject of the message"
          sx={{ margin: "1.4rem 0rem" }}
        />
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          multiline
          rows={5}
          variant="outlined"
          label="Message (minimum 10 characters)"
        />
        <Box
          sx={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={Send}
            sx={{ width: "7rem", padding: "8px 0px" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={"1.4rem"} />
            ) : (
              <span>
                <SendIcon sx={{ marginRight: "10px" }} />
                Send
              </span>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MessageModal;