import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import axios from "axios";
import csc from "country-state-city";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { isValidEmail, isValidName, isValidPhoneNumber, isValidPin } from "../../utils/validation";
import image1 from "./3135715.png";

const cookies = new Cookies();

const AddNewMember = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.get("token")) {
    } else {
      navigate("/");
    }
  }, []);

  const [profilePic, setProfilePic] = useState("");
  const [picReview, setPicReview] = useState("");
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contact, setContact] = useState("");
  const [status, setStatus] = useState("Active");
  const [employeId, setEmployeId] = useState("");
  const [dob, setDob] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [add, setAdd] = useState("");
  const [designation, setDesignation] = useState("");
  const [gender, setGender] = useState("male");
  const [joinedDate, setJoinedDate] = useState("");


  const emptyErrors = {name: false, email: false, pin: false, phone: false, eid: false, deg: false, add: false}
  const [errors, setErrors] = useState(emptyErrors)

  const PostMemberData = () => {
    setErrors(emptyErrors);

  // Check for blank fields
  if (!dob) return setErrors(p => ({ ...p, dob: true }));
  if (!joinedDate) return setErrors(p => ({ ...p, joinedDate: true }));

  // Other validation checks
  if (isValidName(name) === false) return setErrors(p => ({ ...p, name: true }));
  if (isValidEmail(emailId) === false) return setErrors(p => ({ ...p, email: true }));
  if (isValidPhoneNumber(contact) === false) return setErrors(p => ({ ...p, phone: true }));
  if (employeId.length < 3) return setErrors(p => ({ ...p, eid: true }));
  if (designation.length < 3) return setErrors(p => ({ ...p, deg: true }));
  if (isValidPin(pin) === false) return setErrors(p => ({ ...p, pin: true }));
  if (add.length < 5) return setErrors(p => ({ ...p, add: true }));

    const countryObj = csc.getCountryById(country)
    const stateObj = csc.getStateById(state)

    const newCountryName = countryObj?.name || country;
    const newStateName = stateObj?.name || state;

    let formField = new FormData();

    formField.append("name", name);
    formField.append("email", emailId);
    formField.append("status", status);
    formField.append("phone_number", contact);
    formField.append("employeId", employeId);
    formField.append("dob", dob);
    formField.append("designation", designation);
    formField.append("country", newCountryName);
    formField.append("state", state);
    formField.append("city", city);
    formField.append("state", newStateName);
    formField.append("pin", pin);
    formField.append("add", add);
    formField.append("gender", gender);
    formField.append("joinedDate", joinedDate);
    if (profilePic !== "") {
      formField.append("profilePic", profilePic);
    }

    axios
      .post(`hri_admin/team/invite`, formField, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        
        toast.success("Successfully added new Member", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate("/team-manage/all");
      })
      .catch((err) => {
        console.log("Error: ", err);
        toast.error(`Error: Failed to add the new Member`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  /**
   * ----------------
   *    Dropdowns
   * ----------------
   */
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [country, setCountry] = useState("");

  const { values, handleSubmit, setFieldValue, setValues } = useFormik({
    initialValues: {
      country: "",
      state: "",
      city: "",
    },
  });

  const updateStates = (countryId) =>
  csc
    .getStatesOfCountry(countryId)
    .map((state) => ({ label: state.name, value: state.id, ...state }));
  const updatedCities = (stateId) =>
  csc
    .getCitiesOfState(stateId)
    .map((city) => ({ label: city.name, value: city.id, ...city }));

  const countries = csc.getAllCountries();
  const updatedCountries = countries.map((country) => ({
      label: country.name,
      value: country.id,
      ...country,
  }));

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };

  return (
    <div className="bg-[#EDEBEE] h-screen overflow-auto">
      <Header />
      <Sidebar />
      <div>
        <Card className="m-4" elevation={0} sx={{ borderRadius: "10px" }}>
          <CardContent className="mt-4">
          <div className="md:mx-10 mx-[2] mb-4 flex flex-row-reverse justify-between" align="center">
                <p
                  className="text-[#0865B6] py-1 px-3 text-lg cursor-pointer font-semibold bg-[#F1F1F1] hover:text-green-500 text-center w-24 rounded"
                  onClick={() => {
                    PostMemberData();
                  }}
                >
                  Save
                  <DoneAllIcon className="ml-2" sx={{ fontSize: "medium" }} />
                </p>
                <p
                  className="bg-[#F1F1F1] text-[#0865B6] hover:text-red-500 py-1 pr-3 cursor-pointer text-lg font-semibold text-center w-24 rounded"
                  onClick={() => navigate("/team-manage/all")}
                >
                  <ArrowBackIosNewIcon
                    className="ml-2 mb-1"
                    sx={{ fontSize: "medium" }}
                  />
                  Back
                </p>
              </div>

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
            <div className="w-32 border-gray-600 rounded-full relative mx-auto ">
                    <img
                      className="w-32 h-32  rounded-full"
                      src={profilePic ? picReview || profilePic : image1}
                      alt="profilePic"
                      id="pic"
                    />
                    <label className="">
                      <FaRegEdit className="absolute text-white ml-14 mt-[-2rem] text-lg xl:text-xl 2xl:text-2xl md:text-base  cursor-pointer" />
                      <input
                        className="w-20"
                        type="file"
                        onChange={(e) => {
                          setProfilePic(e.target.files[0]);
                          setPicReview(URL.createObjectURL(e.target.files[0]));
                        }}
                        style={{ visibility: "hidden" }}
                      />
                    </label>
                  </div>
              <div className="col-span-3">
                {/*<p className="text-2xl font-bold">*/}
                {/*    Rahul David*/}
                {/*</p>*/}
                <TextField
                  fullWidth
                  label="Name*"
                  error={errors.name}
                  // value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div className="grid col-span-1 md:grid-cols-2 gap-4 mt-4">
                  <div>
                    <TextField
                      fullWidth
                      label="Email Id*"
                      error={errors.email}
                      // value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem disabled value="In-Active">In-Active</MenuItem>
                    </Select>
                  </FormControl>

                  <div>
                    <TextField
                      fullWidth
                      label="Contact*"
                      error={errors.phone}
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      label="Employee ID*"
                      error={errors.eid}
                      // value={employeId}
                      onChange={(e) => setEmployeId(e.target.value)}
                    />
                  </div>

                 
                  <Stack width={"full"}>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MobileDatePicker
      fullWidth
      disableFuture
      openTo="day"
      views={['year', 'month', 'day']}
      label="Date Of Birth*"
      value={dob}
      onChange={(newValue) => {
        setDob(moment(newValue).format("YYYY-MM-DD"));
        setErrors({ ...errors, dob: false }); // Clear the error when a value is selected
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={errors.dob} // Set error state here
          helperText={errors.dob ? "Date of Birth is required" : ""}
        />
      )}
    />
  </LocalizationProvider>
</Stack>

                  <div>
                    <TextField
                      fullWidth
                      label="Designation*"
                      error={errors.deg}
                      // value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                {/*
                --------------------------
                          Country
                --------------------------
                */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label={"Country"} 
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      setValues({
                        country: e.target.value,
                        state: "",
                        city: "",
                      });
                      setCountry(e.target.value);
                      e.target.value &&
                        setStateList(updateStates(e.target.value));
                    }}
                  >
                    {updatedCountries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              {/*
              --------------------------
                        State
              --------------------------
              */}
              <FormControl fullWidth>
                <InputLabel id="state">State</InputLabel>
                <Select
                  labelId="state"
                  id="state"
                  value={values.state}
                  label="state"
                  onChange={(e) => {
                    setValues({ state: e.target.value, city: "" }, false);
                    setState(e.target.value)
                    e.target.value &&
                      setCityList(updatedCities(e.target.value));

                  }}
                >
                  {stateList.length > 0 &&
                    stateList.map((data) => (
                      <MenuItem value={data.id} key={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
                {/*
                --------------------------
                           City
                --------------------------
                */}
                <FormControl fullWidth>
                  <InputLabel id="city">City</InputLabel>
                  <Select
                    labelId="city"
                    id="city"
                    value={values.city}
                    label="city"
                    onChange={(e) => {
                      setFieldValue("city", e.target.value)
                      setCity(e.target.value)
                    }}
                  >
                    {cityList.length > 0 &&
                      cityList.map((data, index) => (
                        <MenuItem value={data.name} key={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                  <div>
                    <TextField
                      fullWidth
                      label="Pin*"
                      error={errors.pin}
                      // value={pin}
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label="Address*"
                      error={errors.add}
                      // value={add}
                      onChange={(e) => setAdd(e.target.value)}
                    />
                  </div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={gender}
                      label="Gender*"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="na">Not specify</MenuItem>       
                    </Select>
                  </FormControl>

                  <Stack width={"full"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        fullWidth
                        disableFuture
                        openTo="day"
                        views={['year', 'month', 'day']}
                        label="Joined in Company*"
                        value={joinedDate}
                        onChange={(newValue) => {
                          setJoinedDate(moment(newValue).format("YYYY-MM-DD"));
                          setErrors({ ...errors, joinedDate: false }); // Clear the error when a value is selected
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.joinedDate} // Set error state here
                            helperText={errors.joinedDate ? "Joined in Company is required" : ""}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AddNewMember;
